import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { interval, merge, Observable, Subject } from 'rxjs';
import { CreateUserResponse, LoginResponse, UserDataResponse, UserRegistration } from '../models';
import { RegistrationStore } from '../store/registration.store';
import { distinctUntilChanged, filter, map, share, tap, withLatestFrom } from 'rxjs/operators';
import { VerifyEmailData } from '../models/verify-email-data';
import { UserStore } from '../store/user.store';

const AUTH_CHECK_INTERVAL = 1000;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public logIn$ = new Subject<boolean>();
  public logOut$ = new Subject<boolean>();
  public automaticLogOut$ = new Subject<void>();
  public isAuthenticated$: Observable<boolean>;

  private _isAuthenticated$ = new Subject<boolean>();
  private periodicAuthCheck$ = interval(AUTH_CHECK_INTERVAL).pipe(
    map(() => this.isAuthenticated),
    share()
  );
  private readonly authCookie = 'isAuthenticated';
  private verificationApiUrl = `${this.userApiUrl}/emailVerification`;
  private usersApiUrl = `${this.userApiUrl}/users`;
  private userPasswordApiUrl = `${this.usersApiUrl}/password`;

  constructor(
    private http: HttpClient,
    private registrationStore: RegistrationStore,
    private userStore: UserStore,
    private cookieService: CookieService,
    @Inject('userApiUrl') private userApiUrl: string,
    @Inject('mainBroker') private mainBroker: string
  ) {
    this.isAuthenticated$ = this._isAuthenticated$.asObservable().pipe(distinctUntilChanged());

    merge(this.logIn$.asObservable(), this.logOut$.asObservable(), this.periodicAuthCheck$).subscribe(() =>
      this._isAuthenticated$.next(this.isAuthenticated)
    );

    this.periodicAuthCheck$
      .pipe(
        withLatestFrom(userStore.getRefreshToken()),
        filter(([isAuthenticated, token]) => !isAuthenticated && !!token)
      )
      .subscribe(() => {
        this.userStore.deleteTokens();
        this.automaticLogOut$.next();
      });
  }

  get isAuthenticated(): boolean {
    return this.cookieService.check(this.authCookie);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkEmailAvailability(email: string): Observable<any> {
    return this.http.get(`${this.verificationApiUrl}/emailValidation`, { params: { email } });
  }

  createUser(): Observable<CreateUserResponse> {
    const user: UserRegistration = this.registrationStore.state;
    return this.http.post<CreateUserResponse>(`${this.usersApiUrl}`, user);
  }

  isUserCompleteForCreate(): boolean {
    const user: UserRegistration = this.registrationStore.state;
    return !!(user.firstName && user.lastName && user.email && user.password);
  }

  sendVerificationCode(verifyEmail: VerifyEmailData): Observable<void> {
    //only append the / when there is an offerId
    const sendVerificationURL = verifyEmail.offerId ? `/${verifyEmail.offerId}` : '';
    return this.http.post<void>(`${this.verificationApiUrl}${sendVerificationURL}`, {
      ...verifyEmail.userData,
      mainBroker: this.mainBroker,
    });
  }

  verifyCode(email: string, verificationCode: string): Observable<void> {
    return this.http.post<void>(`${this.verificationApiUrl}/code`, { email, verificationCode });
  }

  verifyLogin(password: string): Observable<void> {
    return this.http.post<void>(`${this.usersApiUrl}/verifyLogin`, { password });
  }

  changeUsername(username: string, password: string): Observable<void> {
    return this.http.put<void>(`${this.userApiUrl}/users/username/change`, { username, password });
  }

  signIn(username?: string, password?: string): Observable<LoginResponse> {
    if (!(username && password)) {
      username = this.registrationStore.state.email;
      password = this.registrationStore.state.password;
    }
    return this.http.post<LoginResponse>(`${this.usersApiUrl}/login`, { username, password }).pipe(
      tap(({ accessToken, refreshToken, userId }) => {
        this.logIn$.next(true);

        this.userStore.setUserId(userId);
        this.userStore.setTokens(accessToken, refreshToken);
      })
    );
  }

  signOut(): Observable<void> {
    return this.http.post<void>(`${this.usersApiUrl}/logout`, null).pipe(
      tap(() => {
        this.logOut$.next(false);
        this.userStore.deleteTokens();
      })
    );
  }

  getUser(): Observable<UserDataResponse> {
    return this.http.get<UserDataResponse>(`${this.usersApiUrl}/user`);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.http.put<void>(`${this.userPasswordApiUrl}/change`, { currentPassword, newPassword });
  }

  setNewPasswordRequest(firstName: string, lastName: string, email: string): Observable<void> {
    return this.http.post<void>(`${this.userPasswordApiUrl}/reset/init`, { firstName, lastName, email });
  }

  getNewPasswordRequest(uuid: string): Observable<void> {
    return this.http.get<void>(`${this.userPasswordApiUrl}/${uuid}/reset/validate`);
  }

  setNewPassword(uuid: string, email: string, password: string) {
    return this.http.post(`${this.userPasswordApiUrl}/${uuid}/reset/confirm`, { email, password });
  }
}

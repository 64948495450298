import { Component, ContentChildren, HostBinding, Input, OnDestroy, QueryList, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, RouterLinkActive } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[nvProgressNavigationItem]',
  templateUrl: './progress-navigation-item.component.html',
  styleUrls: ['./progress-navigation-item.component.scss'],
})
export class NvProgressNavigationItemComponent {
  @Input() title: string;
  @Input() link: string;
  @Input() exact = false;
  @Input() index: number;
  @Input() navigatable: boolean;
  @Input() iconPath: string;

  @HostBinding('class') get hostClasses() {
    // TODO: set navigatable depending on guards
    return `${this.navigatable ? 'navigatable' : ''}${this.active ? ' active' : ''}`;
  }

  active: boolean;
  @ViewChild(RouterLinkActive, { static: true }) rla: RouterLinkActive;
}

@Component({
  selector: 'nv-progress-navigation',
  templateUrl: './progress-navigation.component.html',
  styleUrls: ['./progress-navigation.component.scss'],
})
export class NvProgressNavigationComponent implements OnDestroy {
  @ContentChildren(NvProgressNavigationItemComponent) navItems: QueryList<NvProgressNavigationItemComponent>;
  activeItem: NvProgressNavigationItemComponent;
  @Input() fixed = true;

  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router) {
    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveItem();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateActiveItem() {
    // unfortunately not avoidable
    setTimeout(() => {
      this.navItems?.forEach((item) => (item.active = item.rla.isActive));
      this.activeItem = this.navItems?.find((item) => item.active);
    }, 0);
  }
}

import { CorrectedPostalAddress } from './correctedPostalAddress';

export interface ValidationDataPostalAddressList {
  correctedPostalAddress: CorrectedPostalAddress;
  flagADCheckResult: string;
}

export enum validationFlag {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Corrected = 'CORRECTED',
  Suggestion = 'SUGGESTION',
}

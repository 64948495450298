import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppConfigService } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-wizard-header',
  templateUrl: './wizard-header.component.html',
  styleUrls: ['./wizard-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvWizardHeaderComponent {
  @Input() logoHref = '';
  @Input() enableChat: boolean;

  get isExternalLink() {
    return this.logoHref?.startsWith('http');
  }

  constructor(private appConfigService: AppConfigService) {}

  public showLoginLogoutButton(): boolean {
    return this.appConfigService.config.showLoginButtonInFooter;
  }
}

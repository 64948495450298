import { Component, Inject, Optional } from '@angular/core';
import { UserService } from '@nbg-digital/user-management/domain';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginDialogService } from '../login-dialog/login-dialog.service';

@Component({
  selector: 'nv-user-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
})
export class LoginButtonComponent {
  public showLoginButton$: Observable<boolean> = combineLatest([
    this.userService.isAuthenticated$,
    this.loginDialogService?.teaserVisible$,
  ]).pipe(map(([isAuthenticated, teaserVisible]) => !isAuthenticated && !teaserVisible));

  public shakeLoginButton$: Observable<boolean> = this.loginDialogService?.usedMail$;

  constructor(
    @Inject('enableUserLogin') public enableUserLogin: boolean,
    @Optional() private loginDialogService: LoginDialogService,
    private userService: UserService
  ) {}

  login() {
    this.loginDialogService.showLoginDialog(false);
  }
}

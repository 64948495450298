import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvWiggleDirective } from './wiggle.directive';

@NgModule({
  declarations: [NvWiggleDirective],
  imports: [CommonModule],
  exports: [NvWiggleDirective],
})
export class NvWiggleModule {
}

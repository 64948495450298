import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable()
export class LoginDialogService {
  private _teaserVisible = new Subject<boolean>();
  private _showLogin = new Subject<boolean>();
  private _usedMail = new Subject<boolean>();

  teaserVisible$ = this._teaserVisible.asObservable().pipe(startWith(true));
  showLogin$ = this._showLogin.asObservable();
  usedMail$ = this._usedMail.asObservable();

  showTeaser() {
    this._teaserVisible.next(true);
  }

  hideTeaser() {
    this._teaserVisible.next(false);
  }

  showLoginDialog(sessionExpired: boolean) {
    this._showLogin.next(sessionExpired);
  }

  shakeLoginButton(shake: boolean) {
    this._usedMail.next(shake);
  }
}

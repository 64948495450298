import { ClickId } from '../../store/tracking.store';

export const TEALIUM_SESSION_LOGIN = 'nicht eingeloggt';

export interface TealiumSessionData {
  session_domain: string;
  session_login: string;
  process_id: string;
  campaign_clickid?: string;
}

export function createTealiumSessionData(processId: string, clickId: ClickId, sessionDomain: string) : TealiumSessionData {
  return {
    session_domain: sessionDomain,
    session_login: TEALIUM_SESSION_LOGIN,
    process_id: processId || '',
    campaign_clickid: clickId ? `${clickId.key}=${clickId.value}` : "",
  };
}

export const TEST_CUSTOMER_INFORMATION = {
  personalInformation: {
    firstname: 'Anas',
    lastname: 'Ashour',
    salutation: 'MR',
    birthDate: '1990-01-01',
    street: 'XXYY',
    houseNumber: '9',
    zip: '90518',
    city: 'Altdorf',
    country: 'Deutschland',
    phone: '0123456789',
    email: 'anas.ashour@codecamp-n.com',
    nationality: 'deutsch',
    cityOfBirth: 'Damascus',
    smoker: null,
  },
  paymentInformation: {
    bankName: 'Die lange Bankname',
    bankShortName: 'die Bankname',
    bic: 'was ist bic',
    iban: 'DE27100777770209299700 ',
  },
};

export const TEST_CUSTOMER = {
  firstname: 'string',
  lastname: 'string',
  salutation: 'MRS',
  birthDate: '1990-01-01',
  street: 'string',
  houseNumber: 'string',
  zip: 'string',
  city: 'string',
  country: 'string',
  phone: 'string',
  email: 'string',
  nationality: 'string',
  smoker: 'SMOKER',
};

export const TEST_CUSTOMER_PARTIAL = {
  firstname: 'string',
  lastname: 'string',
  birthDate: '1990-01-01',
  street: 'string',
  houseNumber: 'string',
  zip: 'string',
  city: 'string',
  phone: 'string',
};

export const TEST_CUSTOMER_STATE = {
  firstname: 'Richard',
  lastname: 'Testerino',
  birthDate: '1884-02-01',
  street: 'Solgerstraße',
  houseNumber: '18',
  zip: '90429',
  city: 'Nürnberg',
  phone: '01234567890',
};

export const TEST_POLICYINFORMATION = {
  tariff: 'basic',
  insuranceStart: '2021-12-13',
  insuranceEnd: '2023-12-13',
  inactivationDate: '2021-12-30',
  inactivationExecutionDate: '2021-12-31',
  terminationOption: 'REVOCATION',
  premium: {
    net: 123456,
    gross: 654321,
  },
  paymentCycle: 'MONTHLY',
};

export const TEST_CONTRACT = {
  product: 'RLV',
  policyNumber: 'string',
  status: 'string',
  subBroker: 'string',
  mainBroker: 'EVOX',
  personalInformation: {
    customer: TEST_CUSTOMER,
    beneficiary: {
      personalInformation: {
        firstname: 'string',
        lastname: 'string',
        salutation: 'MRS',
        birthDate: '2000-01-01',
        street: 'string',
        houseNumber: 'string',
        zip: 'string',
        city: 'string',
        country: 'string',
        phone: 'string',
        email: 'string',
        nationality: 'string',
        smoker: 'NONSMOKER',
      },
      type: 'CHILD',
    },
  },
  paymentInformation: {
    bankName: 'string',
    bankShortName: 'string',
    bic: 'string',
    iban: 'string',
  },
  policyInformation: TEST_POLICYINFORMATION,
};

export const TEST_CONTRACT_OVERVIEWS = [
  {
    product: 'DKR',
    policyNumber: '200123125',
    status: 'ACTIVE',
    subBroker: '123123123',
    mainBroker: 'EVOX',
  },
  {
    product: 'RLV',
    policyNumber: '200123124',
    status: 'INACTIVE',
    subBroker: '123123123',
    mainBroker: 'EVOX',
  },
  {
    product: 'DKR',
    policyNumber: '200123123',
    status: 'OK',
    subBroker: '123123123',
    mainBroker: 'EVOX',
  },
];

export const TEST_MESSAGES = [
  {
    content: 'Message 1',
    date: new Date(),
    attachments: [
      {
        index: 1,
        id: 'attachment1',
        filename: 'file1.txt',
      },
    ],
    id: '1',
    messageType: 'MESSAGE',
    read: false,
    subject: 'Message 1',
  },
  {
    content: 'Message 2',
    date: new Date(),
    attachments: [
      {
        index: 1,
        id: 'attachment1',
        filename: 'file1.txt',
      },
      {
        index: 2,
        id: 'attachment2',
        filename: 'file2.txt',
      },
    ],
    id: '2',
    messageType: 'MESSAGE',
    read: true,
    subject: 'Message 2',
  },
  {
    content: 'Message 3',
    date: new Date(),
    id: '2',
    messageType: 'MESSAGE',
    read: true,
    subject: 'Message 2',
  },
];

export const TEST_CUSTOMER_SERVICE_STATE = {
  customer: {
    firstname: 'Peter',
    lastname: 'Zweck',
    salutation: 'MR',
    birthDate: '1990-01-01',
    street: 'Teststrasse',
    houseNumber: '9',
    zip: '90518',
    city: 'Altdorf',
    country: 'Deutschland',
    phone: '0123456789',
    smoker: null,
  },
  contracts: [TEST_CONTRACT_OVERVIEWS[2]],
  messages: [
    {
      id: '1',
      subject: 'Test',
      content: 'Hier ist ein Testinhalt',
      read: true,
      date: new Date('2020-01-01'),
      attachment: false,
      filename: 'Testname',
      messageType: 'UNKNOWN',
    },
  ],
  unreadMessages: [
    {
      id: '2',
      subject: 'Test',
      content: 'Hier ist ein ungelesener Testinhalt',
      read: false,
      date: new Date('2020-02-01'),
      attachment: false,
      filename: 'Testname-2',
      messageType: 'UNKNOWN',
    },
  ],
};

export const TEST_CUSTOMER_SERVICE_CONTRACT_RESPONSE = {
  contractDetails: {
    product: 'RLV',
    coverage: 100000,
  },
  policyHolder: {
    firstname: 'Frieda',
    lastname: 'Hatzu',
    salutation: 'MR',
    birthDate: '1990-01-01',
    street: 'Fedderstrasse',
    houseNumber: '9',
    zip: '90518',
    city: 'Altdorf',
    country: 'Deutschland',
    phone: '0123456789',
    email: 'test.person@codecamp-n.com',
    nationality: 'deutsch',
    cityOfBirth: 'Damascus',
    smoker: null,
  },
  insuredPerson: {
    firstname: 'Frieda',
    lastname: 'Hatzu',
    salutation: 'MR',
    birthDate: '1990-01-01',
    street: 'Fedderstrasse',
    houseNumber: '9',
    zip: '90518',
    city: 'Altdorf',
    country: 'Deutschland',
    phone: '0123456789',
    email: 'test.person@codecamp-n.com',
    nationality: 'deutsch',
    cityOfBirth: 'Damascus',
    smoker: null,
  },
  paymentInformation: {
    bankName: 'Volksbank Fürth',
    bankShortName: 'VBF',
    bic: 'TERYDEM1FZN',
    iban: 'DE27834958345898930202 ',
  },
  policyInformation: {
    tariff: 'basic',
    premium: {
      net: 123456,
      gross: 654321,
    },
    insuranceStart: '2021-12-13',
    insuranceEnd: '2021-12-13',
    inactivationDate: '2021-12-13',
    inactivationExecutionDate: '2021-12-13',
    terminationOption: 'REVOCATION',
    paymentCycle: 'MONTHLY',
  },
  product: 'RLV',
  policyNumber: '123123123',
  status: 'OK',
  subBroker: '321321321',
  mainBroker: 'EVOX',
};

export const TEST_CUSTOMER_SERVICE_PERSONAL_DATA = {
  policyNumber: '2220123123',
  firstname: 'Dönähr',
  lastname: 'Tellähr',
  birthDate: '2000-01-01',
  street: 'Flowerstreet',
  houseNumber: '66',
  zip: '90123',
  city: 'Feddtown',
  phone: '0160123123123123',
};

export const TEST_CUSTOMER_SERVICE_PAYMENT_DATA = {
  policyNumber: '2220123123',
  changeDate: '2022-06-06',
  iban: 'DE27100777770209299700',
};

import { Injectable } from '@angular/core';
import {
  ConsentData,
  ConsentManagementState,
  ConsentManagementStore,
  ConsentService,
  CustomerConsent,
  CustomerEweResponse,
  DistributorInfo,
  RatingData,
} from '@nbg-digital/consent-management/domain';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConsentManagementFacade {
  constructor(private consentService: ConsentService, private consentManagementStore: ConsentManagementStore) {}

  public reentryData$: Observable<ConsentManagementState> = this.consentManagementStore.select((state) => state);

  public getCustomerConsent(userId: string): Observable<CustomerEweResponse> {
    return this.consentService.getCustomerConsent(userId);
  }

  public getCustomerEweMail(): Observable<boolean> {
    return this.consentManagementStore.selectCustomerEweMail();
  }

  public getCustomerEwePhone(): Observable<boolean> {
    return this.consentManagementStore.selectCustomerEwePhone();
  }

  public getDistributorId(): Observable<string> {
    return this.consentManagementStore.selectDistributorId();
  }

  public createConsent(consentData: ConsentData) {
    return this.consentService.createConsent(consentData);
  }

  public createCustomerConsent(customerConsent: CustomerConsent, userId: string) {
    return this.consentService.createCustomerConsent(customerConsent, userId);
  }

  public createDistributorInfo(distributorInfo: DistributorInfo) {
    return this.consentService.createDistributorInfo(distributorInfo);
  }

  public createRating(ratingData: RatingData) {
    return this.consentService.createRating(ratingData);
  }

  public setDistributorId(distributorId: string) {
    this.consentManagementStore.setDistributorId(distributorId);
  }

  public setCustomerEweMail(customerEweMail: boolean) {
    return this.consentManagementStore.setCustomerEweMail(customerEweMail);
  }

  public setCustomerEwePhone(customerEwePhone: boolean) {
    return this.consentManagementStore.setCustomerEwePhone(customerEwePhone);
  }
}

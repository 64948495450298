// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Array<T> {
  remove(elem: T): Array<T>;
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function <T>(this: T[], elem: T): T[] {
    const index = this.indexOf(elem);
    if (index > -1) {
      this.splice(index, 1);
    }
    return this;
  };
}

export class ReentryUrlService {
  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }

  constructor(private _baseUrl: string) {}
}

export const ReentryUrlServiceFactory = (baseUrl: string = '') => new ReentryUrlService(baseUrl);

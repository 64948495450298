export function loadStylesheet(src: string) {
  if (!document) {
    return;
  }

  //check if Stylesheet is already present and don't add a second one
  const links = document.getElementsByTagName('link');
  if (Array.from(links).filter((link) => link.href == src).length >= 1) {
    return;
  }

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = src;
  document.head.appendChild(link);
}

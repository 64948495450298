import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvErrorPageComponent } from './error-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NvButtonModule } from '@nbg-digital/ui-dpl-components';
import { NvWizardHeaderModule } from '../wizard-header/wizard-header.module';

@NgModule({
  declarations: [NvErrorPageComponent],
  imports: [CommonModule, RouterModule, TranslateModule, NvButtonModule, NvWizardHeaderModule],
  exports: [NvErrorPageComponent],
})
export class NvErrorPageModule {}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'nv-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class NvCardComponent {
  @Input() class = '';
  @Input() fullHeight = false;

  @HostBinding('class') get hostClasses(): string {
    return ['nv-card', ...(this.class ? [this.class] : [])].join(' ');
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationLoaderService implements OnDestroy {
  private loadingSubject$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<void>();

  public loading$ = this.loadingSubject$.asObservable();

  constructor(private router: Router) {
    // Workaround for local reentry testing:
    // environment.local.ts -> production: true,

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.loadingSubject$.next(true);
      } else if (
        this.loadingSubject$.getValue() !== false &&
        (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)
      ) {
        this.loadingSubject$.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvLoadingSpinnerModalComponent } from './loading-spinner-modal.component';
import { NvModalModule } from '../modal/modal.module';
import { NvLoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [NvLoadingSpinnerModalComponent],
  imports: [CommonModule, NvModalModule, NvLoadingSpinnerModule],
  exports: [NvLoadingSpinnerModalComponent],
})
export class NvLoadingSpinnerModalModule {}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Country {
  country: string;
  nationality: string;
  iso: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private httpClient: HttpClient, @Inject('baseUrl') private baseUrl: string) {}

  public getNationality(code: string): Observable<string> {
    return this.getCountryList().pipe(
      map((countries) => countries.find((nationality) => nationality.iso === code)?.nationality)
    );
  }

  public getCountry(code: string): Observable<string> {
    return this.getCountryList().pipe(
      map((countries) => countries.find((nationality) => nationality.iso === code)?.country)
    );
  }

  public getCountryList(): Observable<Country[]> {
    return this.httpClient
      .get<{ nationalities: Country[] }>(`${this.baseUrl}/assets/i18n/countries.json`)
      .pipe(map((country) => country.nationalities));
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MagnumGoParameters } from '../models/magnum-go-parameters';
import { MagnumGoConfig, MagnumGoPricingParameters } from '../models/magnum-go-config';
import { MagnumGoResult } from '../models/magnum-go-result';
import { InterceptorSkipHeader, ProductService } from '@nbg-digital/shared/util';
import { MainBroker } from '../models/main-broker';
import { Product } from '../models/product';
import { RiskAssessmentResultSummary } from '../models/risk-assessment-result-summary';

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentService {
  constructor(
    private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string,
    @Inject('mainBroker') private mainBroker: MainBroker,
    private productService: ProductService
  ) {}

  initiateAssessment(
    offerId: string,
    pricingParameters: MagnumGoPricingParameters,
    acquisitionAgent: string
  ): Observable<MagnumGoParameters> {
    const config: MagnumGoConfig = {
      offerId,
      pricingParameters,
      product: this.productService.product,
      mainBroker: this.mainBroker,
      acquisitionAgent,
    };
    return this.http.post<MagnumGoParameters>(`${this.apiUrl}/riskAssessment`, config, {
      headers: {
        [InterceptorSkipHeader]: '',
      },
    });
  }

  getAssessmentResult(caseId: string): Observable<MagnumGoResult> {
    return this.http.get<MagnumGoResult>(`${this.apiUrl}/riskAssessment/${caseId}`);
  }

  writeAssessmentResult(
    { decisionStatus, adjustment, underwriting }: MagnumGoResult,
    offerId: string,
    product: Product,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    profession: any
  ) {
    const resultSummary: RiskAssessmentResultSummary = {
      offerId,
      product,
      profession,
      decisionStatus,
      adjustment,
      underwriting,
    };
    return this.http.put(`${this.apiUrl}/riskAssessment/result`, resultSummary);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { NvDropdownComponent } from './dropdown.component';
import { NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';

let uuid = 0;

@Component({
  selector: 'nv-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class NvDropdownItemComponent implements OnInit, OnDestroy {
  @Input() id = `nv-dropdown-item-${++uuid}`;

  @Input()
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (this._value !== value) {
      this._value = value;
    }
  }

  @Input()
  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  private _value: string;
  private _label: string;

  @Output() readonly changeEventEmitter = new EventEmitter<NvDropdownItemComponent>();
  @ViewChild(NgbDropdownItem, { static: true }) ngbItem: NgbDropdownItem;

  constructor(@Optional() private dropdown: NvDropdownComponent) {
    this.dropdown.registerOption(this);
  }

  ngOnInit() {
    if (this.dropdown) {
      this.dropdown.updateSelectedOption();
    }
  }

  public onSelect() {
    this.dropdown.setOption(this);
  }

  public onTab() {
    this.onSelect();
    this.dropdown.ngbDropdown.close();
  }

  ngOnDestroy(): void {
    this.dropdown.unregisterOption(this);
  }
}

import { Component, Optional } from '@angular/core';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { AppConfigService } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-error-page',
  templateUrl: './error-page.component.html',
})
export class NvErrorPageComponent {
  backToStartLink = '/';

  constructor(
    @Optional() private userFacade: UserManagementFacade,
    @Optional() private pricingFacade: PricingFacade,
    @Optional() private applicationFacade: ApplicationFacade,
    @Optional() private riskAssessmentFacade: RiskAssessmentFacade,
    public appConfigService: AppConfigService
  ) {
    this.userFacade.resetRegistrationState();
    this.pricingFacade.resetState();
    this.applicationFacade.resetState();
    this.riskAssessmentFacade.resetState();
  }
}

export const TEST_SESSION_DATA = {
  offerId: '12345678abcdefg',
  email: 'max.mustermann@email.de',
  product: 'rlv',
  data: {
    pricing: null,
    application: null,
    riskAssessment: null,
    userManagement: null,
  },
  channel: 'ONLINE',
  isUserLoggedIn: false,
  mainBroker: 'EVOX',
  reentryUrl: 'https://hanswo.rs',
};

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkBoxControlValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NvCheckboxComponent),
  multi: true,
};

let uid = 0;

@Component({
  selector: 'nv-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [checkBoxControlValueAccessor],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvCheckboxComponent implements ControlValueAccessor {
  @Input() id = `nv-checkbox-${++uid}`;
  @Input() name: string | null = null;
  @Input() value: string;
  @Input() appearance: 'checkbox' | 'switch' = 'checkbox';

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this.checked) {
      this._checked = newValue;
      this.cdr.markForCheck();
    }
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this.cdr.markForCheck();
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _checked = false;
  private _disabled = false;
  private _required = false;

  // ControlValueAccessor implementation

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    this.checked = !!value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (fn: void) => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onTouched = (fn: void) => {};

  constructor(private cdr: ChangeDetectorRef) {}
}

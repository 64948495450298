import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Abstract base component for reusable partial forms. T is the type of the data provided and expected by the parent form,
 * i.e. not necessarily the form model.
 */
@Directive()
export abstract class PartialFormComponent<T> implements OnInit, OnDestroy {
  /**
   * Is emitted when the partial form has been successfully setup.
   * To be handled by the parent component to set the child formGroup accordingly.
   */
  @Output() formReady = new EventEmitter<UntypedFormGroup>();

  /**
   * Is emitted when the partial form destroys itself.
   * To be handled by the parent component to reset the child formGroup accordingly.
   */
  @Output() formDestroyed = new EventEmitter<UntypedFormGroup>();

  /**
   * Value used to populate the form group / read the current form group value.
   */
  @Input()
  set value(value: Partial<T>) {
    this.formGroup.patchValue(this.toFormData(value));
  }

  get value(): T {
    return this.fromFormData();
  }

  /**
   * The partial form to be incorporated in a parent form.
   */
  public formGroup: UntypedFormGroup;

  /**
   * Whether or not the form group is currently valid.
   */
  public isValid() {
    return this.formGroup.valid;
  }

  /**
   * Maps the data provided to the form model.
   */
  protected abstract toFormData(value: Partial<T>);

  /**
   * Returns the current form data mapped to the type expected by the parent component.
   */
  protected abstract fromFormData(): T;

  ngOnInit(): void {
    this.formReady.emit(this.formGroup);
  }

  ngOnDestroy() {
    this.formDestroyed.emit();
  }
}

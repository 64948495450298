import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import {
  NvButtonModule,
  NvCardModule,
  NvContainerModule,
  NvLoadingSpinnerModule,
  NvModalModule,
  NvPricingFooterModule,
  NvProgressNavigationModule,
} from '@nbg-digital/ui-dpl-components';
import { StorageAgreementModule } from '@nbg-digital/session-management/feature';
import {
  LoginButtonModule,
  LoginDialogModule,
  LoginTeaserModule,
  LoginTeaserSuccessModule,
  LogOutButtonModule,
} from '@nbg-digital/user-management/feature';
import { InsuranceApplicationWizardComponent } from './insurance-application-wizard.component';
import { NvWizardHeaderModule } from '../wizard-header/wizard-header.module';
import { TranslateUtilsModule } from '@nbg-digital/shared/util';

@NgModule({
  declarations: [InsuranceApplicationWizardComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NvContainerModule,
    NvWizardHeaderModule,
    NvProgressNavigationModule,
    NvLoadingSpinnerModule,
    NvPricingFooterModule,
    NvModalModule,
    NvWizardHeaderModule,
    StorageAgreementModule,
    LoginDialogModule,
    LoginTeaserModule,
    LoginTeaserSuccessModule,
    LogOutButtonModule,
    NvCardModule,
    TranslateUtilsModule,
    NvButtonModule,
    LoginButtonModule,
  ],

  exports: [InsuranceApplicationWizardComponent],
})
export class InsuranceApplicationWizardModule {}

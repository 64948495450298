export type OrderType = 'Lead1' | 'Lead5' | '';

export interface TealiumOrderData {
  order_type?: OrderType;
  order_details?: string[];
  order_value?: string;
  order_id?: string;
}

export function createTealiumOrderData(
  orderType: OrderType,
  orderValue: number,
  orderId: string,
  orderDetails: string
): TealiumOrderData {
  return {
    order_type: orderType || '',
    order_value: orderValue?.toFixed(2) || '',
    order_id: orderId || '',
    order_details: [orderDetails || ''],
  };
}

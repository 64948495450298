import { Injectable } from '@angular/core';
import { Store } from '@nbg-digital/shared/util';

export interface UserState {
  username: string;
  salutation: string;
  firstname: string;
  lastname: string;
  accessToken: string;
  refreshToken: string;
  userId: string;
}

const initialState: UserState = {
  username: null,
  salutation: null,
  firstname: null,
  lastname: null,
  accessToken: null,
  refreshToken: null,
  userId: null,
};

@Injectable({
  providedIn: 'root',
})
export class UserStore extends Store<UserState> {
  constructor() {
    super('user');
  }

  public getRefreshToken() {
    return this.select((state) => state.refreshToken);
  }

  public selectUserId() {
    return this.select((state) => state.userId);
  }

  public setUserId(userId: string) {
    this.update({ userId });
  }

  public setTokens(accessToken: string, refreshToken: string) {
    this.update({ accessToken, refreshToken });
  }

  public deleteTokens() {
    this.update({ accessToken: null, refreshToken: null });
  }

  protected getInitialState(): UserState {
    return initialState;
  }

  selectUsername() {
    return this.select((state) => state.username);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LoadingCause = HttpRequest<any> | NavigationStart;
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loadingCauses$ = new BehaviorSubject<LoadingCause[]>([]);
  isLoading$: Observable<boolean>;

  constructor() {
    this.isLoading$ = this.loadingCauses$.pipe(
      map((causes) => {
        return causes.length > 0;
      }),
      distinctUntilChanged(),
      debounceTime(100)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public add(cause: HttpRequest<any>) {
    const causes = this.loadingCauses$.getValue();
    causes.push(cause);
    this.loadingCauses$.next(causes);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public remove(cause: HttpRequest<any>) {
    const causes = this.loadingCauses$.getValue();
    causes.remove(cause);
    this.loadingCauses$.next(causes);
  }
}

import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '@nbg-digital/user-management/domain';
@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    @Inject('enableUserLogin') private enableUserLogin: boolean
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (!this.userService.isAuthenticated || !this.enableUserLogin) {
      return true;
    }
    const { nextLink, previousLink } = route.data.navigation;
    const redirectUrl = nextLink !== this.router.url ? nextLink : previousLink;
    return this.router.createUrlTree([redirectUrl]);
  }
}

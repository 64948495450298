import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfessionService {
  constructor(private httpClient: HttpClient, @Inject('apiUrl') private apiUrl: string) {}

  getProfessionList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiUrl}/jobs`);
  }

  getJobValidity(jobName: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.apiUrl}/jobs/valid`, {
      params: {
        jobName: jobName,
      },
    });
  }
}

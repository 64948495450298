import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvFormInfoContainerComponent } from './form-info-container.component';

@NgModule({
  declarations: [NvFormInfoContainerComponent],
  imports: [CommonModule],
  exports: [NvFormInfoContainerComponent],
})
export class NvFormInfoContainerModule {}

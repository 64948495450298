import { Component, Input } from '@angular/core';

@Component({
  selector: 'nv-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class NvInfoBoxComponent {
  @Input() type: 'info' | 'alert' = 'info';
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerInformationResponse, CustomerInformationService } from '@nbg-digital/customer-service/domain';

@Injectable({
  providedIn: 'root',
})
export class CustomerInformationFacade {
  constructor(private customerInformationService: CustomerInformationService) {}

  getCustomerInformation(): Observable<CustomerInformationResponse> {
    return this.customerInformationService.getCustomerInformation();
  }
}

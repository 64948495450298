import { NgModule } from '@angular/core';
import { NvPasswordStrengthComponent } from './password-strength.component';
import { CommonModule } from '@angular/common';
import { NvProgressBarModule } from '../progress-bar/progress-bar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NvPasswordStrengthComponent],
  imports: [CommonModule, NvProgressBarModule, TranslateModule],
  exports: [NvPasswordStrengthComponent],
})
export class NvPasswordStrengthModule {}

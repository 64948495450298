import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from './login-dialog.component';
import { NvModalModule } from '@nbg-digital/ui-dpl-components';
import { LoginFormModule } from '../../login-form/login-form.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginDialogService } from './login-dialog.service';

@NgModule({
  declarations: [LoginDialogComponent],
  imports: [CommonModule, TranslateModule, LoginFormModule, NvModalModule],
  exports: [LoginDialogComponent],
  providers: [LoginDialogService],
})
export class LoginDialogModule {}

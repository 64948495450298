import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryService } from './country.service';

@Pipe({
  name: 'nationality',
})
export class NationalityPipe implements PipeTransform {
  constructor(private countryService: CountryService) {}

  transform(value: string): Observable<string> {
    return this.countryService.getNationality(value);
  }
}

import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';

const datePickerControlValueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NvDatePickerComponent),
  multi: true,
};

let uid = 0;

@Component({
  selector: 'nv-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [datePickerControlValueAccessor],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvDatePickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild(NgbInputDatepicker, { static: true }) ngbDatepicker: NgbInputDatepicker;

  @Input() minDate: string;
  @Input() maxDate: string;
  @Input()
  id = `nv-date-picker-${++uid}`;
  @Input() placeholder?: string = null;
  @Input() patternHint?: string = null;
  @Input() limitToFirstOfMonth? = false;
  @Input() readOnly?: boolean;
  @Input() showToggle = true;
  @Input() floatingLabel = true;

  public value: string;

  public maxNgbDate: NgbDateStruct;
  public minNgbDate: NgbDateStruct;

  // the order actually changes the outcome
  // TODO: check formats, which to keep, which to get rid of
  private formats = ['DDMMYYYY', 'DDMMYY', 'DD.MM.YYYY', 'DD.MM.YY', 'D.M.YY', 'YYYY-MM-DD', 'DD-MM-YYYY'];

  constructor(public ngbDateAdapter: NgbDateAdapter<string>) {}

  ngOnInit() {
    this.maxNgbDate = this.ngbDateAdapter.fromModel(this.maxDate);
    this.minNgbDate = this.ngbDateAdapter.fromModel(this.minDate);
  }

  allowOnlyFirstOfMonth(): (date: NgbDate) => boolean {
    if (this.limitToFirstOfMonth) {
      return (date: NgbDate) => date.day > 1;
    } else {
      return null;
    }
  }

  onNbgDatepickerChange(event: NgbDateStruct) {
    this.value = event ? dayjs(this.ngbDateAdapter.toModel(event)).format('YYYY-MM-DD') : null;
    this.value = this.setInvalidDateToNull();
    this.onTouched();
    this.onChange(this.value);
  }

  onInputChange(event: string) {
    this.value = event ? dayjs(event, this.formats, true).format('YYYY-MM-DD') : null;
    this.value = this.setInvalidDateToNull();
    this.onTouched();
    this.onChange(this.value);
  }

  setInvalidDateToNull(): string {
    return dayjs(this.value).isValid() ? this.value : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (valueChange) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // ControlValueAccessor
  writeValue(value: string): void {
    this.value = value;
    this.ngbDatepicker.writeValue(value);
  }

  registerOnChange(fn): void {
    this.onChange = fn;
    this.ngbDatepicker.registerOnChange(fn);
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
    this.ngbDatepicker.registerOnTouched(fn);
  }
}

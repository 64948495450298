import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nv-wizard-page-navigation',
  templateUrl: './wizard-page-navigation.component.html',
  styleUrls: ['./wizard-page-navigation.component.scss'],
})
export class NvWizardPageNavigationComponent {
  @Input() forwardLink = '/';
  @Input() forwardColor = 'secondary';
  @Input() forwardTitle = '';
  @Input() forwardDisabled = false;
  @Input() backLink: string;
  @Input() backwardColor = 'light';
  @Input() backTitle = '';
  @Input() showBackArrow = true;
  @Input() trackForwardAction = false;
  @Input() trackBackAction = false;
  @Input() trustElement = false;

  constructor(private router: Router) {}

  navigateToNext() {
    if (this.forwardLink) {
      this.router.navigate([this.forwardLink]);
    }
  }
}

import { Component, EventEmitter, Inject, Input, OnDestroy, Optional, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '@nbg-digital/user-management/domain';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nv-user-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent implements OnDestroy {
  @Input() showTitle = true;
  @Output() logoutSuccess = new EventEmitter<void>();
  public isLoggedIn$ = this.userService.isAuthenticated$;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private router: Router,
    @Optional() @Inject('loginPath') private loginPath,
    @Optional() @Inject('enableUserLogin') public enableUserLogin: boolean
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logOut() {
    this.userService
      .signOut()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.logoutSuccess.emit();
        if (this.loginPath) {
          this.router.navigate(['/', this.loginPath]);
        }
      });
  }
}

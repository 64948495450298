import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvErrorComponent } from './error.component';

@NgModule({
  declarations: [NvErrorComponent],
  imports: [CommonModule, NgbModule],
  exports: [NvErrorComponent],
})
export class NvErrorModule {}

export function loadScript(src: string, id: string = '', defer: boolean = false) {
  if (!document) {
    return;
  }

  //check if Script is already present and don't add a second one
  const scripts = document.getElementsByTagName('script');
  if (Array.from(scripts).filter((script) => script.src == src).length >= 1) {
    return;
  }

  const script = document.createElement('script');
  script.lang = 'javascript';
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  if (id) {
    script.id = id;
  }
  if (defer) {
    script.defer = defer;
  }
  document.head.appendChild(script);
}

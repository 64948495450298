import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  allowedSpecialCharacters,
  emailPattern,
  isEqualPassword,
  validatePasswordStrength,
} from '@nbg-digital/shared/util';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UserService } from '@nbg-digital/user-management/domain';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'nv-user-password-recovery',
  templateUrl: 'password-recovery.component.html',
})
export class PasswordRecoveryComponent implements OnInit {
  @ViewChild('modalPasswordChanged', { static: true })
  private modalPasswordChanged: NvModalComponent;
  @ViewChild('modalLinkExpired', { static: true })
  private modalLinkExpired: NvModalComponent;

  private uuid: string;

  specialChars = allowedSpecialCharacters;
  passwordRecoveryForm: UntypedFormGroup;
  passwordStrength: Event;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fBuilder: UntypedFormBuilder,
    private userService: UserService,
    @Inject('passwordForgotPath') private passwordForgotPath: string,
    @Inject('loginPath') private loginPath: string
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');

    this.passwordRecoveryForm = this.fBuilder.group(
      {
        email: ['', [Validators.required, emailPattern]],
        password: ['', [Validators.required, Validators.minLength(8), validatePasswordStrength]],
        passwordConfirmation: ['', Validators.required],
      },
      { validators: [isEqualPassword()] }
    );
  }

  ngOnInit() {
    this.userService
      .getNewPasswordRequest(this.uuid)
      .pipe(
        catchError(() => {
          this.modalLinkExpired.open();
          return of();
        })
      )
      .subscribe();
  }

  onPassed(event: Event) {
    this.passwordStrength = event;
  }

  submit(event: Event) {
    event.preventDefault();
    this.passwordRecoveryForm.markAllAsTouched();
    if (this.passwordRecoveryForm.valid) {
      const { email, password } = this.passwordRecoveryForm.value;
      this.userService.setNewPassword(this.uuid, email, password).subscribe(() => {
        this.modalPasswordChanged.open();
      });
    }
  }

  submitModalLinkExpired(event: Event) {
    event.preventDefault();
    this.modalLinkExpired.close();
    this.router.navigate([this.passwordForgotPath]);
  }

  onModalLinkExpiredClosed() {
    this.router.navigate([this.passwordForgotPath]);
  }

  submitModalPasswordChanged() {
    this.modalPasswordChanged.close();
    this.router.navigate([this.loginPath]);
  }

  onModalPasswordChangedClosed() {
    this.router.navigate([this.loginPath]);
  }
}

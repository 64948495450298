import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvSliderComponent } from './slider.component';

@NgModule({
  declarations: [NvSliderComponent],
  imports: [CommonModule],
  exports: [NvSliderComponent],
})
export class NvSliderModule {}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'nv-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class NvProgressBarComponent implements OnInit, OnChanges {
  @Input() size = 0;
  @Input() color: 'error' | 'warning' | 'success' | 'secondary' = 'error';
  classes = '';

  private setClasses(): string {
    return ['progress-bar', `w-${this.size}`, `bg-${this.color}`].join(' ');
  }

  ngOnInit(): void {
    this.classes = this.setClasses();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.classes = this.setClasses();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NvRadioButtonComponent } from './radio';
import { NvRadioGroupDirective } from './radio';

@NgModule({
  declarations: [NvRadioButtonComponent, NvRadioGroupDirective],
  imports: [CommonModule],
  exports: [NvRadioButtonComponent, NvRadioGroupDirective],
})
export class NvRadioModule {}

import { BreakpointObserver } from '@angular/cdk/layout';

/**
 * Determines the current breakpoint for tracking purposes.
 * Expects the breakpointObserver as input.
 */
export function getCurrentTrackingBreakpoint(breakpointObserver: BreakpointObserver): string {
  const trackingBreakpoints = [
    {
      name: 'xs',
      maxWidth: 660,
    },
    {
      name: 'sm',
      maxWidth: 1024,
    },
    {
      name: 'md',
      maxWidth: 1280,
    },
  ];

  const currentBreakpoint = trackingBreakpoints.find(breakpoint =>
    breakpointObserver.isMatched(`(max-width: ${breakpoint.maxWidth - 1}px)`)
  );

  return currentBreakpoint ? currentBreakpoint.name : 'lg';
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DecriptedAgencyNumberResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class AgencyService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {}

  getDecryptedAgencyNumber(id: string): Observable<DecriptedAgencyNumberResponse> {
    return this.http.get<DecriptedAgencyNumberResponse>(`${this.apiUrl}/agency/${id}`);
  }
}

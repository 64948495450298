import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { map, Observable } from 'rxjs';

export function validateOptions(options$?: Observable<{ value: string }[]>): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return options$.pipe(
      map((options) => {
        const matchingOption = options && options.find((option) => option.value === control.value);

        if (control.value && !matchingOption) {
          return { invalidOption: { value: control.value } };
        }

        return null;
      })
    );
  };
}

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);

@Injectable()
export class DatePickerFormatter extends NgbDateParserFormatter {
  // the order actually changes the outcome
  private formats = ['DDMMYYYY', 'DDMMYY', 'DD.MM.YYYY', 'DD.MM.YY', 'D.M.YY', 'YYYY-MM-DD', 'DD-MM-YYYY'];

  parse(value: string): NgbDateStruct | null {
    const date = dayjs(value, this.formats, true);
    if (value && date.isValid()) {
      return {
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
      };
    }

    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? dayjs()
          .set('year', date.year)
          .set('month', date.month - 1)
          .set('date', date.day)
          .format('DD.MM.YYYY')
      : '';
  }
}

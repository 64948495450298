import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TealiumUtagService } from './tealium/tealium-utag.service';
import { TrackingStore } from './store/tracking.store';
import { ActionAreaDirective } from './actions/action-area.directive';
import { ActionDirective } from './actions/action.directive';

export interface TrackingConfig {
  basePageName: string;
  basePageCategories: string[];
  productName: string;
  productGroup: string;
  productTarget: string;
  productLabel: string;
  sessionDomain: string;
}

export interface TealiumConfig {
  account: string;
  profile: string;
  environment: string;
}

export interface TrackingModuleConfig {
  service: Provider;
  trackingConfig: TrackingConfig;
  tealiumConfig: TealiumConfig;
  actionTracking: boolean;
}

@NgModule({
  declarations: [ActionAreaDirective, ActionDirective],
  imports: [CommonModule],
  providers: [TealiumUtagService],
  exports: [ActionAreaDirective, ActionDirective],
})
export class SharedUtilTrackingModule {
  /**
   * Creates the tracking module and provides the tracking service.
   * Expects a service implementation as well as a tracking config and tealium config.
   */
  public static forRoot(config: TrackingModuleConfig): ModuleWithProviders<SharedUtilTrackingModule> {
    return {
      ngModule: SharedUtilTrackingModule,
      providers: [
        TrackingStore,
        config.service,
        { provide: 'trackingConfig', useValue: config.trackingConfig },
        { provide: 'tealiumConfig', useValue: config.tealiumConfig },
        { provide: 'actionTracking', useValue: config.actionTracking },
      ],
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from './email.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NvErrorModule, NvInputModule } from '@nbg-digital/ui-dpl-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [EmailComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule,
    NvErrorModule,
    NvInputModule
  ],
  exports: [EmailComponent]
})
export class EmailModule { }

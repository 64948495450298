import { Component, Input } from '@angular/core';

export interface NavigationItem {
  route: string;
  id: string;
  icon: string;
  title: string;
}

@Component({
  selector: 'nv-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  @Input() items: NavigationItem[];
}

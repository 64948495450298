import { PaymentCycle } from './payment-cycle';

export interface PolicyInformation {
  tariff: string;
  premium: Premium;
  insuranceStart: string;
  insuranceEnd: string;
  inactivationDate: string;
  inactivationExecutionDate: string;
  terminationOption: TerminationOption;
  paymentCycle: PaymentCycle;
}

export enum TerminationOption {
  Revocation = 'REVOCATION',
  Termination = 'TERMINATION',
  None = 'NONE',
}

export interface Premium {
  net: number;
  gross: number;
}

export interface PolicyInformationRlv extends PolicyInformation {
  coverage: number;
}

export interface PolicyInformationDkr extends PolicyInformation {
  coverage: number;
}

export interface PolicyInformationEks extends PolicyInformation {
  monthlyBenefit: number;
  mobility: boolean;
  office: boolean;
  mind: boolean;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvDropdownComponent } from './dropdown.component';
import { NvDropdownItemComponent } from './dropdown-item.component';
import { NvFormInfoContainerModule } from '../form-info-container/form-info-container.module';

@NgModule({
  declarations: [NvDropdownComponent, NvDropdownItemComponent],
  imports: [CommonModule, NgbModule, NvFormInfoContainerModule],
  exports: [NvDropdownComponent, NvDropdownItemComponent],
})
export class NvDropdownModule {}

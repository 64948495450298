import { Component, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { NvModalComponent, NvModalContent } from './modal.component';

@Component({
  selector: 'nv-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class NvInfoModalComponent {
  @ContentChild(NvModalContent, { read: TemplateRef, static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazyContent: TemplateRef<any>;

  @ViewChild('modal', { static: true }) private modal: NvModalComponent;

  openDialog(event: Event) {
    this.modal.open();
    event.stopPropagation();
  }

  closeDialog(event: Event) {
    this.modal.close();
    event.stopPropagation();
  }
}

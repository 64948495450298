import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NvButtonModule, NvCardModule, NvErrorModule, NvFormModule, NvInputModule, NvModalModule } from '@nbg-digital/ui-dpl-components';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasswordForgotComponent } from './password-forgot.component';

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/password-forgot/', '.json');
}

const routes: Routes = [
  {
    path: '',
    component: PasswordForgotComponent,
  },
];
@NgModule({
  imports: [
    CommonModule,
    NvCardModule,
    NvButtonModule,
    NvErrorModule,
    NvInputModule,
    NvModalModule,
    NvFormModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
  ],
  exports: [],
  declarations: [PasswordForgotComponent],
  providers: [],
})
export class PasswordForgotModule extends ModuleWithTranslations {
  constructor(private translateService: TranslateService) {
    super(translateService);
  }
}

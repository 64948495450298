import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConsentAdminService {
  private readonly baseUrl = `${this.eweApiUrl}/ewe`;
  private apiUrlLead = `${this.eweApiUrl}/lead`;

  constructor(
    private http: HttpClient,
    @Inject('eweApiUrl') private eweApiUrl: string,
    @Inject('apiToken') private apiToken: string
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCsv(): Observable<any> {
    return this.http
      .get(this.baseUrl, {
        headers: {
          Authorization: this.apiToken,
          Accept: 'text/plain',
          'Content-Type': 'application/csv',
        },
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const fileName = res.headers.get('content-disposition')?.split('filename=')[1].trim();
          saveAs(res.body, fileName ? fileName : 'ewes.csv');
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLead(): Observable<any> {
    return this.http
      .get(this.apiUrlLead, {
        headers: {
          Authorization: this.apiToken,
          Accept: 'text/plain',
          'Content-Type': 'application/csv',
        },
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const fileName = res.headers.get('content-disposition')?.split('filename=')[1].trim();
          saveAs(res.body, fileName ? fileName : 'leads.csv');
        })
      );
  }
}

export type ProductStatus = 'view' | 'add' | 'conf' | '';
export type TealiumBoolean = 'true' | 'false' | '';
export type TealiumGender = 'Männlich' | 'Weiblich' | '';

export interface TealiumProductData {
  product_name: string[];
  product_category: string[];
  product_status?: ProductStatus[];
  product_quantity?: string[];
  product_lead?: TealiumBoolean;
  product_request?: TealiumBoolean;
  product_package?: TealiumBoolean;
  product_group?: string[];
  product_target?: string[];
  product_label?: string[];
  product_id?: string;
}

export function createTealiumProductData(
  productName: string,
  productCategory: string,
  productStatus: ProductStatus,
  productQuantity: number,
  productLead: boolean,
  productRequest: boolean,
  productPackage: boolean,
  productGroup: string,
  productTarget: string,
  productLabel: string,
  productId: string
): TealiumProductData {
  return {
    product_name: [productName || ''],
    product_category: [productCategory || ''],
    product_status: [productStatus || ''],
    product_quantity: [productQuantity?.toString() || ''],
    product_lead: productLead ? 'true' : '',
    product_request: productRequest ? 'true' : '',
    product_package: productPackage ? 'true' : '',
    product_group: [productGroup || ''],
    product_target: [productTarget || ''],
    product_label: [productLabel || ''],
    product_id: productId || '',
  };
}

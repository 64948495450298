import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordComponent } from './password.component';
import { NvInputModule } from '../input/input.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PasswordComponent],
  imports: [CommonModule, NvInputModule, TranslateModule],
  exports: [PasswordComponent],
})
export class PasswordModule {}

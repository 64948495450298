import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionManagementService } from '@nbg-digital/session-management/domain';

@Injectable()
export class StorageAgreementWithdrawalResolver implements Resolve<string> {
  constructor(private sessionService: SessionManagementService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.sessionService.deleteSessionData(route.paramMap.get('offerId'));
  }
}

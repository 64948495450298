import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UserService } from '@nbg-digital/user-management/domain';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { emailPattern } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-user-password-forgot',
  templateUrl: 'password-forgot.component.html',
})
export class PasswordForgotComponent {
  @ViewChild('modalLinkMailed', { static: true })
  private modalLinkMailed: NvModalComponent;

  passwordForgotForm: UntypedFormGroup;

  showInvalidCredentialsFeedback = false;

  constructor(
    private fBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    @Inject('loginPath') private loginPath: string
  ) {
    this.passwordForgotForm = this.fBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, emailPattern]],
    });
  }

  submit(event: Event) {
    event.preventDefault();
    this.passwordForgotForm.markAllAsTouched();
    if (this.passwordForgotForm.valid) {
      const { firstName, lastName, email } = this.passwordForgotForm.value;
      this.userService
        .setNewPasswordRequest(firstName, lastName, email)
        .pipe(
          catchError((err) => {
            if (err.status === 403) {
              this.showInvalidCredentialsFeedback = true;
            }
            return of();
          })
        )
        .subscribe(() => {
          this.modalLinkMailed.open();
        });
    }
  }

  submitModalLinkMailed(event: Event) {
    event.preventDefault();
    this.modalLinkMailed.close();
    this.router.navigate([this.loginPath]);
  }

  onModalLinkMailedClosed() {
    this.router.navigate([this.loginPath]);
  }
}

import { Inject, Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { ProductService } from '@nbg-digital/shared/util';
import { Product } from '@nbg-digital/user-management/domain';

@Injectable({
  providedIn: 'root',
})
export class ApplicationCompleteGuard implements CanActivate {
  constructor(
    private router: Router,
    private applicationFacade: ApplicationFacade,
    private riskAssessmentFacade: RiskAssessmentFacade,
    @Inject(ProductService) private productService: ProductService,
    @Inject('applicationPath')
    private applicationPath: string
  ) {}
  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.applicationFacade.isApplicationComplete(this.productService.product === Product.Rlv),
      this.riskAssessmentFacade.isRiskAssessmentComplete$,
    ]).pipe(
      map(
        ([isApplicationComplete, isRiskAssessmentComplete]) =>
          (isApplicationComplete && isRiskAssessmentComplete) || this.router.createUrlTree([`/${this.applicationPath}`])
      )
    );
  }
}

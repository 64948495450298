import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfigService, AppConfigServiceFactory } from './app.config.service';
import { AppConfig } from './app.config';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class AppConfigModule {
  static forRoot(config: AppConfig): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        { provide: AppConfig, useValue: config },
        { provide: AppConfigService, useFactory: AppConfigServiceFactory, deps: [AppConfig] },
      ],
    };
  }
}

import { AppConfig } from './app.config';

export class AppConfigService {
  constructor(private _config: AppConfig) {}

  get config(): AppConfig {
    return this._config;
  }
}

export const AppConfigServiceFactory = (config: AppConfig) => new AppConfigService(config);

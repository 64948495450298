import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UserService } from '@nbg-digital/user-management/domain';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginDialogService } from '../login-dialog/login-dialog.service';

@Component({
  selector: 'nv-user-login-teaser',
  templateUrl: 'login-teaser.component.html',
  styleUrls: ['./login-teaser.component.scss'],
})
export class LoginTeaserComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dismissClicked = new EventEmitter<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() loginClicked = new EventEmitter<any>();

  showTeaser$ = combineLatest([this.userService.isAuthenticated$, this.loginDialogService.teaserVisible$]).pipe(
    map(([isAuthenticated, teaserVisible]) => teaserVisible && !isAuthenticated)
  );

  constructor(
    private userService: UserService,
    private loginDialogService: LoginDialogService,
    @Inject('enableUserLogin') public enableUserLogin: boolean,
    @Inject('kupoUrl') public kupoUrl: string
  ) {}

  dismiss(event: MouseEvent) {
    this.loginDialogService.hideTeaser();
    this.dismissClicked.emit();
    event.preventDefault();
  }

  login(event: MouseEvent) {
    this.loginDialogService.showLoginDialog(false);
    this.loginClicked.emit();
    event.preventDefault();
  }
}

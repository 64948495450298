import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class RejectionInterceptor implements HttpInterceptor {
  constructor(private router: Router, @Inject('rejectionUrl') private rejectionPagePath: string) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.handleHttpError(error)));
  }

  private handleHttpError = (error: HttpErrorResponse): Observable<any> => {
    if (422 === error.status) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      from(this.router.navigate([`/${this.rejectionPagePath}`])).subscribe(() => {});
    }
    return throwError(error);
  };
}

import { TrackingConfig } from '@nbg-digital/shared/util-tracking';

export const RLV_PRODUCT_NAME = 'Lebensversicherung|Risikolebensversicherung|Evo-X';
export const RLV_PRODUCT_GROUP = 'Lebensversicherung';
export const RLV_PRODUCT_TARGET = 'Privatkunden';
export const RLV_PRODUCT_LABEL = 'Risikolebensversicherung';
export const RLV_BASE_PAGE_NAME = 'EvoX/Produkt/Lebensversicherung/Risikolebensversicherung';
export const RLV_SESSION_DOMAIN = 'Evo-X';
export const RLV_BASE_PAGE_CATEGORIES = [
  'EvoX',
  '-',
  'Produkte',
  'Lebensversicherung',
  'Risikolebensversicherung',
  'Antrag',
];

export const RLV_TRACKING_CONFIG: TrackingConfig = {
  basePageName: RLV_BASE_PAGE_NAME,
  basePageCategories: RLV_BASE_PAGE_CATEGORIES,
  productName: RLV_PRODUCT_NAME,
  productGroup: RLV_PRODUCT_GROUP,
  productTarget: RLV_PRODUCT_TARGET,
  productLabel: RLV_PRODUCT_LABEL,
  sessionDomain: RLV_SESSION_DOMAIN,
};

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translateList',
})
export class TranslateListPipe implements PipeTransform {
  constructor(private translate: TranslateService, private translateParser: TranslateParser) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  transform(value: string, params: { [key: string]: string }): any {
    return this.translate.get(value).pipe(map((translation) => this.interpolateTranslationList(translation, params)));
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private interpolateTranslationList(list: any[], params: { [key: string]: string }) {
    if (!list?.length || typeof list.map !== 'function') return null;
    return list.map((item) => ({
      ...item,
      TEXT: this.translateParser.interpolate(item.TEXT, params),
    }));
  }
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ActionType } from './action-type.enum';
import { TrackingStore } from '../store/tracking.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nvActionName]',
})
export class ActionDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() set nvActionName(val: string) {
    this._actionName = val;
  }
  @Input() nvActionType: ActionType = ActionType.Cta;
  @HostBinding('attr.data-wt') get dataWt(): string {
    return this.actionTracking && this._actionName
      ? `${this._actionArea}.${this.nvActionType}.${this._actionName.toLowerCase()}`
      : null;
  }

  /**
   * If true, the directive triggers a refresh of event listeners on all data-wt
   * to enable tracking of dynamically added elements.
   */
  @Input() dynamic = false;

  private _actionName: string;
  private _actionArea: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private element: ElementRef,
    @Optional() private store: TrackingStore,
    @Optional() @Inject('actionTracking') private actionTracking: boolean
  ) {}

  ngOnInit(): void {
    this.store
      ?.selectActionAreaByElement(this.element.nativeElement)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((area) => (this._actionArea = area));
  }

  ngAfterViewInit() {
    if (this.dynamic) {
      const updateActionTrackingListeners = window['clickTrackUpdate'];
      if (updateActionTrackingListeners instanceof Function) {
        updateActionTrackingListeners();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

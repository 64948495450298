import { TranslateService } from '@ngx-translate/core';

export class ModuleWithTranslations {
  constructor(translateService: TranslateService) {
    this.initTranslation(translateService);
  }
  /*
    translation service does not load translations for lazy loaded modules,
    except when you reset the language
   */
  initTranslation(translateService: TranslateService): void {
    const currentLang = translateService.defaultLang;
    translateService.currentLang = '';
    translateService.use(currentLang);
  }
}

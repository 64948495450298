import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationLoaderService } from '@nbg-digital/shared/util';
import { TrackingService } from '@nbg-digital/shared/util-tracking';
import { Subject } from 'rxjs';

@Component({
  selector: 'nv-rlv-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  @HostBinding('class.nv-evox-root') rootClass = true;
  public pageAnimationKey: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public navigationLoader: NavigationLoaderService,
    private tracking: TrackingService,
    @Inject('landingPageUrl') public landingPageUrl: string,
    @Inject('enableConstructionPage') public enableConstructionPage: boolean
  ) {
    this.tracking.init(this.unsubscribe$.asObservable());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { AppConfig } from '@nbg-digital/shared/util';

export const TEST_CONFIG_ALL_TRUE: AppConfig = {
  showHeaderWithFixedNavigationBar: true,
  showRating: true,
  showBayerischeProgressBar: true,
  progressNavigationAssetsPath: 'path',
  showAcquisitionAgentCheckboxes: true,
  enableEwe: true,
  enableReentry: true,
  showLoginButtonInFooter: true,
};

export const TEST_CONFIG_ALL_FALSE: AppConfig = {
  showHeaderWithFixedNavigationBar: false,
  showRating: false,
  showBayerischeProgressBar: false,
  progressNavigationAssetsPath: 'path',
  showAcquisitionAgentCheckboxes: false,
  enableEwe: false,
  enableReentry: false,
  showLoginButtonInFooter: false,
};

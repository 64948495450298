import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OfferService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {}

  generateOfferId(): Observable<string> {
    return this.http
      .post<{ offerId: string }>(`${this.apiUrl}/offerId`, null)
      .pipe(map((response) => response.offerId));
  }
}

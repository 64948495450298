export interface TealiumUserData {
  user_id: string;
  user_age?: string;
  user_gender?: string;
  user_occupation?: string;
  user_graduation_level?: string;
  user_officework?: string;
}

export function createTealiumUserData(
  userId: string,
  userAge: string,
  userGender: string,
  userOccupation: string,
  userEducationLevel: string,
  userOfficeShare: string
): TealiumUserData {
  return {
    user_id: userId || '',
    user_age: userAge || '',
    user_gender: userGender || '',
    user_occupation: userOccupation || '',
    user_graduation_level: userEducationLevel || '',
    user_officework: userOfficeShare || '',
  };
}

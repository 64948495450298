import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as IBAN from 'iban';

export function isIbanValid(control: AbstractControl): ValidationErrors | null {
  const valueWithoutSpaces = control.value.split(' ').join('');
  const electronicFormat = IBAN.electronicFormat(control.value);
  if (electronicFormat !== valueWithoutSpaces || (!IBAN.isValid(control.value) && control.value != '')) {
    return { invalidIban: { value: control.value } };
  }
  return null;
}

import { Component, Inject, OnDestroy, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { PricingFacade } from '@nbg-digital/pricing/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import {
  AppConfigService,
  getDeepestActivatedRoute,
  NavigationLoaderService,
  NvAnimations,
  ProductService,
} from '@nbg-digital/shared/util';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { CustomerInformationFacade } from '@nbg-digital/customer-service/api';
import {
  TEST_APPLICATION_STATES,
  TEST_PRICING_STATES,
  TEST_REGISTRATION_STATES,
} from '@nbg-digital/shared/util-testing';

interface NavigationItem {
  translationKey: string;
  link: string;
  exact?: boolean;
  navigatable: Observable<boolean>;
}

@Component({
  selector: 'nv-insurance-application-wizard',
  templateUrl: './insurance-application-wizard.component.html',
  styleUrls: ['./insurance-application-wizard.component.scss'],
  animations: [NvAnimations],
})
export class InsuranceApplicationWizardComponent implements OnDestroy {
  @ViewChild('logoutSuccessModal', { static: true })
  private logoutSuccessModal: NvModalComponent;
  private unsubscribe$ = new Subject<void>();

  navigationItems: NavigationItem[];
  showFooter$: Observable<boolean>;
  currentPrice$: Observable<number>;
  currentGrossPrice$: Observable<number>;
  hideRating$: Observable<boolean>;
  selectedModuleName$: Observable<string>;
  pageAnimationKey$: Observable<string>;
  riskAssessmentComplete$: Observable<boolean>;
  paymentCycle$: Observable<string>;

  pathUrl = `${this.baseUrl}${this.appConfigService.config.progressNavigationAssetsPath}`;
  showIcon: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public navigationLoader: NavigationLoaderService,
    @Optional() private pricingFacade: PricingFacade,
    @Optional() private riskAssessmentFacade: RiskAssessmentFacade,
    @Optional() private applicationFacade: ApplicationFacade,
    private userManagementFacade: UserManagementFacade,
    private customerInformationFacade: CustomerInformationFacade,
    public appConfigService: AppConfigService,
    @Inject(PricingFacade) private productService: ProductService,
    @Inject('landingPageUrl') public landingPageUrl: string,
    @Inject('pricingPath') private pricingPath: string,
    @Inject('navigationPricingPath') private navigationPricingPath: string,
    @Inject('navigationPricingResultPath') private navigationPricingResultPath: string,
    @Inject('riskAssessmentPath') private riskAssessmentPath: string,
    @Inject('applicationPath') private applicationPath: string,
    @Inject('summaryPath') private summaryPath: string,
    @Inject('enableUserLogin') public enableUserLogin: boolean,
    @Inject('enableChat') public enableChat: boolean,
    @Inject('baseUrl') public baseUrl: string,
    @Inject('enableMocks') public enableMocks: boolean,
    @Optional() @Inject('logo1Url') public logo1Url: string
  ) {
    this.showProgressBarIcons();
    this.initializeUserData();
    this.retrieveRouteConfiguration();
    this.initializePrices();
    this.initializeReentryRedirect();

    this.riskAssessmentComplete$ = this.riskAssessmentFacade.isRiskAssessmentComplete$;

    this.selectedModuleName$ = this.pricingFacade.pricing$.pipe(map((params) => params.tariff));
    this.paymentCycle$ = this.pricingFacade.paymentCycle$;

    this.initializeNavItems();
  }

  loadMockData() {
    sessionStorage.setItem('pricing', JSON.stringify(TEST_PRICING_STATES[0]));
    this.pricingFacade.restorePricingState();
    sessionStorage.setItem('application', JSON.stringify(TEST_APPLICATION_STATES[0]));
    this.applicationFacade.restoreApplicationState();
    sessionStorage.setItem('registration', JSON.stringify(TEST_REGISTRATION_STATES[0]));
    this.userManagementFacade.restoreRegistrationState();
  }

  redirectToApplication() {
    this.riskAssessmentFacade?.isRiskAssessmentComplete$.pipe(first()).subscribe((riskAssessmentComplete) => {
      if (riskAssessmentComplete) {
        this.router.navigate([this.applicationPath]);
      }
    });
  }

  private showProgressBarIcons() {
    this.showIcon = this.appConfigService.config.showBayerischeProgressBar;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeNavItems() {
    // navigation to pricing is always allowed
    const pricingAllowed$ = of(true);

    // navigation to module overview is allowed if all pricing parameters have been submitted
    const pricingResultAllowed$ = this.pricingFacade?.canNavigateToResultPage();

    // navigation to risk assessment is allowed if pricing is completed
    const riskAssessmentAllowed$ = this.pricingFacade?.isPricingComplete$;

    // navigation to application is allowed if risk assessment is completed
    const applicationAllowed$ = this.riskAssessmentFacade?.isRiskAssessmentComplete$;

    // navigation to application summary is allowed if both risk assessment and application are completed
    const summaryAllowed$ = combineLatest([
      this.riskAssessmentFacade?.isRiskAssessmentComplete$,
      this.applicationFacade?.isApplicationComplete(this.productService.product === 'rlv'),
    ]).pipe(map(([riskAssessmentComplete, applicationComplete]) => riskAssessmentComplete && applicationComplete));

    this.navigationItems = [
      {
        translationKey: 'APP.NAVIGATION.YOUR_NEED',
        link: `/${this.pricingPath}/${this.navigationPricingPath}`,
        exact: false,
        navigatable: pricingAllowed$,
      },
      {
        translationKey: 'APP.NAVIGATION.YOUR_OFFER',
        link: `/${this.pricingPath}/${this.navigationPricingResultPath}`,
        exact: true,
        navigatable: pricingResultAllowed$,
      },
      {
        translationKey: 'APP.NAVIGATION.HEALTH_QUESTIONS',
        link: `/${this.riskAssessmentPath}`,
        exact: false,
        navigatable: riskAssessmentAllowed$,
      },
      {
        translationKey: 'APP.NAVIGATION.APPLICATION',
        link: `/${this.applicationPath}`,
        exact: false,
        navigatable: applicationAllowed$,
      },
      {
        translationKey: 'APP.NAVIGATION.FINISH',
        link: `/${this.summaryPath}`,
        exact: false,
        navigatable: summaryAllowed$,
      },
      // TODO: fix/workaround for breaking NG0100, can be removed if angular fixes current behaviour
    ].map((item) => ({ ...item, navigatable: item.navigatable?.pipe(debounceTime(50)) }));
  }

  private initializeUserData() {
    // on logout redirect to start of (insurance) application
    this.userManagementFacade.logOut$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.logoutSuccessModal.open();
      this.redirectToApplication();
    });

    //subscribe to isAuthenticated to cover people who log in and those who are already logged in
    this.userManagementFacade.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => this.enableUserLogin && isAuthenticated),
        switchMap(() => this.userManagementFacade.getUser())
      )
      .subscribe((userData) => {
        this.userManagementFacade.setUser(userData);
        this.router.navigate(['antrag/nutzer-daten']);
      });
  }

  private initializePrices() {
    this.currentPrice$ = combineLatest([
      this.riskAssessmentFacade.riskAssessmentResult$.pipe(map((result) => result.adjustedPrice)),
      this.pricingFacade.pricing$.pipe(map((params) => params.price)),
    ]).pipe(map(([adjustedPrice, price]) => adjustedPrice || price));

    this.currentGrossPrice$ = combineLatest([
      this.riskAssessmentFacade.riskAssessmentResult$.pipe(map((result) => result.adjustedGrossPrice)),
      this.pricingFacade.pricing$.pipe(map((params) => params.grossPrice)),
    ]).pipe(map(([adjustedGrossPrice, grossPrice]) => adjustedGrossPrice || grossPrice));
  }

  private retrieveRouteConfiguration() {
    const currentRoute$ = this.router.events.pipe(
      startWith(new NavigationEnd(null, null, null)),
      filter((e) => e instanceof NavigationEnd),
      map(() => getDeepestActivatedRoute(this.route.snapshot))
    );

    this.hideRating$ = currentRoute$.pipe(map((currentRoute) => currentRoute?.data.hideRatings));
    this.showFooter$ = currentRoute$.pipe(map((currentRoute) => currentRoute?.data.footer));
    this.pageAnimationKey$ = currentRoute$.pipe(map((currentRoute) => currentRoute?.routeConfig?.path));
  }

  private initializeReentryRedirect() {
    const queryParamMap = this.route.snapshot.queryParamMap;

    const reentry = queryParamMap.get('reentry');
    if (reentry) {
      this.router.navigate([`wiedereinstieg/${reentry}`]);
    }

    const deleteReentry = queryParamMap.get('deleteReentry');
    if (deleteReentry) {
      this.router.navigate([`wiedereinstieg/widerruf/${deleteReentry}`]);
    }
  }
}

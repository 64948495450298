import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  NvButtonModule,
  NvCardModule,
  NvCheckboxModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
  NvPasswordStrengthModule,
  NvPinCodeModule,
  NvPricingFooterModule,
  NvWizardModule,
  PasswordModule,
} from '@nbg-digital/ui-dpl-components';
import { RegistrationComponent } from './registration.component';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import { VerificationModule } from '../component/verification/verification.module';
import { NotAuthenticatedGuard } from './not-authenticated.guard';
import { EmailModule } from '../email/email.module';
import { ApplicationCompleteGuard } from './application-complete.guard';

function createTranslateLoader(http: HttpClient, baseUrl: string) {
  return new TranslateHttpLoader(http, `${baseUrl}/assets/i18n/registration/`, '.json');
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NvCardModule,
    NvErrorModule,
    NvInputModule,
    NvFormModule,
    NvButtonModule,
    NvModalModule,
    NvPinCodeModule,
    RouterModule.forChild([
      {
        path: '',
        component: RegistrationComponent,
        canActivate: [NotAuthenticatedGuard, ApplicationCompleteGuard],
        data: {
          footer: true,
          tracking: {
            page: {
              type: 'Registrierung',
              name: 'Antrag/Daten/Registrierung',
              category: 'Registrierung',
            },
            product: {
              name: true,
              group: true,
              target: true,
              status: 'add',
              label: true,
              quantity: 0,
              lead: true,
              id: true,
              category: true,
            },
          },
        },
      },
    ]),
    NvPasswordStrengthModule,
    TranslateModule,
    NvPricingFooterModule,
    NvWizardModule,
    SharedUtilTrackingModule,
    VerificationModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, 'baseUrl'],
      },
      isolate: false,
      extend: true,
    }),
    NvCheckboxModule,
    EmailModule,
    PasswordModule,
    NvLinkModule,
  ],
  declarations: [RegistrationComponent],
})
export class RegistrationModule extends ModuleWithTranslations {
  constructor(public translateService: TranslateService) {
    super(translateService);
  }
}

export interface TealiumPageNameData {
  // Seitenname
  page_name: string;
}

export interface TealiumPageCategoryData {
  // Interface für Seitenkategorien
  // Element des Seitennames - Seitenbereich
  contentgroup_cg1: string;
  // Element des Seitennames - Menue
  contentgroup_cg2: string;
  // Angabe der Produktgruppe
  contentgroup_cg3: string;
  // Angabe des Produkts (sofern Produktseite) oder 3. Ebene des Seitennamens
  contentgroup_cg4: string;
  // grobe Angabe des Seitentyps
  contentgroup_cg5: string;
  // feine Angabe des Seitentyps (nur für Seiten der Strecke relevant
  contentgroup_cg6: string;
  contentgroup_cg7: string;
}

export type TealiumPageData = TealiumPageNameData & TealiumPageCategoryData;

export function createTealiumPageData(
  baseName: string,
  pageName: string,
  baseCategories: string[] = [],
  pageCategory: string
): TealiumPageData {
  return {
    page_name: `${baseName}/${pageName || ''}`,
    contentgroup_cg1: baseCategories[0] || '',
    contentgroup_cg2: baseCategories[1] || '',
    contentgroup_cg3: baseCategories[2] || '',
    contentgroup_cg4: baseCategories[3] || '',
    contentgroup_cg5: baseCategories[4] || '',
    contentgroup_cg6: baseCategories[5] || '',
    contentgroup_cg7: pageCategory || '',
  };
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RiskAssessmentStore,
  RiskAssessmentResult,
  RiskAssessmentState,
  Underwriting,
} from '@nbg-digital/risk-assessment/domain';

@Injectable({ providedIn: 'root' })
export class RiskAssessmentFacade {
  public reentryData$: Observable<RiskAssessmentState> = this.store.select((state) => state);

  constructor(private store: RiskAssessmentStore) {}

  public riskAssessmentResult$: Observable<RiskAssessmentResult> = this.store.select((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { caseId, ...result } = state;
    return result;
  });

  public underwriting$: Observable<Underwriting[]> = this.store.select((state) => state.underwriting);

  /**
   * Whether the risk assessment process has been completed by the user.
   */
  public isRiskAssessmentComplete$: Observable<boolean> = this.store.isComplete$;

  public resetState(): void {
    this.store.reset();
  }

  public setReEntryState(state: RiskAssessmentState, reentryValid: boolean) {
    this.store.reset();
    if (reentryValid) {
      this.store.update(state);
    }
  }
}

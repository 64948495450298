// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: '###BASE_URL###',
  apiUrl: '###APIURL###',
  userApiUrl: '###KUPOAPIURL###',
  eweApiUrl: '###EWEAPIURL###',
  kupoUrl: '###KUPO_URL###',
  landingPageUrl: '###LANDINGPAGEURL###',
  skipMagnumGo: JSON.parse('###SKIPMAGNUMGO###'),
  e2e: JSON.parse('###E2EMGOSKIP###'),
  tealiumProfile: '###TEALIUMPROFILE###',
  tealiumAccount: '###TEALIUMACCOUNT###',
  tealiumEnvironment: '###TEALIUMENVIRONMENT###',
  enableTealiumLogging: JSON.parse('###ENABLETEALIUMLOGGING###'),
  pathAdviceConnectId: '###PATHADVICECONNECTID###',
  enableUserLogin: JSON.parse('###ENABLEUSERLOGIN###'),
  enableChat: JSON.parse('###ENABLECHAT###'),
  mainBroker: '###MAINBROKER###',
  magnumGoUrl: '###MAGNUMGOURL###',
  magnumGoTenant: '###MAGNUMGOTENANT###',
  magnumGoLanguage: '###MAGNUMGOLANGUAGE###',
  privacyStatementUrl: '###PRIVACYSTATEMENTURL###',
  enableConstructionPage: JSON.parse('###ENABLECONSTRUCTIONPAGE###'),
  enableMocks: JSON.parse('###ENABLEMOCKS###'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, Input } from '@angular/core';

@Component({
  selector: 'nv-construction-page',
  templateUrl: './construction-page.component.html',
  styleUrls: ['./construction-page.component.css'],
})
export class NvConstructionPageComponent {
  @Input() showHeader = true;
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */

const radioControlValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NvRadioGroupDirective),
  multi: true,
};

@Directive({
  selector: '[nvRadioGroup]',
  providers: [radioControlValueAccessor],
})
export class NvRadioGroupDirective implements ControlValueAccessor {
  private radios: Set<NvRadioButtonComponent> = new Set<NvRadioButtonComponent>();

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.updateRadioValues(value);
    }
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _value: any = null;
  private _disabled = false;
  private _required = false;

  // ControlValueAccessor implementation

  writeValue(value: any) {
    this._value = value;
    this.updateRadioValues(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  onChange = (fn: void) => {
    return;
  };
  onTouched = (fn: void) => {
    return;
  };

  onRadioChange(value: any) {
    this.writeValue(value);
    this.onChange(value);
  }

  onRadioValueUpdate() {
    this.updateRadioValues(this._value);
  }

  registerRadio(radio: NvRadioButtonComponent) {
    this.radios.add(radio);
  }

  unregisterRadio(radio: NvRadioButtonComponent) {
    this.radios.delete(radio);
  }

  private updateRadioValues(value: any) {
    this.radios.forEach((radio) => {
      radio.checked = radio.value === value;
    });
  }
}

let uid = 0;

@Component({
  selector: 'nv-radio-button',
  styleUrls: ['./radio-button.component.scss'],
  templateUrl: './radio-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvRadioButtonComponent implements OnDestroy {
  @Input() id = `nv-radio-${++uid}`;
  @Input() name: string | null = null;
  @Input() appearance: 'radio' | 'switch' | 'tile' | 'inline-tile' | 'shadow-tile' = 'radio';

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    const newVal = coerceBooleanProperty(value);
    if (this._checked !== newVal) {
      this._checked = newVal;
      this.cdr.markForCheck();
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup) {
        this.radioGroup.onRadioValueUpdate();
      }
    }
  }

  @Input()
  get required() {
    return this._required || (this.radioGroup && this.radioGroup.required);
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get disabled() {
    return this._disabled || (this.radioGroup && this.radioGroup.disabled);
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _checked = false;
  private _value: any = null;
  private _required: boolean;
  private _disabled: boolean;

  constructor(@Optional() private radioGroup: NvRadioGroupDirective, private cdr: ChangeDetectorRef) {
    this.radioGroup.registerRadio(this);
  }

  ngOnDestroy() {
    this.radioGroup.unregisterRadio(this);
  }

  onChange() {
    this.radioGroup.onRadioChange(this._value);
  }
}

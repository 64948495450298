import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule, NvCardModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

import { LogoutAutomaticMessageComponent } from './logout-automatic-message.component';

@NgModule({
  imports: [CommonModule, NvButtonModule, NvCardModule, TranslateModule],
  exports: [LogoutAutomaticMessageComponent],
  declarations: [LogoutAutomaticMessageComponent],
})
export class LogoutAutomaticMessageModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateListPipe } from './translate-list.pipe';

@NgModule({
  declarations: [TranslateListPipe],
  imports: [CommonModule],
  exports: [TranslateListPipe],
})
export class TranslateUtilsModule {}

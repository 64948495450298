import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import Fuse from 'fuse.js';

let uid = 0;

export interface AutoCompleteOption {
  value: string;
  text: string;
}

const autoCompleteValueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NvAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'nv-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [autoCompleteValueAccessor],
  encapsulation: ViewEncapsulation.None,
})
export class NvAutoCompleteComponent implements OnInit, ControlValueAccessor {
  @ViewChild(NgbTypeahead, { static: true }) typeahead: NgbTypeahead;

  @Input() id = `nv-auto-complete-${++uid}`;
  @Input() minInput = 2;
  @Input() maxResults = 10;
  @Input() placeholder?: string = null;
  @Input() readOnly?: boolean;
  @Input() options: AutoCompleteOption[];

  search: (searchTerm$: Observable<string>) => Observable<string[]>;
  formatter: (string) => string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fuse: Fuse<any>;
  private fuseOptions = {
    keys: ['text'],
    // we want to ignore slash and brackets for the score, as they appear quite often and the user should not be forced to type them
    getFn: (entry: AutoCompleteOption) => entry.text.replace('/', '').replace('(', '').replace(')', ''),
  };

  ngOnInit() {
    this.formatter = (item: string) => this.options?.find((option) => option.value === item)?.text || null;
    this.typeahead.popupId = `${this.id}`;
    this.fuse = new Fuse(this.options, this.fuseOptions);
    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((term) => this.setExactMatch(term)),
        filter((term) => term.length >= this.minInput),
        map((term) => this.searchEntry(term))
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  private _onChange = (_: unknown) => {};

  writeValue(value): void {
    this.typeahead.writeValue(this.typeahead.inputFormatter ? value : this.formatter(value));
  }

  registerOnChange(fn): void {
    this._onChange = fn;
    this.typeahead.registerOnChange(fn);
  }

  registerOnTouched(fn): void {
    this.typeahead.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.typeahead.setDisabledState(isDisabled);
  }

  private setExactMatch(term: string) {
    const match = this.options.find((entry) => entry.text.toLowerCase() === term.toLowerCase());
    if (match) {
      this._onChange(match.value);
    }
  }

  private searchEntry(term: string) {
    return this.fuse
      .search(term)
      .slice(0, this.maxResults)
      .map((entry) => entry.item.value);
  }
}

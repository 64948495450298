export class ProductService {
  constructor(private productName: string) {}

  get product(): string {
    return this.productName;
  }

  set product(productName: string) {
    this.productName = productName;
  }
}

export const ProductServiceFactory = (productName: string) => new ProductService(productName);

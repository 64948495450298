import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvHeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { NvAgentModalModule } from '../agent-modal/agent-modal.module';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { NvContainerModule } from '../container/container.module';

@NgModule({
  declarations: [NvHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedUtilTrackingModule,
    NvAgentModalModule,
    NvContainerModule,
  ],
  exports: [NvHeaderComponent],
})
export class NvHeaderModule {}

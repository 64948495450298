export function focusFirstInvalidField(element: HTMLElement) {
  setTimeout(() => {
    const firstInvalidField = element.querySelector(
      '[formControlName].ng-invalid, [formControl].ng-invalid'
    ) as HTMLElement;

    if (firstInvalidField) {
      focusElement(firstInvalidField);
      // Sometimes ng-invalid is set on the parent instead of the input (e.g. nv-checkbox).
      // To focus the correct element we need to check if this is a nested input
      const invalidInput = firstInvalidField.querySelector('input');
      invalidInput?.focus({ preventScroll: true });
    }
  }, 0);
}

export function focusElement(element: HTMLElement) {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.focus({ preventScroll: true });
  }
}

export function focusElementStart(element: HTMLElement) {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    element.focus({ preventScroll: true });
  }
}

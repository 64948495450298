import { Component, Input } from '@angular/core';

@Component({
  selector: 'nv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class NvFooterComponent {
  @Input() imprintHref = '';
  @Input() privacyHref = '';
  @Input() termsOfUseHref = '';
  @Input() initialContactDetailsHref = '';
}

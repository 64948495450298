import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { NvPopoverComponent, NvPopoverContent } from './popover.component';
import { NvCardModule } from '../card/card.module';

@NgModule({
  declarations: [NvPopoverComponent, NvPopoverContent],
  imports: [CommonModule, NvCardModule, NgbPopoverModule],
  exports: [NvPopoverComponent, NvPopoverContent],
})
export class NvPopoverModule {}

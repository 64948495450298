import { Component, Input } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';

@Component({
  selector: 'nv-password',
  templateUrl: './password.component.html',
})
export class PasswordComponent {
  @Input() id = '';
  @Input() placeholder = '';

  visible = false;

  constructor(private defaultValueAccessor: DefaultValueAccessor) {}

  onTouched() {
    this.defaultValueAccessor.onTouched();
  }

  togglePassword() {
    this.visible = !this.visible;
  }
}

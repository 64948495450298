import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvCardModule } from '../card/card.module';
import { NvImageCardComponent } from './image-card.component';

@NgModule({
  declarations: [NvImageCardComponent],
  imports: [CommonModule, NvCardModule],
  exports: [NvImageCardComponent],
})
export class NvImageCardModule {}

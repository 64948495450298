import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { ProductService } from '@nbg-digital/shared/util';
import { Product } from '@nbg-digital/session-management/domain';

@Component({
  selector: 'nv-session-reentry',
  templateUrl: './reentry.component.html',
})
export class ReentryComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('reentryNotificationModal', { static: true })
  private reentryNotificationModal: NvModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationFacade: ApplicationFacade,
    @Inject(ProductService) private productService: ProductService,
    @Inject('registrationPath') private registrationPath: string,
    @Inject('errorPagePath') private errorPagePath: string
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(
        map((data) => data.reentryData),
        withLatestFrom(this.applicationFacade.isApplicationComplete(this.productService.product === Product.Rlv)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ([data, isApplicationComplete]: [{ reentryData: { canReEnter: boolean; reentryValid: boolean } }, boolean]) => {
          if (!data.reentryData.canReEnter) {
            this.router.navigateByUrl(`/${this.errorPagePath}`);
          } else if (!data.reentryData.reentryValid) {
            this.router.navigateByUrl('tarifierung/bedarf/person');
            this.reentryNotificationModal.open();
          } else {
            isApplicationComplete
              ? this.router.navigateByUrl(this.registrationPath)
              : this.router.navigateByUrl('tarifierung/wiedereinstieg');
          }
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

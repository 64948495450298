import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutButtonComponent } from './logout-button.component';
import { NvButtonModule } from '@nbg-digital/ui-dpl-components';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NvButtonModule
  ],
  declarations: [LogoutButtonComponent],
  exports: [LogoutButtonComponent]
})
export class LogOutButtonModule {}

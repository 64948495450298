import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  NvButtonModule,
  NvCardModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
  NvPasswordStrengthModule,
  PasswordModule,
} from '@nbg-digital/ui-dpl-components';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginDetailsComponent } from './login-details/login-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginDataComponent } from './login-data.component';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { VerificationModule } from '../verification/verification.module';
import { EmailModule } from '../../email/email.module';

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/login-details/', '.json');
}

@NgModule({
  declarations: [LoginDataComponent, LoginDetailsComponent, ChangePasswordComponent, ChangeUsernameComponent],
  imports: [
    CommonModule,
    NvCardModule,
    NvButtonModule,
    ReactiveFormsModule,
    NvPasswordStrengthModule,
    NvInputModule,
    NvModalModule,
    NvFormModule,
    NvErrorModule,
    NvLinkModule,
    PasswordModule,
    VerificationModule,
    EmailModule,
    RouterModule.forChild([
      {
        path: '',
        component: LoginDataComponent,
        children: [
          {
            path: '',
            component: LoginDetailsComponent,
          },
          {
            path: 'passwort-aendern',
            component: ChangePasswordComponent,
          },
          {
            path: 'benutzername-aendern',
            component: ChangeUsernameComponent,
          },
        ],
      },
    ]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
    PasswordModule,
  ],
})
export class LoginDataModule extends ModuleWithTranslations {
  constructor(translateService: TranslateService) {
    super(translateService);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvEditButtonComponent } from './edit-button.component';

@NgModule({
  declarations: [NvEditButtonComponent],
  imports: [CommonModule, NgbModule],
  exports: [NvEditButtonComponent],
})
export class NvEditButtonModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvWizardPageNavigationComponent } from './wizard-page-navigation.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NvButtonModule } from '../button/button.module';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';

@NgModule({
  declarations: [NvWizardPageNavigationComponent],
  imports: [CommonModule, RouterModule, TranslateModule, NvButtonModule, SharedUtilTrackingModule],
  exports: [NvWizardPageNavigationComponent],
})
export class NvWizardModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NvButtonModule,
  NvCardModule, NvFormModule,
  NvInputModule,
  NvModalModule,
  NvPinCodeModule,
} from '@nbg-digital/ui-dpl-components';
import { VerificationComponent } from './verification.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VerificationComponent],
  exports: [VerificationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NvButtonModule,
    NvInputModule,
    NvPinCodeModule,
    NvCardModule,
    NvFormModule,
    NvModalModule,
    TranslateModule,
  ],
})
export class VerificationModule {}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessagesResponse } from '../models/messages-response';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Attachment } from '../models/attachment';

@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}

  getMessages(): Observable<MessagesResponse> {
    return this.http.get<MessagesResponse>(`${this.apiUrl}/postbox/messages`);
  }

  getNewestMessages(messageCount: number): Observable<MessagesResponse> {
    return this.http.get<MessagesResponse>(`${this.apiUrl}/postbox/messages?messageCount=${messageCount}`);
  }

  setReadMessage(messageId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/postbox/message/${messageId}/read`, { messageId });
  }

  getAttachment(attachment: Attachment) {
    return this.http
      .get(`${this.apiUrl}/documents?documentId=${attachment.id}&documentIndex=${attachment.index}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          saveAs(res.body, attachment.filename || 'attachment.pdf');
        })
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvConstructionPageComponent } from './construction-page.component';
import { NvContainerModule } from '../container/container.module';
import { NvHeaderModule } from '../header/header.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NvConstructionPageComponent],
  imports: [CommonModule, NvContainerModule, TranslateModule, NvHeaderModule],
  exports: [NvConstructionPageComponent],
})
export class NvConstructionPageModule {}

import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActionArea } from './action-area.enum';
import { TrackingStore } from '../store/tracking.store';

export const ACTION_AREA_ATTRIBUTE = 'nv-action-area';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `[${ACTION_AREA_ATTRIBUTE}]`,
})
export class ActionAreaDirective implements OnInit, OnDestroy {
  @Input(ACTION_AREA_ATTRIBUTE) actionArea: ActionArea;

  constructor(@Optional() private store: TrackingStore) {}

  ngOnInit() {
    this.store?.registerArea(this.actionArea, `[${ACTION_AREA_ATTRIBUTE}=${this.actionArea}]`);
  }

  ngOnDestroy() {
    this.store?.deregisterArea(this.actionArea);
  }
}

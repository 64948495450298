import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class LoadingChunkFailedErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      throw error;
    }
  }
}

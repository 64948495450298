import { Component } from '@angular/core';
import { UserService, UserStore } from '@nbg-digital/user-management/domain';

@Component({
  selector: 'nv-user-login-data',
  templateUrl: './login-data.component.html',
})
export class LoginDataComponent {
  constructor(private userService: UserService, private userStore: UserStore) {
    this.userService.getUser().subscribe((response) => {
      this.userStore.update({
        username: response.username,
        firstname: response.firstName,
        lastname: response.firstName,
        userId: response.userId,
      });
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvDatePickerComponent } from './date-picker.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerAdapter } from '../date-picker/date-picker-adapter';
import { DatePickerFormatter } from './date-picker-formatter';
import { NvInputModule } from '../input/input.module';
import { FormsModule } from '@angular/forms';
import { NvButtonModule } from '../button/button.module';

@NgModule({
  declarations: [NvDatePickerComponent],
  imports: [CommonModule, NgbModule, NvButtonModule, NvInputModule, FormsModule],
  exports: [NvDatePickerComponent],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: DatePickerAdapter,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: DatePickerFormatter,
    },
  ],
})
export class NvDatePickerModule {}

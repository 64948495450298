import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BrokerParameters, Product, SessionManagementService } from '@nbg-digital/session-management/domain';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrokerEntryResolver implements Resolve<{ brokerParameters: BrokerParameters; brokerId: string }> {
  constructor(private sessionManagementService: SessionManagementService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<{ brokerParameters: BrokerParameters; brokerId: string }> {
    const brokerId = route.paramMap.get('brokerId');
    const product = route.parent.data.product as Product;
    return this.sessionManagementService.getBrokerParameters(product, brokerId).pipe(
      map((brokerParameters) => ({ brokerParameters, brokerId })),
      catchError(() => of({ brokerParameters: null, brokerId }))
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  NvCardModule,
  NvButtonModule,
  NvErrorModule,
  NvInputModule,
  NvFormModule,
  NvLinkModule,
} from '@nbg-digital/ui-dpl-components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NvCardModule,
    NvButtonModule,
    NvErrorModule,
    NvInputModule,
    NvFormModule,
    NvLinkModule,
  ],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';

export function minAge(age: number, format?: string): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isGreater = false;
    let isFuture = false;
    if (age !== null && control.value && dayjs(control.value, format || 'YYYY-MM-DD', true).isValid()) {
      isGreater = dayjs().diff(dayjs(control.value, format || 'YYYY-MM-DD'), 'years') >= age;
      isFuture = dayjs().isBefore(dayjs(control.value, format || 'YYYY-MM-DD'));

      return isGreater && !isFuture ? null : { invalidMinAge: { value: control.value } };
    }
    return null;
  };
}

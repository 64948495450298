import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvDecisionModalComponent } from './decision-modal.component';
import { NvModalModule } from '../modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { NvButtonModule } from '../button/button.module';

@NgModule({
  declarations: [NvDecisionModalComponent],
  imports: [CommonModule, NvButtonModule, NvModalModule, TranslateModule],
  exports: [NvDecisionModalComponent],
})
export class NvDecisionModalModule {}

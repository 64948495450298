import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConsentData, CustomerConsent, CustomerEweResponse, DistributorInfo, RatingData } from '../models';
import { InterceptorSkipHeader } from '@nbg-digital/shared/util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private readonly eweBaseUrl = `${this.eweApiUrl}/ewe`;
  private readonly distributorBaseUrl = `${this.eweApiUrl}/distributor`;
  private apiUrlRating = `${this.eweApiUrl}/rating`;
  private apiUrlConfirm = `${this.eweBaseUrl}/confirm`;

  private apiUrlWithdrawal = `${this.eweBaseUrl}/unsubscribe`;
  private readonly customerEweBaseUrl = `${this.eweApiUrl}/customerEwe`;
  private apiUrlCustomerConfirm = `${this.customerEweBaseUrl}/confirm`;
  private apiUrlCustomerWithdrawal = `${this.customerEweBaseUrl}/unsubscribe`;

  constructor(private http: HttpClient, @Inject('eweApiUrl') private eweApiUrl: string) {}

  createConsent(consentData: ConsentData) {
    return this.http.post(this.eweBaseUrl, consentData, {
      headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
    });
  }

  confirmConsent(uuid: string) {
    return this.http.put(`${this.apiUrlConfirm}/${uuid}`, {});
  }

  withdrawConsent(uuid: string) {
    return this.http.put(`${this.apiUrlWithdrawal}/${uuid}`, {});
  }

  createCustomerConsent(customerConsent: CustomerConsent, userId: string) {
    return this.http.post(`${this.customerEweBaseUrl}/${userId}`, { ...customerConsent });
  }

  createDistributorInfo(distributorInfo: DistributorInfo) {
    return this.http.post(`${this.distributorBaseUrl}`, distributorInfo, {
      headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
    });
  }

  getCustomerConsent(userId: string): Observable<CustomerEweResponse> {
    return this.http.get<CustomerEweResponse>(`${this.customerEweBaseUrl}/${userId}`);
  }

  confirmCustomerConsent(uuid: string) {
    return this.http.put(`${this.apiUrlCustomerConfirm}/${uuid}`, {});
  }

  withdrawCustomerConsent(uuid: string) {
    return this.http.put(`${this.apiUrlCustomerWithdrawal}/${uuid}`, {});
  }

  createRating(ratingData: RatingData) {
    return this.http.post(`${this.apiUrlRating}`, ratingData, {
      headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
    });
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function hasCorrectLength(): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (isValidPhoneNumber(control.value, 'DE') !== true) {
        return { invalidNumber: { value: control.value } };
      }
    }
    return null;
  };
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { ApplicationFacade } from '@nbg-digital/application/api';
import { RegistrationStore, UserService } from '@nbg-digital/user-management/domain';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { WizardPageComponent } from '@nbg-digital/ui-dpl-components';
import { allowedSpecialCharacters, isEqualPassword, validatePasswordStrength } from '@nbg-digital/shared/util';
import { VerificationComponent } from '../component/verification/verification.component';
import { combineLatest } from 'rxjs';
import { EmailComponent } from '../email/email.component';
import { LoginDialogService } from '../component/login-dialog/login-dialog.service';

@Component({
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends WizardPageComponent implements OnInit {
  registrationForm: UntypedFormGroup;
  initialEmail: string;

  public passwordStrength;
  public specialChars = allowedSpecialCharacters;

  @ViewChild('verificationComponent', { static: true }) private verificationComponent: VerificationComponent;
  @ViewChild(EmailComponent) emailComponent: EmailComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private applicationFacade: ApplicationFacade,
    private registrationStore: RegistrationStore,
    private userService: UserService,
    private pricingFacade: PricingFacade,
    private loginDialogService: LoginDialogService,
    route: ActivatedRoute,
    element: ElementRef,
    @Inject('baseUrl') public baseUrl: string
  ) {
    super(route, element);

    this.registrationForm = this.formBuilder.group(
      {
        email: null,
        password: ['', [Validators.required, Validators.minLength(8), validatePasswordStrength]],
        passwordConfirmation: ['', [Validators.required]],
        trustedShops: [false],
      },
      {
        validators: [isEqualPassword()],
      }
    );
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.registrationForm.setControl(name, form);
  }

  handleMailUniqueness(unavailable: boolean) {
    this.loginDialogService.shakeLoginButton(unavailable);
  }

  onPassed($event) {
    this.passwordStrength = $event;
  }

  onVerificationSuccessful() {
    this.registrationStore.update({ isPinCodeVerified: true });
  }

  onSendPinCode() {
    this.registrationStore.update({ isInitialPinCodeSent: true });
  }

  saveAndVerifyEmail() {
    combineLatest([
      this.registrationStore.select((state) => {
        const { firstName, lastName, email } = state;
        return { firstName, lastName, email };
      }),
      this.pricingFacade.offerId$,
    ])
      .pipe(first())
      .subscribe(([user, offerId]) => {
        this.verificationComponent.open(
          {
            offerId,
            userData: { firstname: user.firstName, lastname: user.lastName, email: user.email },
          },
          this.shouldUserGetPinMail(user.email)
        );
      });
  }

  protected shouldUserGetPinMail(email: string) {
    return !this.registrationStore.state.isInitialPinCodeSent && this.registrationStore.state.email === email;
  }

  protected isFormValid() {
    this.registrationForm.markAllAsTouched();
    return this.registrationForm.valid;
  }

  protected fillInitialDataFromStore() {
    combineLatest([this.applicationFacade.user$, this.registrationStore.state$])
      .pipe(first())
      .subscribe(([applicationUser, registrationState]) => {
        this.initialEmail = applicationUser.email;

        if (registrationState.password) {
          this.registrationForm.patchValue({
            password: registrationState.password,
            passwordConfirmation: registrationState.password,
          });
        }

        if (!registrationState.firstName) {
          this.registrationStore.update({
            firstName: applicationUser.firstName,
            lastName: applicationUser.lastName,
          });
        }
      });

    this.applicationFacade.trustedShops$.subscribe((trustedShopChecked) => {
      this.registrationForm.patchValue({ trustedShops: trustedShopChecked });
    });
  }

  protected beforeNext() {
    if (this.emailComponent.value !== null && this.emailComponent.value !== this.registrationStore.state.email) {
      this.registrationStore.update({ isInitialPinCodeSent: false });
    }

    if (
      this.registrationStore.state.isPinCodeVerified &&
      this.emailComponent.value !== this.registrationStore.state.email
    ) {
      this.registrationStore.update({ email: this.emailComponent.value, isPinCodeVerified: false });
    }
    this.registrationStore.update({
      password: this.registrationForm.value.password,
      email: this.emailComponent.value,
    });
    this.applicationFacade.setEmailAddress(this.emailComponent.value);
    this.applicationFacade.setTrustedShops(this.registrationForm.value.trustedShops);

    if (!this.registrationStore.state.isPinCodeVerified) {
      this.saveAndVerifyEmail();

      // let WizardPage know when email verification is finished so it can then navigate to the next page
      return this.verificationComponent.successfulVerification.asObservable();
    }
  }
}

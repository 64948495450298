import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { Channel, MainBroker, Product, SessionManagementService } from '@nbg-digital/session-management/domain';
import { AppConfigService, emailPattern, ProductService, ReentryUrlService } from '@nbg-digital/shared/util';
import { combineLatest, of, Subject, timer } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { ConsentManagementFacade } from '@nbg-digital/consent-management/api';

@Component({
  selector: 'nv-session-storage-agreement',
  templateUrl: './storage-agreement.component.html',
  styleUrls: ['./storage-agreement.component.scss'],
})
export class StorageAgreementComponent implements OnDestroy {
  agreementForm: UntypedFormGroup;
  showForm = true;
  private unsubscribe$ = new Subject<void>();
  MainBroker = MainBroker;

  constructor(
    @Inject('privacyStatementUrl') public privacyStatementUrl: string,
    @Inject('imprintUrl') public imprintUrl: string,
    private fb: UntypedFormBuilder,
    private sessionService: SessionManagementService,
    private productService: ProductService,
    private reentryUrlService: ReentryUrlService,
    @Inject('channel') private channel: string,
    private pricingFacade: PricingFacade,
    private consentManagementFacade: ConsentManagementFacade,
    public appConfigService: AppConfigService,
    @Optional() private riskAssessmentFacade: RiskAssessmentFacade,
    @Optional() private applicationFacade: ApplicationFacade,
    @Optional() private userManagementFacade: UserManagementFacade,
    @Inject('mainBroker') public mainBroker: MainBroker
  ) {
    this.agreementForm = this.fb.group({
      email: ['', { validators: [Validators.required, emailPattern] }],
      acceptance: false,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetShowModal() {
    timer(150).subscribe(() => (this.showForm = true));
  }

  onSubmit(event: Event) {
    event.preventDefault();

    combineLatest([
      this.pricingFacade.offerId$,
      this.pricingFacade.reentryData$,
      this.riskAssessmentFacade?.reentryData$,
      this.applicationFacade?.reentryData$,
      this.userManagementFacade?.reentryData$,
      this.consentManagementFacade.reentryData$,
    ])
      .pipe(
        first(),
        switchMap(([offerId, pricing, riskAssessment, application, userManagement, consent]) => {
          const data = { pricing, riskAssessment, application, userManagement, consent };
          const email = this.agreementForm.value.email;
          const product = this.productService.product as Product;
          let channel = this.channel as Channel;
          const isUserLoggedIn = this.userManagementFacade.isAuthenticated;

          // overwrite 'online' channel when already set from offer-agent
          this.applicationFacade?.salesInformation$.subscribe((value) => {
            if (value?.channel) {
              channel = value.channel;
            }
          });

          this.showForm = false;

          return combineLatest([
            this.agreementForm.value.acceptance
              ? this.consentManagementFacade.createConsent({
                  email,
                  phoneNumber: '',
                })
              : of(null),
            this.sessionService.saveSessionData({
              offerId,
              email,
              product,
              data,
              channel,
              isUserLoggedIn,
              mainBroker: this.mainBroker,
              reentryUrl: this.reentryUrlService.baseUrl,
            }),
          ]);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.agreementForm.get('acceptance').reset());
  }
}

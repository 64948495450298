export function getGender(salutation: string): string {
  switch (salutation) {
    case 'MR':
      return 'Männlich';

    case 'MRS':
      return 'Weiblich';

    default:
      return '';
  }
}

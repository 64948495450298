export enum ApiErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  PASSWORD_POLICY = 'PASSWORD_POLICY',
  INVALID_INPUT_DATA = 'INVALID_INPUT_DATA',
  NO_OFFER_ID = 'NO_OFFER_ID',
  ILLFORMED_DATE = 'ILLFORMED_DATE',
  WRONG_TYPE = 'WRONG_TYPE',
  MISSING_ELEMENT = 'MISSING_ELEMENT',
  UNKNOWN_CHOICE = 'UNKNOWN_CHOICE',
  UNRECOGNIZED_COUNTRY = 'UNRECOGNIZED_COUNTRY',
  ILLFORMED_IBAN = 'ILLFORMED_IBAN',
  BENEFIT_NOT_OK = 'BENEFIT_NOT_OK',
  END_AGE_NOT_OK = 'END_AGE_NOT_OK',
  UW_NOT_OK = 'UW_NOT_OK',
  SPORT_NOT_OK = 'SPORT_NOT_OK',
  CHECKS_NOT_OK = 'CHECKS_NOT_OK',
  BANK_DATA_NOT_OK = 'BANK_DATA_NOT_OK',
  NO_EMAIL = 'NO_EMAIL',
  NO_JOB = 'NO_JOB',
  BMI_NOT_OK = 'BMI_NOT_OK',
  PARTNER_NOT_OK = 'PARTNER_NOT_OK',
  CANNOT_PRODUCE_OFFER = 'CANNOT_PRODUCE_OFFER',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_LOCKED = 'USER_LOCKED',
  FORBIDDEN = 'FORBIDDEN',
  USER_CREDENTIALS_WRONG = 'USER_CREDENTIALS_WRONG',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
  UUID_EXPIRED = 'UUID_EXPIRED',
}

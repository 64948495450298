import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ChatService } from '@nbg-digital/shared/util';

interface WindowWithPathAdvice extends Window {
  pathadvice: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connect: () => any;
  };
}

@Component({
  selector: 'nv-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  encapsulation: ViewEncapsulation.None, // TODO:  remove ViewEncapsulation.none,
  providers: [
    {
      provide: Window,
      useFactory: () => window,
    },
  ],
})
export class NvChatButtonComponent {
  constructor(chatService: ChatService, @Inject(Window) private window: WindowWithPathAdvice) {
    chatService.init();
  }

  public pathAdviceConnect() {
    this.window.pathadvice.connect();
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { PricingAdjustment, PricingResponse } from '../models';
import { PricingStore } from '../store/pricing.store';
import { map, switchMap, take } from 'rxjs/operators';
import { InterceptorSkipHeader } from '@nbg-digital/shared/util';

@Injectable({ providedIn: 'root' })
export class PricingService {
  ageThresholdForNotice = 46;
  maxCoverageForAge46AndUpWithoutNotice = 300000;
  maxCoverageForAge45AndBelowWithoutNotice = 500000;

  constructor(private httpClient: HttpClient, private store: PricingStore, @Inject('apiUrl') private apiUrl: string) {}

  getMaxCoverageWithoutNotice(selectedBirthDate?: string): Observable<number> {
    return this.store.ageAtThisTime(selectedBirthDate).pipe(
      map((age) => {
        if (age >= this.ageThresholdForNotice) {
          return this.maxCoverageForAge46AndUpWithoutNotice;
        } else {
          return this.maxCoverageForAge45AndBelowWithoutNotice;
        }
      })
    );
  }

  isCoverageDirectlyAcceptable(): Observable<boolean> {
    return combineLatest([this.getMaxCoverageWithoutNotice(), this.store.state$]).pipe(
      map(([maxCoverageWithoutNotice, store]) => {
        return store.coverage <= maxCoverageWithoutNotice;
      })
    );
  }

  getPremiumTariffs(): Observable<PricingResponse> {
    return this.store.selectPricingParameters().pipe(
      take(1),
      switchMap((pricingParameters) =>
        this.httpClient.post<PricingResponse>(`${this.apiUrl}/premium`, pricingParameters, {
          headers: {
            [InterceptorSkipHeader]: '',
          },
        })
      )
    );
  }

  getAdjustedPrice(adjustment: PricingAdjustment, coverage: number): Observable<PricingResponse> {
    return this.store.selectPricingParameters().pipe(
      take(1),
      switchMap((pricingParameters) =>
        this.httpClient.post<PricingResponse>(`${this.apiUrl}/premium`, {
          ...pricingParameters,
          adjustment,
          coverage: coverage || pricingParameters.coverage,
          headers: {
            [InterceptorSkipHeader]: '',
          },
        })
      )
    );
  }
}

/* eslint-disable no-useless-escape */
const passwordMatchCases = {
  upperCase: /[A-Z]/,
  lowerCase: /[a-z]/,
  digits: /[0-9]/,
  minLength: /.{8}/,
  specialCharacters: /[!"§$%&\/()=?+*#'\[\]\-_.:,;{}\\~<>|°^]/,
};

export function calcPasswordStrength(password: string, matchCases: { [key: string]: RegExp } = passwordMatchCases) {
  if (typeof password !== 'string' || !matchCases['minLength'].test(password)) {
    return 0;
  }

  let strength = Object.values(matchCases)
    .slice(0, 3)
    .reduce((acc, match) => (match.test(password) ? ++acc : acc), 0);

  if (strength === 3 && matchCases['specialCharacters'].test(password)) {
    strength++;
  }

  return strength;
}

export const TEST_RISK_ASSESSMENT_STATES = [
  {
    caseId: '',
    token: '',
    decisionStatus: 'ACCEPT',
    adjustment: undefined,
    maxSum: undefined,
    adjustedPrice: 10,
    adjustedGrossPrice: 12,
    underwriting: [],
    externalExistingContract: false,
  },
  {
    caseId: '123',
    token: '',
    decisionStatus: 'ACCEPT',
    adjustment: undefined,
    maxSum: undefined,
    adjustedPrice: 10,
    adjustedGrossPrice: 12,
    underwriting: [],
    externalExistingContract: false,
  },
];

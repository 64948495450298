import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ReactiveFormsModule } from '@angular/forms';
import { StorageAgreementWithdrawalComponent } from './storage-agreement-withdrawal.component';
import { RouterModule } from '@angular/router';
import { StorageAgreementWithdrawalResolver } from './storage-agreement-withdrawal.resolver';
import { TrackingAwareRoute } from '@nbg-digital/shared/util-tracking';
import { NvButtonModule } from '@nbg-digital/ui-dpl-components';


const routes: TrackingAwareRoute[] = [
  {
    path: '',
    component: StorageAgreementWithdrawalComponent,
    resolve: { status: StorageAgreementWithdrawalResolver },
  },
];

@NgModule({
  declarations: [StorageAgreementWithdrawalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NvButtonModule,
    RouterModule.forChild(routes),
  ],
  providers: [StorageAgreementWithdrawalResolver],
})
export class StorageAgreementWithdrawalModule {
}

import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UserService } from '@nbg-digital/user-management/domain';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'nv-user-login-teaser-success',
  templateUrl: 'login-teaser-success.component.html',
  styleUrls: ['./login-teaser-success.component.scss'],
})
export class LoginTeaserSuccessComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dismissClicked = new EventEmitter<any>();
  visible$ = new Subject<boolean>();

  constructor(private userService: UserService, @Inject('enableUserLogin') enableUserLogin: boolean) {
    if (enableUserLogin) {
      this.userService.isAuthenticated$
        .pipe(distinctUntilChanged())
        .subscribe((loggedIn) => this.visible$.next(loggedIn));
    }
  }

  dismiss() {
    this.visible$.next(false);
    this.dismissClicked.emit();
  }
}

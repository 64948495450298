import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvInputDirective } from './input.directive';

@NgModule({
  declarations: [NvInputDirective],
  imports: [CommonModule],
  exports: [NvInputDirective],
})
export class NvInputModule {}

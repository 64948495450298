import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvProgressNavigationComponent, NvProgressNavigationItemComponent } from './progress-navigation';
import { RouterModule } from '@angular/router';
import { NvContainerModule } from '../container/container.module';

@NgModule({
  declarations: [NvProgressNavigationComponent, NvProgressNavigationItemComponent],
  imports: [CommonModule, RouterModule, NvContainerModule],
  exports: [NvProgressNavigationComponent, NvProgressNavigationItemComponent],
})
export class NvProgressNavigationModule {}

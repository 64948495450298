import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { getDeepestActivatedRoute } from '@nbg-digital/shared/util';
import { TealiumConfig, TrackingConfig } from './shared-util-tracking.module';
import { createTealiumPageData } from './tealium/data/tealium-page-data';
import { createTealiumPageParameters } from './tealium/data/tealium-page-parameters';
import { TrackingAwareRoute } from './tracking-aware-route';
import { getCurrentTrackingBreakpoint } from './utils';
import { TealiumUtagService } from './tealium/tealium-utag.service';
import { createTealiumOrderData } from './tealium/data/tealium-order-data';
import { createTealiumProductData } from './tealium/data/tealium-product-data';
import { createTealiumSessionData } from './tealium/data/tealium-session-data';
import { createTealiumUserData } from './tealium/data/tealium-user-data';
import { combineLatest, Observable } from 'rxjs';
import { ClickId, TrackingStore } from './store/tracking.store';
import { createTealiumBrokerData } from './tealium/data/tealium-broker-data';

interface StateDependentTrackingParameters {
  processId: string;
  productCategory: string;
  productId: string;
  orderValue: number;
  orderDetails: string;
  orderId: string;
  userId: string;
  userAge: string;
  userGender: string;
  userOccupation: string;
  userGraduationLevel: string;
  userOfficeWork: string;
  subBroker: string;
}

export abstract class TrackingService {
  protected abstract processId$: Observable<string>;
  protected abstract productCategory$: Observable<string>;
  protected abstract productId$: Observable<string>;
  protected abstract orderValue$: Observable<number>; // monthly_net_premium * 12
  protected abstract orderDetails$: Observable<string>;
  protected abstract orderId$: Observable<string>;
  protected abstract userId$: Observable<string>;
  protected abstract userAge$: Observable<string>;
  protected abstract userGender$: Observable<string>;
  protected abstract userOccupation$: Observable<string>;
  protected abstract userGraduationLevel$: Observable<string>;
  protected abstract userOfficeWork$: Observable<string>;
  protected abstract subBroker$: Observable<string>;
  private clickId$: Observable<ClickId>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private tealium: TealiumUtagService,
    private config: TrackingConfig,
    private tealiumConfig: TealiumConfig,
    private store: TrackingStore
  ) {
    this.clickId$ = this.store.select((state) => state.clickId);
  }

  /**
   * Initializes tracking by setting the provided tealium config and subscribing to navigation events.
   * To be called once per app, e.g. in app.component.
   */
  init(unsubscribe$: Observable<void>) {
    this.tealium.setConfig(this.tealiumConfig);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        first(),
        map(() => this.route.snapshot)
      )
      .subscribe((snapshot) => {
        const keys = ['gclid', 'msclkid', 'fbclid'];
        const existingKey = keys.find((key) => snapshot.queryParamMap.has(key));
        if (existingKey) {
          const value = snapshot.queryParamMap.get(existingKey);
          this.store.setClickId({ key: existingKey, value: value });
        }
      });

    const stateDependentParameters: Observable<StateDependentTrackingParameters> = this.getStateDependentParams();
    this.router.events
      .pipe(
        takeUntil(unsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        map(() => getDeepestActivatedRoute(this.route.snapshot)?.routeConfig as TrackingAwareRoute),
        withLatestFrom(stateDependentParameters, this.clickId$)
      )
      .subscribe(([route, stateDependentParams, clickId]) => this.trackPageView(route, stateDependentParams, clickId));
  }

  /**
   * Overrides the initially set tracking config.
   */
  overrideTrackingConfig(config: TrackingConfig) {
    this.config = config;
  }

  /**
   * Expects the route config of the current route to assemble the page view data and call tealium.
   */
  private trackPageView(
    route: TrackingAwareRoute,
    stateDependentParams: StateDependentTrackingParameters,
    clickId: ClickId
  ) {
    const { basePageName, basePageCategories, productName, productGroup, productLabel, productTarget, sessionDomain } =
      this.config;
    const { page, order, product, broker } = route.data?.tracking || {};
    const {
      processId,
      productCategory,
      productId,
      orderValue,
      orderDetails,
      orderId,
      userId,
      userAge,
      userGender,
      userOccupation,
      userGraduationLevel,
      userOfficeWork,
      subBroker,
    } = stateDependentParams;

    this.tealium.view({
      ...createTealiumPageData(basePageName, page?.name, basePageCategories, page?.category),
      ...createTealiumPageParameters(getCurrentTrackingBreakpoint(this.breakpointObserver), page?.type),
      ...createTealiumOrderData(order?.type, order?.type && orderValue, order?.type && orderId, orderDetails),
      ...createTealiumProductData(
        product?.name && productName,
        product?.category && productCategory,
        product?.status,
        product?.quantity,
        product?.lead,
        product?.request,
        product?.package,
        product?.group && productGroup,
        product?.target && productTarget,
        product?.label && productLabel,
        product?.id && productId
      ),
      ...createTealiumUserData(userId, userAge, userGender, userOccupation, userGraduationLevel, userOfficeWork),
      ...createTealiumSessionData(processId, clickId, sessionDomain),
      ...createTealiumBrokerData(broker?.conversion && subBroker, subBroker),
    });
  }

  private getStateDependentParams(): Observable<StateDependentTrackingParameters> {
    return combineLatest([
      this.processId$,
      this.productCategory$,
      this.productId$,
      this.orderValue$,
      this.orderDetails$,
      this.orderId$,
      this.userId$,
      this.userAge$,
      this.userGender$,
      this.userOccupation$,
      this.userGraduationLevel$,
      this.userOfficeWork$,
      this.subBroker$,
    ]).pipe(
      map(
        ([
          processId,
          productCategory,
          productId,
          orderValue,
          orderDetails,
          orderId,
          userId,
          userAge,
          userGender,
          userOccupation,
          userGraduationLevel,
          userOfficeWork,
          subBroker,
        ]) => ({
          processId: processId as string,
          productCategory: productCategory as string,
          productId: productId as string,
          orderValue: orderValue as number,
          orderDetails: orderDetails as string,
          orderId: orderId as string,
          userId: userId as string,
          userAge: userAge as string,
          userGender: userGender as string,
          userOccupation: userOccupation as string,
          userGraduationLevel: userGraduationLevel as string,
          userOfficeWork: userOfficeWork as string,
          subBroker: subBroker as string,
        })
      )
    );
  }
}

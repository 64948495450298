import { Directive, HostBinding, Input } from '@angular/core';

type InputType = 'text' | 'number' | 'date' | 'password';
let uid = 0;

@Directive({
  selector: 'input[nvInput], textarea[nvInput]',
})
export class NvInputDirective {
  @HostBinding('class') staticClass = 'form-control form-input';
  @HostBinding('type') @Input() type: InputType = 'text';
  @HostBinding('attr.id') @Input() id = `nv-input-${++uid}`;
}

import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'nv-error',
  templateUrl: './error.component.html',
})
export class NvErrorComponent {
  @HostBinding('class.invalid-feedback')
  @Input()
  id: string;
}

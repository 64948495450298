import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule, NvCardModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

import { LoginTeaserSuccessComponent } from './login-teaser-success.component';

@NgModule({
  imports: [CommonModule, NvButtonModule, NvCardModule, TranslateModule],
  exports: [LoginTeaserSuccessComponent],
  declarations: [LoginTeaserSuccessComponent],
})
export class LoginTeaserSuccessModule {}

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ModuleWithTranslations } from '@nbg-digital/shared/util';
import {
  NvButtonModule,
  NvCardModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvModalModule,
  NvPasswordStrengthModule, PasswordModule,
} from '@nbg-digital/ui-dpl-components';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasswordChangedModule } from '../password-changed/password-changed.module';
import { PasswordRecoveryComponent } from './password-recovery.component';

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/password-recovery/', '.json');
}

const routes: Routes = [
  {
    path: '',
    component: PasswordRecoveryComponent,
  },
];
@NgModule({
  imports: [
    CommonModule,
    NvCardModule,
    NvButtonModule,
    NvFormModule,
    NvErrorModule,
    NvInputModule,
    NvModalModule,
    NvPasswordStrengthModule,
    PasswordChangedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      extend: true,
    }),
    PasswordModule,
  ],
  exports: [],
  declarations: [PasswordRecoveryComponent],
  providers: [],
})
export class PasswordRecoveryModule extends ModuleWithTranslations {
  constructor(private translateService: TranslateService) {
    super(translateService);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule, NvModalModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

import { PasswordChangedComponent } from './password-changed.component';

@NgModule({
  imports: [CommonModule, NvModalModule, NvButtonModule, TranslateModule],
  exports: [PasswordChangedComponent],
  declarations: [PasswordChangedComponent],
  providers: [],
})
export class PasswordChangedModule {}

import { Component, Input } from '@angular/core';

export type LinkType = 'default' | 'download' | 'breadcrumb';
@Component({
  selector: 'nv-link:not([link])',
  templateUrl: './link.component.html',
})
export class NvLinkComponent {
  @Input() href = '#';
  @Input() type: LinkType = 'default';
  @Input() targetBlank = true;
}

@Component({
  selector: 'nv-link[link]',
  templateUrl: './router-link.component.html',
})
export class NvRouterLinkComponent {
  @Input() link = '';
  @Input() type: LinkType = 'default';
}

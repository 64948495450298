import { Injectable } from '@angular/core';

import {
  CreateUserResponse,
  LoginResponse,
  RegistrationState,
  RegistrationStore,
  UserDataResponse,
  UserService,
  UserStore,
} from '@nbg-digital/user-management/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementFacade {
  public logOut$ = this.userService.logOut$.asObservable();
  public automaticLogout$ = this.userService.automaticLogOut$.asObservable();
  public isRegistrationComplete$ = this.registrationStore.registrationComplete$;

  public reentryData$ = this.registrationStore.select((state) => ({
    ...state,
    password: null,
  }));

  public isAuthenticated$ = this.userService.isAuthenticated$;

  constructor(
    private userService: UserService,
    private registrationStore: RegistrationStore,
    private userStore: UserStore
  ) {}

  restoreRegistrationState() {
    this.registrationStore.syncFromStorage();
  }

  public get isAuthenticated() {
    return this.userService.isAuthenticated;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkEmailAvailability(email: string): Observable<any> {
    return this.userService.checkEmailAvailability(email);
  }

  public createUser(): Observable<CreateUserResponse> {
    if (this.userService.isUserCompleteForCreate()) {
      return this.userService.createUser();
    }
  }

  public signIn(): Observable<LoginResponse> {
    return this.userService.signIn();
  }

  public signOut(): Observable<void> {
    return this.userService.signOut();
  }

  public getUser(): Observable<UserDataResponse> {
    return this.userService.getUser();
  }

  public setUser(userData: UserDataResponse) {
    this.userStore.update({
      username: userData.username,
      userId: userData.userId,
      firstname: userData.firstName,
      lastname: userData.lastName,
    });
  }

  public getUserId(): Observable<string> {
    return this.userStore.selectUserId();
  }

  public isPinCodeVerified(): boolean {
    return this.registrationStore.state.isPinCodeVerified;
  }

  public resetPinCodeValidation(): void {
    this.registrationStore.update({ isInitialPinCodeSent: false });
    this.registrationStore.update({ isPinCodeVerified: false });
  }

  public getRefreshToken(): Observable<string> {
    return this.userStore.getRefreshToken();
  }

  public resetRegistrationState(): void {
    this.registrationStore.reset();
  }

  public setReEntryState(state: RegistrationState) {
    this.registrationStore.reset();
    this.registrationStore.update(state);
  }

  getUsername() {
    return this.userStore.selectUsername();
  }
}

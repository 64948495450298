import { Injectable } from '@angular/core';
import {
  BrokerParameters,
  EducationLevel,
  OfficeShare,
  PricingAdjustment,
  PricingService,
  PricingState,
  PricingStore,
  Product,
  Salutation,
  SmokerType,
} from '@nbg-digital/pricing/domain';
import { ProductService } from '@nbg-digital/shared/util';
import { catchError, EMPTY, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PricingFacade {
  public pricing$: Observable<PricingState> = this.pricingStore.selectPricingState();
  public offerId$: Observable<string> = this.pricingStore.select((state) => state.offerId);
  public acquisitionAgent$: Observable<string> = this.pricingStore.select((state) => state.acquisitionAgent);
  public selectedBirthdate$: Observable<string> = this.pricingStore.select((state) => state.birthDate);
  public subBroker$: Observable<string> = this.pricingStore.select((state) => state.subBroker);
  public paymentCycle$: Observable<string> = this.pricingStore.select((state) => state.paymentCycle);
  public price$: Observable<number> = this.pricingStore.select((state) => state.price);
  public isCoverageDirectlyAcceptable$: Observable<boolean> = this.pricingService.isCoverageDirectlyAcceptable();
  /**
   * Whether or not the pricing process has been completed by the user.
   */
  isPricingComplete$ = this.pricingStore.isComplete$;
  /**
   * Whether or not all pricing parameters are present.
   */

  public reentryData$: Observable<PricingState> = this.pricingStore.select((state) => state);

  public pricingSummary$ = this.pricingStore.selectPricingSummary();
  public insureeSummary$ = this.pricingStore.selectInsureeSummary();

  constructor(
    protected pricingStore: PricingStore,
    protected pricingService: PricingService,
    private productService: ProductService,
    private router: Router
  ) {}

  cachePricingState() {
    this.pricingStore.syncToStorage();
  }

  restorePricingState() {
    this.pricingStore.syncFromStorage();
  }

  canNavigateToResultPage(): Observable<boolean> {
    return this.pricing$.pipe(
      map((state) => {
        const { birthDate, profession, salutation, smoker } = state;
        let requiredParams: Partial<PricingState> = {};

        if ((this.productService.product as Product) === Product.Rlv) {
          requiredParams = { birthDate, profession, salutation, smoker };
        } else if ((this.productService.product as Product) === Product.Dkr) {
          requiredParams = { birthDate, salutation, smoker };
        }
        return Object.values(requiredParams).every((param) => param !== null);
      })
    );
  }

  /**
   * Returns the price for the currently set pricing parameters and selected module considering adjustments
   * and/or lower than desired maximum coverage.
   */
  getAdjustedPrice(
    adjustment: PricingAdjustment = null,
    maxSum: number = null
  ): Observable<{ adjustedPrice: number; adjustedGrossPrice: number }> {
    return this.pricingService.getAdjustedPrice(adjustment, maxSum).pipe(
      catchError(() => {
        this.router.navigate(['/error']);
        return EMPTY;
      }),
      // lookup the new price with the selected tariff
      withLatestFrom(this.pricingStore.select((state) => state.tariff)),
      map(([pricing, selectedModule]) => {
        const tariff = pricing.tariffs.find((m) => m.name === selectedModule);
        return { adjustedPrice: tariff.premium.net, adjustedGrossPrice: tariff.premium.gross };
      })
    );
  }

  resetState(): void {
    this.pricingStore.reset();
  }

  setReEntryState(state: PricingState, reentryValid: boolean, adjustedStart: string) {
    this.pricingStore.reset();
    this.pricingStore.update(state);

    this.pricingStore.updateStartDate(adjustedStart);

    if (!reentryValid) {
      this.pricingStore.resetContractData();
    }
  }

  setBrokerEntryState(brokerParameters: BrokerParameters) {
    this.pricingStore.update({
      ...brokerParameters,
      profession: brokerParameters.profession
        ? {
            jobName: brokerParameters.profession,
            officeQuota: brokerParameters.officeQuota,
            graduation: brokerParameters.graduation,
          }
        : null,
    });
  }

  setBirthDate(birthDate: string) {
    this.pricingStore.update({ birthDate });
  }

  setSalutation(salutation: Salutation) {
    this.pricingStore.update({ salutation });
  }

  setAcquisitionAgent(acquisitionAgent: string) {
    this.pricingStore.update({ acquisitionAgent });
  }

  setPricingParameters(
    offerId: string,
    birthDate: string,
    coverage: number,
    duration: number,
    jobName: string,
    officeQuota: string,
    graduation: string,
    smoker: string,
    salutation: string,
    tariff: string
  ) {
    this.pricingStore.update({
      offerId,
      birthDate,
      coverage,
      duration,
      profession: {
        jobName,
        officeQuota: officeQuota as OfficeShare,
        graduation: graduation as EducationLevel,
      },
      smoker: smoker as SmokerType,
      salutation: salutation as Salutation,
      tariff,
    });
  }

  setOfferId(offerId: string) {
    this.pricingStore.update({ offerId });
  }
}

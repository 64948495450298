import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'nv-info-text-container',
  templateUrl: './info-text-container.component.html',
})
export class NvInfoTextContainerComponent {
  @Input() text: string;
  @Input() inline = true;

  @HostBinding('class') get hostClasses(): string {
    return `pos-relative align-top d-${this.inline ? 'inline-' : ''}block`;
  }
}

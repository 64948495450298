import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvWizardHeaderComponent } from './wizard-header.component';
import { RouterModule } from '@angular/router';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';
import { TranslateModule } from '@ngx-translate/core';
import {
  NvAgentModalModule,
  NvButtonModule,
  NvChatButtonModule,
  NvHeaderModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { LoginButtonModule, LogOutButtonModule } from '@nbg-digital/user-management/feature';

@NgModule({
  declarations: [NvWizardHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedUtilTrackingModule,
    NvAgentModalModule,
    NvButtonModule,
    NvModalModule,
    NvHeaderModule,
    NvChatButtonModule,
    LoginButtonModule,
    LogOutButtonModule,
  ],
  exports: [NvWizardHeaderComponent],
})
export class NvWizardHeaderModule {}

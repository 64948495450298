import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContractResponse } from '../models/contract-response';
import { Product } from '../models/product';
import { Contract } from '../models/contract';
import { ContractsResponse } from '../models/contracts-response';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}
  private contractUrl = `${this.apiUrl}/contracts`;

  getContracts(): Observable<ContractsResponse> {
    return this.http.get<ContractsResponse>(this.contractUrl);
  }

  getContractDetails(product: Product, policyNumber: string): Observable<Contract> {
    return this.http.get<ContractResponse>(`${this.contractUrl}/contract/${product}?policyNumber=${policyNumber}`).pipe(
      map((response) => ({
        ...response,
        personalInformation: {
          policyHolder: response.policyHolder,
          insuredPerson: response.insuredPerson,
          beneficiary: response.contractDetails.beneficiary,
        },
        policyInformation: {
          ...response.policyInformation,
          ...response.contractDetails,
          beneficiary: undefined,
        },
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContractDocuments(policyNumber: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/documents/contracts/${policyNumber}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const fileName = res.headers.get('content-disposition')?.split('filename=')[1].trim().replace(/['"]+/g, '');
          saveAs(res.body, fileName || 'Versicherungsschein.pdf');
        })
      );
  }
}

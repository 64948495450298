import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BankAccountInfo, BankInfo, ValidateAddressData, ValidateAddressDataResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient, @Inject('apiUrl') private apiUrl: string) {}

  validateAddress(validateAddressData: ValidateAddressData) {
    return this.http.post<ValidateAddressDataResponse>(`${this.apiUrl}/address-verification`, {
      streetName: validateAddressData.streetName,
      zipCode: validateAddressData.zipCode,
      city: validateAddressData.city,
      houseNumber: validateAddressData.houseNumber,
    });
  }
}

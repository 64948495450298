export const TEST_PERSON = {
  type: 'person',
  address: {
    city: 'Nürnberg',
    country: 'DE',
    street: 'Teststraße',
    housenumber: '1',
    zip: '90402',
  },
  birthDate: '1990-01-01',
  cityOfBirth: 'Nürnberg',
  email: 'unit@test.de',
  firstname: 'Theodor',
  lastname: 'Tester',
  country: 'DE',
  nationality: 'DE',
  phone: '01234567890',
  roles: ['POLICY_HOLDER', 'INSUREE', 'PREMIUM_PAYER'],
  salutation: 'MR',
};

export const TEST_POLICY_HOLDER = {
  ...TEST_PERSON,
  roles: ['POLICY_HOLDER'],
};

export const TEST_BENEFICIARY = {
  type: 'person',
  address: {
    city: 'Nürnberg',
    country: 'DE',
    street: 'Teststraße',
    housenumber: '1',
    zip: '90402',
  },
  birthDate: '1991-01-01',
  cityOfBirth: 'Nürnberg',
  email: 'evoxnbg.dev@gmail.com',
  firstname: 'Theresa',
  lastname: 'Tester',
  nationality: 'DE',
  phone: '01234567891',
  roles: ['BENEFICIARY'],
  salutation: 'MRS',
  insuredRelationship: 'PARTNER',
};

export const TEST_COUNTRIES = [
  { country: 'Deutschland', nationality: 'deutsch', iso: 'DE' },
  { country: 'Island', nationality: 'isländisch', iso: 'IS' },
  { country: 'Madagaskar', nationality: 'madagassisch', iso: 'MG' },
];

export const TEST_APPLICATION_STATES = [
  {
    insuranceObjects: {
      POLICY_HOLDER: TEST_PERSON,
      INSUREE: TEST_PERSON,
      PREMIUM_PAYER: TEST_PERSON,
      BENEFICIARY: TEST_BENEFICIARY,
    },

    policyInformation: {
      beneficiary: 'PARTNER',
      coverageUsage: 'LOAN',
    },
    paymentInfo: {
      iban: 'DE12500105170648489890',
      bic: 'INGDDEFFXXX',
      bankName: 'ING-DiBa',
    },
    trustedShops: false,
    salesInformation: {
      acquisitionAgent: '658033333',
      channel: 'ONLINE',
    },
    countries: TEST_COUNTRIES,
  },
  {
    policyInformation: {
      beneficiary: 'INDIVIDUAL',
      coverageUsage: 'LOAN',
    },
    insuranceObjects: {
      BENEFICIARY: TEST_BENEFICIARY,
    },
  },
];

export const TEST_SALES_INFORMATION = [
  {
    subBroker: 'Testbroker04',
    firstNameAgent: 'Peterson',
    lastNameAgent: 'Swinskij',
  },
];

export const TEST_PERSONAL_DETAILS_CONFIG = [
  {
    salutationEditable: true,
    birthdateEditable: true,
    cityOfBirthEditable: true,
    cityOfBirthRequired: true,
    nameEditable: true,
    nameRequired: true,
    nationalityEditable: true,
    nationalityRequired: true,
    titleHidden: false,
    phoneRequired: true,
    phoneHidden: false,
    nationalityHidden: false,
  },
  {
    salutationEditable: false,
    birthdateEditable: false,
    cityOfBirthEditable: false,
    cityOfBirthRequired: false,
    nameEditable: false,
    nameRequired: false,
    nationalityEditable: false,
    nationalityRequired: false,
    titleHidden: true,
    phoneRequired: false,
    phoneHidden: false,
    nationalityHidden: true,
  },
];

export const TEST_ADDRESS_DATA_CONFIG = [
  {
    streetRequired: true,
    housenumberRequired: true,
    cityRequired: true,
    zipRequired: true,
    countryEditable: true,
  },
  {
    streetRequired: false,
    housenumberRequired: false,
    cityRequired: false,
    zipRequired: false,
    countryEditable: false,
  },
];

export const TEST_PAYMENT_DATA = [
  {
    firstname: 'Tester',
    lastname: 'Maliksson',
  },
  {
    firstname: 'Tester2',
    lastname: 'Maliksson the Second',
    iban: 'DE27100777770209299700',
    bic: 'NORSDE51XXX',
  },
];

export const TEST_OFFER_MAIL_PARAMETERS = [
  {
    firstName: 'Testperson',
    lastName: 'Ausfuerth',
    email: 'unit.test@evox.coc.de',
    product: 'rlv',
    document: {
      documentId: 'Absicherungsvorschlag',
      documentIndex: 4,
      description: 'Beschreibung',
    },
  },
];

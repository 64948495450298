import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { AbstractControl, AsyncValidator, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { emailPattern } from '@nbg-digital/shared/util';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserService } from '@nbg-digital/user-management/domain';

@Injectable({ providedIn: 'root' })
class UniqueEmailValidator implements AsyncValidator {
  constructor(private userService: UserService) {}

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (ctrl.hasError('pattern') || ctrl.hasError('required')) {
      return of(null);
    }
    return this.userService.checkEmailAvailability(ctrl.value).pipe(
      catchError((error) => {
        if (error['status'] === 409) {
          return of(false);
        }
        throw error;
      }),
      map((isAvailable) => (isAvailable ? null : { alreadyExists: true }))
    );
  }
}

@Component({
  selector: 'nv-user-email',
  templateUrl: './email.component.html',
})
export class EmailComponent extends PartialFormComponent<string> {
  @Output() emailUnavailable = new EventEmitter<boolean>();

  constructor(private fBuilder: UntypedFormBuilder, private uniqueEmailValidator: UniqueEmailValidator) {
    super();

    this.formGroup = this.fBuilder.group({
      email: [
        '',
        {
          validators: [Validators.required, emailPattern],
          asyncValidators: [uniqueEmailValidator],
          updateOn: 'blur',
        },
      ],
    });
    const emailControl = this.formGroup.get('email');
    emailControl.statusChanges.subscribe((status) => {
      if (status === 'INVALID' && emailControl.hasError('alreadyExists')) {
        this.emailUnavailable.emit(true);
      } else if (status === 'VALID') {
        this.emailUnavailable.emit(false);
      }
    });
  }

  protected fromFormData(): string {
    return this.formGroup.value.email;
  }

  protected toFormData(value: string) {
    if (value == null) {
      return '';
    }
    return {
      email: value,
    };
  }
}

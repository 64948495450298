import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvServiceErrorPageComponent } from './service-error-page.component';
import { NvContainerModule } from '../container/container.module';
import { NvHeaderModule } from '../header/header.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NvServiceErrorPageComponent],
  imports: [CommonModule, NvContainerModule, NvHeaderModule, TranslateModule],
  exports: [NvServiceErrorPageComponent],
})
export class NvServiceErrorPageModule {}

import { Adjustment } from './adjustment';
import { Consultation } from './consultation';
import { Salutation } from './insuranceObject';

export interface PricingParameters {
  readonly birthDate: string;
  readonly profession: Profession;
  readonly startDate: string;
  readonly coverage?: number;
  readonly duration?: number;
  readonly dynamic?: boolean;
  readonly salutation: Salutation;
  readonly smoker?: SmokerType;
  readonly tariff?: string;
  readonly price?: number;
  readonly adjustment?: Adjustment;
  readonly subBroker: string;
  readonly acquisitionAgent?: string;
  readonly consultation?: Consultation;
}

export interface Profession {
  jobName: string;
  officeQuota?: string;
  graduation?: string;
}

export enum SmokerType {
  Yes = 'SMOKER',
  No = 'NONSMOKER',
  NonSmoker1Year = 'NONSMOKER1YEAR',
}

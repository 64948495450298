import { Injectable } from '@angular/core';
import { Store } from '@nbg-digital/shared/util';

export interface ConsentManagementState {
  customerEweMail: boolean;
  customerEwePhone: boolean;
  distributorId: string;
}

const initialState: ConsentManagementState = {
  customerEweMail: false,
  customerEwePhone: false,
  distributorId: null,
};

@Injectable({ providedIn: 'root' })
export class ConsentManagementStore extends Store<ConsentManagementState> {
  constructor() {
    super('consent-management');
  }

  setCustomerEweMail(customerEweMail: boolean) {
    this.update({ customerEweMail });
  }

  setCustomerEwePhone(customerEwePhone: boolean) {
    this.update({ customerEwePhone });
  }

  setDistributorId(distributorId: string) {
    this.update({ distributorId });
  }

  selectCustomerEweMail() {
    return this.select((state) => state.customerEweMail);
  }

  selectCustomerEwePhone() {
    return this.select((state) => state.customerEwePhone);
  }

  selectDistributorId() {
    return this.select((state) => state.distributorId);
  }

  clearStorage() {
    this.state = this.getInitialState();
  }

  protected getInitialState() {
    return initialState;
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private repository = new Map();
  private includedEndpoints = ['jobs', 'countries.json'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isCacheable(request)) {
      return next.handle(request);
    }

    const cachedResponse = this.repository.get(request.url);
    return cachedResponse ? of(cachedResponse) : this.sendRequest(request, next);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isCacheable(request: HttpRequest<any>) {
    const url = request.url.split('/').pop();
    return this.includedEndpoints.includes(url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sendRequest(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.repository.set(event.url, event);
        }
      })
    );
  }
}

import {
  Adjustment,
  ApplicationState,
  ApplicationStore,
  BrokerParameters,
  OfferMailParameters,
  OfferService,
  Person,
  PricingParameters,
  Role,
  SalesInformation,
  Underwriting,
} from '@nbg-digital/application/domain';
import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of } from 'rxjs';

export interface ApplicationUser {
  email?: string;
  firstName: string;
  lastName: string;
}

@Injectable({ providedIn: 'root' })
export class ApplicationFacade {
  public applicationParameters$ = this.applicationStore.select((state) => {
    const { insuranceObjects, paymentInfo, policyInformation, salesInformation, policyNumber } = state;
    return { insuranceObjects, paymentInfo, policyInformation, salesInformation, policyNumber };
  });

  public user$: Observable<ApplicationUser | null> = this.applicationStore.select((state) => {
    if (state.insuranceObjects?.POLICY_HOLDER) {
      const { email, firstname: firstName, lastname: lastName } = state.insuranceObjects.POLICY_HOLDER;
      return { email, firstName, lastName };
    }
    return null;
  });

  public policyHolder$ = this.applicationStore.select((state) => {
    if (state.insuranceObjects?.POLICY_HOLDER) {
      const { email, firstname, lastname } = state.insuranceObjects.POLICY_HOLDER;
      return { email, firstname, lastname };
    }
    return null;
  });

  public reentryData$: Observable<ApplicationState> = this.applicationStore.select((state) => ({
    ...state,
    countries: [],
  }));

  public trustedShops$: Observable<boolean> = this.applicationStore.select((state) => state.trustedShops);
  public policyNumber$: Observable<string> = this.applicationStore.select((state) => state.policyNumber);

  public paymentInfoSummary$ = this.applicationStore.selectPaymentInfoSummary();
  public policyInfoSummary$ = this.applicationStore.selectPolicyInfoSummary();
  public policyHolderSummary$ = this.applicationStore.selectPolicyHolderSummary();
  public salesInformation$ = this.applicationStore.selectSalesInformation();
  public loading$ = this.applicationStore.isLoading();

  constructor(private applicationStore: ApplicationStore, private offerService: OfferService) {}

  restoreApplicationState() {
    this.applicationStore.syncFromStorage();
  }

  public isApplicationComplete(withBeneficiary: boolean): Observable<boolean> {
    return combineLatest([
      this.applicationStore.insureeComplete$,
      this.applicationStore.paymentInfoComplete$,
      withBeneficiary ? this.applicationStore.beneficiaryComplete$ : of(true),
    ]).pipe(
      map(
        ([insureeComplete, paymentComplete, beneficiaryComplete]) =>
          insureeComplete && paymentComplete && beneficiaryComplete
      )
    );
  }

  public resetState(): void {
    this.applicationStore.reset();
  }

  public setReEntryState(state: ApplicationState) {
    this.applicationStore.reset();
    this.applicationStore.update(state);
  }

  public setSalesInformation(salesInformation: Partial<SalesInformation>) {
    this.applicationStore.setSalesInformation(salesInformation);
  }

  public setEmailAddress(email: string) {
    this.applicationStore.setEmailAddress(email);
  }

  setBrokerEntryState(brokerParameters: BrokerParameters) {
    this.applicationStore.setInsuranceObjectFromBrokerParams(brokerParameters);
  }

  setTrustedShops(isTrustedShops: boolean) {
    this.applicationStore.setTrustedShops(isTrustedShops);
  }

  setPolicyNumber(policyNumber: string) {
    this.applicationStore.setPolicyNumber(policyNumber);
  }

  public generateOfferDocument(
    offerId: string,
    pricing: PricingParameters,
    decisionStatus: string,
    underwriting: Underwriting[],
    adjustment?: Adjustment
  ) {
    return this.offerService.generateOfferDocument(offerId, pricing, decisionStatus, underwriting, adjustment);
  }

  public selectInsuranceObject(role: Role): Observable<Person> {
    return this.applicationStore.selectInsuranceObject(role);
  }

  public sendOfferDocumentMail(parameters: OfferMailParameters): Observable<void> {
    return this.offerService.sendOfferDocumentMail(parameters);
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PricingStore } from '@nbg-digital/pricing/domain';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricingGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: PricingStore,
    private router: Router,
    @Inject('pricingPath') private pricingPath: string
  ) {}
  canActivate(
    /* eslint-disable @typescript-eslint/no-unused-vars */
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.isComplete$.pipe(
      map((isComplete) => isComplete || this.router.createUrlTree([`/${this.pricingPath}/ergebnis`]))
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}

import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { calcPasswordStrength } from './calcPasswordStrength';

export const allowedSpecialCharacters = '!"§$%&/()=?+*#\'[]-_.:,;{}\\~<>|°^* ';
// eslint-disable-next-line no-useless-escape
const allowedSpecialCharactersPattern = new RegExp(/[^[0-9A-Za-z!"§$%&\/()=?+*#'\[\]\-_.:,;{}\\~<>|°^]]*/);

export function validatePasswordStrength(control: AbstractControl): ValidationErrors | null {
  const valueWithoutSpaces = control.value.split(' ').join('');
  const passwordStrength = calcPasswordStrength(control.value);

  if (control.value === valueWithoutSpaces && passwordStrength > 0 && passwordStrength < 3) {
    return { isWeak: { value: control.value } };
  }

  if (control.value.match(allowedSpecialCharactersPattern)) {
    return { specialChar: { value: control.value } };
  }

  if (control.value) return null;
}

export function isEqualPassword(): ValidatorFn {
  return (formGroup: UntypedFormGroup) => {
    const password = formGroup.get('password');
    const passwordConfirmation = formGroup.get('passwordConfirmation');
    if (password && passwordConfirmation && password.value === passwordConfirmation.value) {
      return null;
    }

    return { isEqualPassword: true };
  };
}

export function isNewPassword(): ValidatorFn {
  return (formGroup: UntypedFormGroup) => {
    const password = formGroup.get('password');
    const passwordConfirmation = formGroup.get('currentPassword');
    if (password && passwordConfirmation && password.value !== passwordConfirmation.value) {
      return null;
    }

    return { isNewPassword: true };
  };
}

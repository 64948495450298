import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvLinkComponent, NvRouterLinkComponent } from './link.component';
import { NvInputModule } from '../input/input.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NvLinkComponent, NvRouterLinkComponent],
  imports: [CommonModule, NvInputModule, RouterModule],
  exports: [NvLinkComponent, NvRouterLinkComponent],
})
export class NvLinkModule {}

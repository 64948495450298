import { animate, query, style, transition, trigger } from '@angular/animations';

export const NvAnimations = trigger('pageAnimations', [
  transition('* => noopAnimation', []),
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({
          display: 'block',
          transform: 'translateX(150px)',
          opacity: '0',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      animate(
        '500ms 350ms ease',
        style({
          transform: 'translateX(0)',
          opacity: '1',
        })
      ),
      { optional: true }
    ),
  ]),
]);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvHeaderComponent {
  @Input() logoHref = '';
  @Input() logoClickable = true;

  get isExternalLink() {
    return this.logoHref?.startsWith('http');
  }
}

export interface TealiumBrokerData {
  order_buchungsga: string;
  page_agency: string;
}

export function createTealiumBrokerData(buchungsga: string, pageAgency: string): TealiumBrokerData {
  return {
    order_buchungsga: buchungsga || '',
    page_agency: pageAgency,
  };
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerInformationResponse } from '../models/customer-information-response';

@Injectable({
  providedIn: 'root',
})
export class CustomerInformationService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}

  getCustomerInformation(): Observable<CustomerInformationResponse> {
    return this.http.get<CustomerInformationResponse>(`${this.apiUrl}/customer`);
  }
}

import { Injectable } from '@angular/core';
import { Country, obfuscateIban, Store } from '@nbg-digital/shared/util';
import { Observable, combineLatest, map, catchError, of } from 'rxjs';
import {
  BrokerParameters,
  InsuranceObjectType,
  PaymentInfo,
  PaymentInfoSummary,
  Person,
  PolicyHolderSummary,
  PolicyInformation,
  PolicyInfoSummary,
  Role,
  SalesInformation,
} from '../models';

export interface ApplicationState {
  insuranceObjects: {
    [key in Role]?: Person;
  };
  policyInformation: PolicyInformation;
  paymentInfo: PaymentInfo;
  countries: Country[];
  trustedShops: boolean;
  salesInformation: SalesInformation;
  policyNumber: string;
}

const initialState: ApplicationState = {
  insuranceObjects: null,
  policyInformation: null,
  paymentInfo: null,
  countries: [],
  trustedShops: false,
  salesInformation: {
    acquisitionAgent: null,
    channel: null,
  },
  policyNumber: null,
};

@Injectable({ providedIn: 'root' })
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super('application');
  }

  paymentInfoComplete$ = this.select(({ paymentInfo: { iban, bic } }) => {
    return !!iban && !!bic;
  });

  insureePersonalDataComplete$ = this.select((state) => state.insuranceObjects?.INSUREE).pipe(
    map(({ cityOfBirth, firstname, lastname, nationality, phone }) => {
      return !!cityOfBirth && !!firstname && !!lastname && !!nationality && !!phone;
    }),
    catchError(() => of(false))
  );

  insureeAddressComplete$ = this.select((state) => state.insuranceObjects?.INSUREE?.address).pipe(
    map(({ city, zip, country, street, housenumber }) => {
      return !!city && !!zip && !!country && !!street && !!housenumber;
    }),
    catchError(() => of(false))
  );

  insureeComplete$ = combineLatest([this.insureePersonalDataComplete$, this.insureeAddressComplete$]).pipe(
    map(([insureePersonalDataComplete, insureeAddressComplete]) => {
      return insureePersonalDataComplete && insureeAddressComplete;
    })
  );

  beneficiaryComplete$ = this.select(({ policyInformation }) => {
    return !!policyInformation.beneficiary && !!policyInformation.coverageUsage;
  });

  setInsuranceObject(insuranceObject: Person) {
    const insuranceObjects = {};
    insuranceObject.roles.forEach((role) => {
      insuranceObjects[role] = {
        ...(this.state.insuranceObjects ? this.state.insuranceObjects[role] : {}),
        ...insuranceObject,
      };
    });

    this.update({ insuranceObjects: { ...this.state.insuranceObjects, ...insuranceObjects } });
  }

  setInsuranceObjectFromBrokerParams({
    firstName: firstname,
    lastName: lastname,
    birthDate,
    salutation,
    nationality,
    cityOfBirth,
    phoneNumber: phone,
    address,
  }: BrokerParameters) {
    const insuranceObject: Person = {
      type: InsuranceObjectType.Person,
      roles: [Role.Insuree, Role.PolicyHolder, Role.PremiumPayer],
      firstname,
      lastname,
      birthDate,
      salutation,
      nationality,
      cityOfBirth,
      phone,
      address: {
        street: address?.street || '',
        housenumber: address?.houseNumber || '',
        zip: address?.zip || '',
        city: address?.city || '',
        country: address?.country || '',
      },
    };
    this.setInsuranceObject(insuranceObject);
  }

  setPaymentInfo(paymentInfo: PaymentInfo) {
    this.update({ paymentInfo });
  }

  setPolicyInformation(policyInformation: PolicyInformation) {
    this.update({ policyInformation });
  }

  setTrustedShops(isTrustedShops: boolean) {
    this.update({ trustedShops: isTrustedShops });
  }

  setPolicyNumber(policyNumber: string) {
    this.update({ policyNumber: policyNumber });
  }

  setSalesInformation(salesInformation: Partial<SalesInformation>) {
    this.update({ salesInformation: { ...this.state.salesInformation, ...salesInformation } });
  }

  setEmailAddress(email: string) {
    Object.values(this.state.insuranceObjects).forEach((insuranceObject) => (insuranceObject.email = email));
    this.update({ insuranceObjects: { ...this.state.insuranceObjects } });
  }

  selectSalesInformation() {
    return this.select((state) => state.salesInformation);
  }

  selectPaymentInfoSummary(): Observable<PaymentInfoSummary> {
    return this.select((state) => {
      const { iban, bankName } = state.paymentInfo;
      return { iban: obfuscateIban(iban), bankName };
    });
  }

  selectPolicyInfoSummary(): Observable<PolicyInfoSummary> {
    return this.select<PolicyInfoSummary>((state) => {
      const { beneficiary: beneficiaryType, coverageUsage } = state.policyInformation;

      const beneficiary = state.insuranceObjects.BENEFICIARY
        ? {
            ...state.insuranceObjects.BENEFICIARY,
            ...state.insuranceObjects.BENEFICIARY.address,
          }
        : null;

      return {
        beneficiaryType: beneficiaryType,
        coverageUsage,
        beneficiary,
      };
    });
  }

  selectPolicyHolderSummary(): Observable<PolicyHolderSummary> {
    return this.select<PolicyHolderSummary>((state) => {
      const {
        birthDate,
        cityOfBirth,
        email,
        firstname,
        lastname,
        nationality,
        phone,
        salutation,
        address: { city, country, street, housenumber, zip },
      } = state.insuranceObjects.POLICY_HOLDER;

      return {
        birthDate,
        cityOfBirth,
        firstname,
        lastname,
        salutation,
        email,
        nationality,
        phone,
        city,
        country,
        street,
        housenumber,
        zip,
      };
    });
  }

  selectTrustedShops(): Observable<boolean> {
    return this.select<boolean>((state) => {
      return state.trustedShops;
    });
  }

  selectPolicyNumber(): Observable<string> {
    return this.select<string>((state) => {
      return state.policyNumber;
    });
  }

  selectInsuranceObject(role: Role): Observable<Person> {
    return this.select<Person>((state) => state.insuranceObjects[role]);
  }

  protected getInitialState() {
    return initialState;
  }
}

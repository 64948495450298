import * as dayjs from 'dayjs';

function getStartDate(): string {
  return dayjs().add(1, 'months').startOf('months').format('YYYY-MM-DD');
}

export const TEST_PRICING_STATES = [
  {
    birthDate: '1980-01-01',
    coverage: 100000,
    duration: 20,
    dynamic: false,
    salutation: 'MRS',
    profession: { jobName: 'Softwareentwickler/in', graduation: 'TECH', officeQuota: 'HIGH' },
    tariff: 'premium',
    tariffId: 'premium',
    offerId: 'df3fa9f3-06d0-458e-8246-cccf3e206237',
    price: 15,
    startDate: getStartDate(),
    smoker: 'NONSMOKER',
    yearlyPrice: 180,
    subBroker: '',
    paymentCycle: 'HALF_YEARLY',
    consultation: { credit: 'YES', familyPartner: 'NO', mutualInsurance: 'YES', crossInsurance: 'NOT_SPECIFIED' },
  },
];

export const TEST_PRICING_REQUEST_DATA = [
  {
    startDate: '1980-01-01',
    birthDate: '1965-01-01',
    coverage: 100000,
    duration: 20,
    smoker: 'NONSMOKER',
    salutation: 'MRS',
    product: 'rlv',
  },
];

export const TEST_PRICING_RESPONSE_DATA = [
  {
    tariffs: [
      {
        name: 'Basic',
        id: '1',
        premium: {
          net: 1,
          gross: 2,
        },
        monthlyPremium: {
          net: 1,
          gross: 2,
        },
        yearlyPremium: {
          net: 3,
          gross: 4,
        },
      },
    ],
  },
];

export const TEST_INITIAL_INFORMATION = {
  offerId: '123456',
  email: 'test@test.de',
  product: 'dkr',
};

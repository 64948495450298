import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { focusElementStart } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-user-login-fail-card',
  templateUrl: './nv-login-fail-card.component.html',
})
export class NvLoginFailCardComponent implements AfterViewInit {
  @Input() buttonTxt: string;
  @Input() contentTxt: string;
  @Input() hrefLink: string;
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    focusElementStart(this.elementRef.nativeElement);
  }
}

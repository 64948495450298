import { Component, Input, OnInit, Optional } from '@angular/core';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';

@Component({
  selector: 'nv-rejection',
  templateUrl: './rejection.component.html',
})
export class NvRejectionComponent implements OnInit {
  @Input() showContactUsButton = true;

  constructor(
    @Optional() private userFacade: UserManagementFacade,
    @Optional() private pricingFacade: PricingFacade,
    @Optional() private applicationFacade: ApplicationFacade,
    @Optional() private riskAssessmentFacade: RiskAssessmentFacade
  ) {}

  ngOnInit(): void {
    this.userFacade.resetRegistrationState();
    this.pricingFacade.resetState();
    this.applicationFacade.resetState();
    this.riskAssessmentFacade.resetState();
  }
}

import { NvInfoTextContainerComponent } from './info-text-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [NvInfoTextContainerComponent],
  imports: [CommonModule],
  exports: [NvInfoTextContainerComponent],
})
export class NvInfoTextContainerModule {}

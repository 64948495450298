import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePaymentData } from '../models/change-payment-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}
  private bankUrl = `${this.apiUrl}/bank`;

  changeBankData(paymentData: ChangePaymentData): Observable<void> {
    return this.http.post<void>(`${this.bankUrl}/change`, paymentData);
  }
}

import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { map } from 'rxjs/operators';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';

@Component({
  selector: 'nv-session-broker-entry',
  template: '',
})
export class BrokerEntryComponent implements OnInit {
  @ViewChild('errorModal', { static: true })
  private errorModal: NvModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pricingFacade: PricingFacade,
    private applicationFacade: ApplicationFacade,
    @Optional() @Inject('brokerEntryPoint') private brokerEntryPoint: string
  ) {}

  ngOnInit() {
    this.route.data.pipe(map((data) => data.brokerData)).subscribe(({ brokerParameters, brokerId }) => {
      if (brokerParameters) {
        this.pricingFacade.setBrokerEntryState(brokerParameters);
        this.applicationFacade.setBrokerEntryState(brokerParameters);
        this.router.navigate([this.brokerEntryPoint], {
          queryParams: { offerId: brokerId },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([this.brokerEntryPoint], {
          queryParams: { brokerNotFound: true, offerId: brokerId },
          queryParamsHandling: 'merge',
        });
      }
    });
  }
}

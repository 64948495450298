import { Injectable } from '@angular/core';

import { Store } from '@nbg-digital/shared/util';
import { Observable } from 'rxjs';
import { ContractOverview } from '../models/contract-overview';
import { Customer } from '../models/customer';
import { Message } from '../models/message';

export interface CustomerState {
  customer: Customer;
  contracts: Array<ContractOverview>;
  messages: Array<Message>;
  unreadMessages: Array<Message>;
}

export const initialState: CustomerState = {
  customer: null,
  contracts: null,
  messages: null,
  unreadMessages: null,
};

@Injectable({
  providedIn: 'root',
})
export class CustomerServiceStore extends Store<CustomerState> {
  constructor() {
    super('customer-service');
  }

  selectContracts(): Observable<ContractOverview[]> {
    return this.select<ContractOverview[]>((state) => {
      return state.contracts;
    });
  }

  resetContracts(): void {
    this.update({ contracts: null });
  }

  selectCustomer(): Observable<Customer> {
    return this.select<Customer>((state) => {
      return state.customer;
    });
  }

  selectMessages(): Observable<Message[]> {
    return this.select<Message[]>((state) => {
      return state.messages;
    });
  }

  selectUnreadMessages(): Observable<Message[]> {
    return this.select<Message[]>((state) => {
      return state.unreadMessages;
    });
  }

  setMessageRead(id: string) {
    const message = this.state.messages.find((res) => res.id === id);
    if (message) {
      message.read = true;
    }
  }

  protected getInitialState() {
    return initialState;
  }
}

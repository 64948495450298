import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { PersonalInformation } from '../models/personal-information';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}

  getPersonalData(): Observable<Customer> {
    return this.http.get<PersonalInformation>(`${this.apiUrl}/customer/personalInformation`);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'nv-agent-modal',
  templateUrl: './agent-modal.component.html',
  styleUrls: ['./agent-modal.component.scss'],
})
export class NvAgentModalComponent {
  showForm = true;
}

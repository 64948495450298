export const TEST_REGISTRATION_STATES = [
  {
    email: 'evoxnbg.dev@gmail.com',
    password: 'Abcd1234',
    firstName: 'Theresa',
    lastName: 'Tester',
    isInitialPinCodeSent: false,
    isPinCodeVerified: true,
  },
  {
    email: 'evoxnbg.dev@gmail.com',
    password: 'Abcd1234!',
    firstName: 'Peter',
    lastName: 'Pansen',
    isInitialPinCodeSent: false,
    isPinCodeVerified: false,
  },
];

export const TEST_REGISTRATION_SELECT = {
  email: 'evoxnbg.dev@gmail.com',
  firstName: 'Theresa',
  lastName: 'Tester',
};

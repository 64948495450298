export enum InsuranceObjectType {
  // lower case, since technically no enum in middleware
  Person = 'person',
  Car = 'car',
}

export enum Role {
  PolicyHolder = 'POLICY_HOLDER',
  Insuree = 'INSUREE',
  PremiumPayer = 'PREMIUM_PAYER',
  Beneficiary = 'BENEFICIARY',
}

export enum Salutation {
  Mr = 'MR',
  Mrs = 'MRS',
}

export enum Purpose {
  Family = 'FAMILY',
  Loan = 'LOAN',
}

export interface InsuranceObject {
  type: InsuranceObjectType;
}

export interface Address {
  city: string;
  country: string;
  street: string;
  housenumber: string;
  zip: string;
}

export class Person implements InsuranceObject {
  type = InsuranceObjectType.Person;
  address: Address;
  birthDate: string;
  cityOfBirth: string;
  email?: string;
  firstname: string;
  lastname: string;
  nationality?: string;
  phone?: string;
  roles: Role[];
  salutation: Salutation;
  insuredRelationship?: InsuredRelationship;
}

export class Car implements InsuranceObject {
  type = InsuranceObjectType.Car;
}

export enum InsuredRelationship {
  Partner = 'PARTNER',
  Family = 'FAMILY',
  Other = 'OTHER',
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvAutoCompleteComponent } from './auto-complete.component';
import { NvInputModule } from '../input/input.module';
import { NvFormInfoContainerModule } from '../form-info-container/form-info-container.module';

@NgModule({
  declarations: [NvAutoCompleteComponent],
  imports: [CommonModule, NgbModule, NvInputModule, NvFormInfoContainerModule],
  exports: [NvAutoCompleteComponent],
})
export class NvAutoCompleteModule {}

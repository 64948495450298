import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrokerEntryComponent } from './broker-entry.component';
import { Route, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrokerEntryResolver } from './broker-entry.resolver';

const routes: Route[] = [
  {
    path: '',
    component: BrokerEntryComponent,
    resolve: { brokerData: BrokerEntryResolver },
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule],
  declarations: [BrokerEntryComponent],
})
export class BrokerEntryModule {}

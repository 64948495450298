import { Component, EventEmitter, Inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UserService } from '@nbg-digital/user-management/domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginDialogService } from './login-dialog.service';

@Component({
  selector: 'nv-user-login-dialog',
  templateUrl: './login-dialog.component.html',
})
export class LoginDialogComponent implements OnDestroy {
  @Output() loginSuccess = new EventEmitter<void>();
  @Output() dismissLoginAfterExpiration = new EventEmitter<void>();
  @ViewChild('loginModal', { static: true })
  private modal: NvModalComponent;
  sessionExpired: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private loginDialogService: LoginDialogService,
    private userService: UserService,
    @Inject('kupoUrl') public kupoUrl: string
  ) {
    this.loginDialogService.showLogin$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sessionExpired) => this.open(sessionExpired));

    this.userService.automaticLogOut$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.loginDialogService.showLoginDialog(true));
  }

  open(sessionExpired: boolean) {
    this.sessionExpired = sessionExpired;
    this.modal.open();
  }

  onSuccess() {
    this.modal.close();
    this.loginSuccess.emit();
  }

  onDismiss() {
    if (this.sessionExpired) {
      this.dismissLoginAfterExpiration.emit();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface TealiumPageParameters {
  // Interface für Seitenparameter

  // Angabe des verwendeten Responsiven Breakpoints
  page_breakpoint: string;
  // Flagging von Landing Pages
  page_type: string;
}

export function createTealiumPageParameters(breakpoint: string, pageType: string): TealiumPageParameters {
  return {
    page_breakpoint: breakpoint,
    page_type: pageType || '',
  };
}

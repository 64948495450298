export * from './lib/risk-assessment-domain.module';

export * from './lib/infrastructure/risk-assessment.service';

export * from './lib/store/risk-assessment.store';

export * from './lib/models/magnum-go-config';
export * from './lib/models/magnum-go-parameters';
export * from './lib/models/magnum-go-result';
export * from './lib/models/risk-assessment-result';
export * from './lib/models/channel';
export * from './lib/models/product';
export * from './lib/models/main-broker';
export * from './lib/models/insuranceDocument';
export * from './lib/models/insuranceObject';
export * from './lib/models/offerResponse';

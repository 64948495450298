import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { calcPasswordStrength } from '@nbg-digital/shared/util';

@Component({
  selector: 'nv-password-strength',
  templateUrl: './password-strength.component.html',
})
export class NvPasswordStrengthComponent implements OnInit, OnChanges, OnDestroy {
  @Input() password = '';
  @Output() passwordStrengthChange = new EventEmitter<number>();

  passwordStrength$ = new BehaviorSubject<number>(0);
  strengthStatus$: Observable<StatusOption> = this.passwordStrength$.pipe(
    map((strength) => passwordStrength[strength])
  );
  private passwordStrengthSub: Subscription;

  ngOnInit() {
    this.passwordStrengthSub = this.passwordStrength$
      .pipe(distinctUntilChanged())
      .subscribe((strength) => this.passwordStrengthChange.emit(strength));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const password = changes['password'].currentValue;
    const strength = calcPasswordStrength(password);
    this.passwordStrength$.next(strength);
  }

  ngOnDestroy() {
    this.passwordStrengthSub.unsubscribe();
  }
}

interface StatusOption {
  color: string;
  size: string;
  level: string;
}

const passwordStrength: StatusOption[] = [
  { color: 'error', size: '0', level: '' },
  { color: 'error', size: '25', level: 'Schwach' },
  { color: 'warning', size: '50', level: 'Mittel' },
  { color: 'success', size: '75', level: 'Stark' },
  { color: 'secondary', size: '100', level: 'Perfekt' },
];

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { create, noUiSlider, Options, PipsOptions, UpdateOptions } from 'nouislider';

export interface NvSliderFormat {
  to(value: number): string;

  from(value: string): number;
}

export class DefaultFormatter implements NvSliderFormat {
  to(value: number): string {
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  }

  from(value: string): number {
    return parseFloat(value);
  }
}

@Component({
  selector: 'nv-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NvSliderComponent), multi: true }],
  encapsulation: ViewEncapsulation.None, // TODO:  remove ViewEncapsulation.none
})
export class NvSliderComponent implements ControlValueAccessor, OnInit, OnChanges {
  slider: noUiSlider;

  @HostBinding('class') get hostClasses(): string {
    let selectedClass = '';

    if (this.format.to(this.min) === this.slider?.get()) {
      selectedClass = 'min-selected';
    }
    if (this.format.to(this.max) === this.slider?.get()) {
      selectedClass = 'max-selected';
    }

    return selectedClass;
  }

  private sliderOptions: Options;

  @Input() public connect = true;
  @Input() public format: NvSliderFormat = new DefaultFormatter();
  @Input() public min: number;
  @Input() public max: number;
  @Input() public start: number | number[];
  @Input() public step: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public tooltips: boolean | Array<any> = false;
  @Input() public pips?: PipsOptions;
  @Input() public highlightedRangeStart = 0;
  @Input() public enableHighlightedRange = true;
  @Output() public slideStart = new EventEmitter<void>();
  @Output() public slideEnd = new EventEmitter<void>();

  public highlightedRangePosition: number;

  @ViewChild('nvSlider', { static: true }) element: ElementRef<HTMLElement>;

  ngOnInit() {
    this.pips = {
      mode: 'values',
      values: [this.min, this.max],
      format: this.format,
    };
    this.sliderOptions = {
      connect: this.connect,
      format: this.format,
      pips: this.pips,
      range: { min: this.min, max: this.max },
      start: this.start,
      step: this.step,
      tooltips: this.tooltips,
    };
    this.slider = create(this.element.nativeElement, this.sliderOptions);

    this.slider.on('update', (values: string[]) => {
      this.onChange(this.mapValues(values));
    });

    this.slider.on('start', () => this.slideStart.emit());
    this.slider.on('end', () => this.slideEnd.emit());
  }

  ngOnChanges(changes: SimpleChanges) {
    const sliderUpdateOptions: UpdateOptions = {};
    // update if min and or max have changed
    if (changes['min'] || changes['max'] || changes['highlightedRangeStart']) {
      sliderUpdateOptions.range = {
        min: this.min,
        max: this.max,
      };
      sliderUpdateOptions.pips = {
        ...this.pips,
        values: [this.min, this.max],
      };
      if (this.highlightedRangeStart >= this.min && this.highlightedRangeStart <= this.max) {
        this.highlightedRangePosition = 100 * ((this.highlightedRangeStart - this.min) / (this.max - this.min));
      }
    }
    // update slider options with collected changes
    if (Object.keys(sliderUpdateOptions).length > 0) {
      this.slider?.updateOptions(sliderUpdateOptions);
    }
  }

  mapValues(values: string[]): number | number[] {
    const mappedValues = values.map(this.format.from);
    return mappedValues.length === 1 ? mappedValues[0] : mappedValues;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    const noUiOriginElements = this.element.nativeElement.getElementsByClassName('noUi-origin');
    isDisabled
      ? noUiOriginElements[0].setAttribute('disabled', 'true')
      : noUiOriginElements[0].removeAttribute('disabled');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    if (this.slider) {
      setTimeout(() => {
        this.slider.set(value);
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (fn: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onTouched = (fn: void) => {};
}

import { Injectable } from '@angular/core';

import { ProductService, Store } from '@nbg-digital/shared/util';
import * as dayjs from 'dayjs';
import { combineLatest, Observable } from 'rxjs';
import { PricingParameters } from '../models';
import { map } from 'rxjs/operators';

export interface PricingState extends PricingParameters {
  tariff: string;
  tariffId: string;
  price: number;
  grossPrice: number;
  yearlyPrice: number;
  subBroker: string;
  acquisitionAgent?: string;
  readonly offerId: string;
}

const initialState: PricingState = {
  birthDate: null,
  coverage: null,
  duration: null,
  dynamic: null,
  salutation: null,
  profession: null,
  tariff: null,
  offerId: null,
  price: null,
  grossPrice: null,
  startDate: getStartDate(),
  smoker: null,
  yearlyPrice: null,
  tariffId: null,
  subBroker: null,
  acquisitionAgent: null,
  paymentCycle: null,
  consultation: null,
};

@Injectable({ providedIn: 'root' })
export class PricingStore extends Store<PricingState> {
  constructor(private productService: ProductService) {
    super('pricing');
  }

  isComplete$ = this.select((state) => state.price !== null && state.tariff !== null && state.paymentCycle !== null);

  ageAtInsuranceStart(selectedBirthDate?: string, selectedStartDate?: string): Observable<number> {
    return combineLatest([this.select((state) => state.startDate), this.select((state) => state.birthDate)]).pipe(
      map(([startDate, birthDate]) => {
        const birthDateToUse = birthDate || selectedBirthDate;
        const startDateToUse = startDate || selectedStartDate;
        return dayjs(startDateToUse, 'YYYY-MM-DD').diff(dayjs(birthDateToUse), 'years');
      })
    );
  }

  ageAtThisTime(selectedBirthDate?: string): Observable<number> {
    return this.select((state) => state.birthDate).pipe(
      map((birthDate) => dayjs().diff(dayjs(birthDate || selectedBirthDate), 'years'))
    );
  }

  selectPricingParameters(): Observable<PricingParameters> {
    return this.select((state) => ({
      birthDate: state.birthDate,
      coverage: state.coverage,
      duration: state.duration,
      dynamic: state.dynamic,
      salutation: state.salutation,
      profession: state.profession,
      startDate: state.startDate,
      smoker: state.smoker,
      paymentCycle: state.paymentCycle || undefined,
      product: this.productService.product,
      consultation: state.consultation,
    }));
  }

  selectPricingState(): Observable<PricingState> {
    return this.select((state) => ({
      ...state,
      product: this.productService.product,
    }));
  }

  selectPricingSummary() {
    return this.select((state) => {
      const { startDate, coverage, duration, dynamic, tariff, price, consultation } = state;
      const endDate = dayjs(state.startDate).add(state.duration, 'years').format('YYYY-MM-DD');

      return {
        startDate,
        coverage,
        duration,
        dynamic,
        tariff,
        price,
        endDate,
        crossInsurance: consultation?.crossInsurance,
      };
    });
  }

  selectInsureeSummary() {
    return this.select((state) => {
      const { profession, salutation, smoker } = state;
      return { profession, salutation, smoker };
    });
  }

  updateStartDate(adjustedStart: string) {
    this.update({ startDate: adjustedStart });
  }

  updateTariff(tariff: string) {
    this.update({ tariff: tariff, tariffId: tariff });
  }

  protected getInitialState() {
    return initialState;
  }

  resetContractData() {
    this.update({
      coverage: null,
      duration: null,
      dynamic: null,
      tariff: null,
      tariffId: null,
    });
  }
}

function getStartDate(): string {
  return dayjs().add(1, 'months').startOf('months').format('YYYY-MM-DD');
}

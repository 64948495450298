import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TrackingAwareRoute } from '@nbg-digital/shared/util-tracking';
import { ReentryComponent } from './reentry.component';
import { ReentryResolver } from './reentry.resolver';
import { NvButtonModule, NvModalModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

const routes: TrackingAwareRoute[] = [
  {
    path: '',
    component: ReentryComponent,
    resolve: { reentryData: ReentryResolver },
    data: {
      tracking: {
        page: {
          type: 'Wiedereinstiegsseite',
          name: 'Antrag/Wiedereinstieg',
          category: 'Wiedereinstieg',
        },
      },
    },
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), NvModalModule, TranslateModule, NvButtonModule],
  declarations: [ReentryComponent],
  providers: [ReentryResolver],
})
export class ReentryModule {}

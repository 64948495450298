export const TEST_RATING_DATA = [
  {
    firstName: 'test',
    lastName: 'testus',
    email: 'test.testus@codecamp-n.com',
    offerId: '6b51340e-e13d-11ea-87d0-0242ac130003',
    channel: 'ONLINE',
    product: 'rlv',
  },
];

export const TEST_CONSENT_DATA = [
  {
    email: 'test.testus@codecamp-n.com',
    phoneNumber: '01234567890',
  },
  {
    email: 'test.testus@codecamp-n.com',
    phoneNumber: '01234567890',
    personalInformation: {
      salutation: 'MR',
      firstName: 'test',
      lastName: 'testsson',
      birthDate: '1990-01-01',
      street: 'the street',
      houseNumber: '3',
      city: 'the city',
      zip: '99998',
    },
  },
];

import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'nv-user-logout-automatic-message',
  templateUrl: './logout-automatic-message.component.html',
  styleUrls: ['./logout-automatic-message.component.scss'],
})
export class LogoutAutomaticMessageComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() dismissClicked = new EventEmitter<any>();
  visible = true;

  constructor(private route: ActivatedRoute) {
    this.visible = this.route.snapshot.queryParamMap.get('automaticLogout') === 'true';
  }

  dismiss() {
    this.visible = false;
    this.dismissClicked.emit();
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '@nbg-digital/shared/util';
import { Observable } from 'rxjs';
import { BrokerParameters } from '../models/broker-parameters';
import { Product } from '../models/product';
import { SessionData } from '../models/session-data';

@Injectable({
  providedIn: 'root',
})
export class SessionManagementService {
  constructor(private http: HttpClient, @Inject('apiUrl') private apiUrl: string) {}

  saveSessionData(sessionData: SessionData): Observable<{ reentryUrl: string }> {
    return this.http.post<{ reentryUrl: string }>(`${this.apiUrl}/reentry`, sessionData);
  }

  getSessionData(offerId: string): Observable<SessionData> {
    return this.http.get<SessionData>(`${this.apiUrl}/reentry/${offerId}`);
  }

  deleteSessionData(offerId: string) {
    return this.http.delete(`${this.apiUrl}/reentry/${offerId}`);
  }

  getBrokerParameters(product: Product, brokerId: string): Observable<BrokerParameters> {
    return this.http.get<BrokerParameters>(`${this.apiUrl}/broker/${product}/${brokerId}`, {
      headers: {
        [InterceptorSkipHeader]: '',
      },
    });
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { NvDropdownItemComponent } from './dropdown-item.component';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';

let uid = 0;

@Component({
  selector: 'nv-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NvDropdownComponent),
      multi: true,
    },
  ],
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvDropdownComponent implements AfterViewInit, ControlValueAccessor {
  private options: Set<NvDropdownItemComponent> = new Set<NvDropdownItemComponent>();

  @Input() id = `nv-dropdown-${++uid}`;
  @Input() placeholder?: string = null;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  @Input()
  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  @ViewChild(NgbDropdown, { static: true }) ngbDropdown: NgbDropdown;
  @ViewChild(NgbDropdownMenu, { static: true }) ngbMenu: NgbDropdownMenu;
  @ContentChildren(NgbDropdownItem, { descendants: true }) ngbItems: QueryList<NgbDropdownItem>;

  constructor(private cd: ChangeDetectorRef) {}

  private _disabled: boolean = null;
  private _required: boolean = null;
  private _label: string = null;
  private _value: string = null;

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit(): void {
    // hacky solution to override NgbDropdownMenu's menuItems to make NgbDropdown.onKeyDown() work
    this.ngbMenu?.menuItems?.reset(Array.from(this.options).map((option) => option.ngbItem));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (valueChanges: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onTouched = () => {};

  onBlur() {
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    this.updateSelectedOption();
  }

  registerOption(option: NvDropdownItemComponent) {
    this.options.add(option);
  }

  unregisterOption(option: NvDropdownItemComponent) {
    this.options.delete(option);
  }

  setOption(option: NvDropdownItemComponent) {
    this.label = option.label;
    this.value = option.value;
    this.onChange(option.value);
    this.cd.markForCheck();
  }

  updateSelectedOption() {
    of(null)
      .pipe(delay(0))
      .subscribe(() =>
        this.options.forEach((option) => {
          if (option.value === this._value) {
            this.setOption(option);
          }
        })
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvCheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [NvCheckboxComponent],
  imports: [CommonModule],
  exports: [NvCheckboxComponent],
})
export class NvCheckboxModule {}

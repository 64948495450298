import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePaymentData } from '../models/change-payment-data';
import { Observable } from 'rxjs';
import { ChangeAddressData } from '../models/change-address-data';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}

  changeAddressData(changeAddressData: ChangeAddressData): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/customer/address/change`, changeAddressData);
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[nv-button], a[nv-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, // TODO:  remove ViewEncapsulation.none
})
export class NvButtonComponent {
  @Input() variant: 'flat' | 'outline' = 'flat';
  @Input() color: 'primary' | 'secondary' | 'accent' | 'light' | 'transparent' = 'secondary';
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() type: 'submit' = null;
  @Input() class = '';
  @Input() id: string;

  @HostBinding('class') get hostClasses(): string {
    return [
      'btn',
      `btn${this.variant === 'flat' ? '' : '-' + this.variant}-${this.color}`,
      ...(this.size === 'small' ? ['btn-sm'] : []),
      ...(this.class ? [this.class] : []),
    ].join(' ');
  }
}

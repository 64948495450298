import { Component } from '@angular/core';
import { UserState, UserStore } from '@nbg-digital/user-management/domain';
import { Observable } from 'rxjs';

@Component({
  selector: 'nv-user-login-details',
  templateUrl: './login-details.component.html',
})
export class LoginDetailsComponent {
  public user$: Observable<UserState>;
  public passwordChangeUrl = 'passwort-aendern';
  public usernameChangeUrl = 'benutzername-aendern';


  constructor(private userStore: UserStore) {
    this.user$ = this.userStore.state$;
  }
}

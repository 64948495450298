import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvPinCodeComponent } from './pin-code.component';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [NvPinCodeComponent],
  imports: [CommonModule, CodeInputModule.forRoot({ isCharsCode: false })],
  exports: [NvPinCodeComponent],
})
export class NvPinCodeModule {}

import { Component, HostBinding, Input } from '@angular/core';

let uid = 0;

@Component({
  selector: 'nv-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class NvCollapseComponent {
  @Input() title?: string;
  @Input() isCollapsed = false;
  @Input() hasBorder = true;

  @HostBinding('attr.aria-controls') @Input() id = `nv-collapse-${++uid}`;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvContainerComponent } from './container.component';

@NgModule({
  declarations: [NvContainerComponent],
  imports: [CommonModule],
  exports: [NvContainerComponent],
})
export class NvContainerModule {}

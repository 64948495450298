import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';

export function isValidDateFormat(format?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && !validate(control.value, format || 'YYYY-MM-DD')) {
      return { invalidDateFormat: { value: control.value } };
    }

    return null;
  };

  function validate(date, format) {
    if (dayjs(date, format).isValid()) {
      return dayjs(date, format).format(format) === date;
    }
    return false;
  }
}

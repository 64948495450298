import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import {
  NvButtonModule,
  NvCheckboxModule,
  NvErrorModule,
  NvFormModule,
  NvInputModule,
  NvLinkModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { StorageAgreementComponent } from './storage-agreement.component';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';

@NgModule({
  declarations: [StorageAgreementComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NvButtonModule,
    NvCheckboxModule,
    NvModalModule,
    NvErrorModule,
    NvFormModule,
    NvInputModule,
    SharedUtilTrackingModule,
    NvLinkModule,
  ],
  exports: [StorageAgreementComponent],
})
export class StorageAgreementModule {}

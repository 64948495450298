export interface MagnumGoResult {
  decisionStatus: DecisionStatus;
  adjustment?: MagnumGoAdjustment;
  maxSum?: number;
  underwriting: Underwriting[];
  externalExistingContract: boolean;
}

export interface MagnumGoAdjustment {
  extraMortality: number;
  proMille: number;
}

export interface Underwriting {
  questionGroup: string;
  questions: Question[];
}

export interface Question {
  index: number;
  title: string;
  answer: string;
  children: Question[];
}

export enum DecisionStatus {
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  Refer = 'REFER',
  Postpone = 'POSTPONE'
}

import * as dayjs from 'dayjs';

export function isSameAge(date1: string, date2: string, birthDate: string): boolean {
  const [age1, age2] = [date1, date2].map((date) => dayjs(date, 'YYYY-MM-DD').diff(dayjs(birthDate), 'years'));
  return age1 === age2;
}

export function isOfHigherAge(date1: string, date2: string, birthDate: string): boolean {
  const [age1, age2] = [date1, date2].map((date) => dayjs(date, 'YYYY-MM-DD').diff(dayjs(birthDate), 'years'));
  return age1 > age2;
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterceptorSkipHeader } from '@nbg-digital/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl: string) {}
  private serviceRequestUrl = `${this.apiUrl}/service`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  revokeContract(policyNumber: string): Observable<any> {
    return this.http.post(`${this.serviceRequestUrl}/revocation`, { policyNumber });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terminateContract(terminationDate: string, policyNumber: string): Observable<any> {
    return this.http.post<void>(`${this.serviceRequestUrl}/terminate`, { terminationDate, policyNumber });
  }

  sendMessage(formData: FormData): Observable<void> {
    return this.http.post<void>(`${this.serviceRequestUrl}/request`, formData, {
      headers: {
        [InterceptorSkipHeader]: '',
      },
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvModalComponent, NvModalContent } from './modal.component';
import { NvCardModule } from '../card/card.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NvInfoModalComponent } from './info-modal.component';
import { NvContainerModule } from '../container/container.module';

@NgModule({
  declarations: [NvModalComponent, NvModalContent, NvInfoModalComponent],
  imports: [CommonModule, NvCardModule, NgbModalModule, NvContainerModule],
  exports: [NvModalComponent, NvModalContent, NvInfoModalComponent],
})
export class NvModalModule {}

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationStore } from '../store/application.store';
import {
  Adjustment,
  InsuranceDocument,
  OfferMailParameters,
  OfferResponse,
  Person,
  PricingParameters,
  Role,
  Underwriting,
} from '../models';
import { InterceptorSkipHeader, ProductService, ReentryUrlService } from '@nbg-digital/shared/util';
import { finalize, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private telephonyApiUrl = `${this.apiUrl}/telephony`;

  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private productService: ProductService,
    private http: HttpClient,
    private store: ApplicationStore,
    @Optional() private reentryUrlService: ReentryUrlService
  ) {}

  generateOfferDocument(
    offerId: string,
    pricingParameters: PricingParameters,
    decisionStatus?: string,
    underwriting?: Underwriting[],
    adjustment?: Adjustment
  ): Observable<OfferResponse> {
    this.store.setLoading(true);
    const { insuranceObjects, salesInformation } = this.store.state;
    return this.http
      .post<OfferResponse>(`${this.telephonyApiUrl}/priceIndication/${offerId}`, {
        salesInformation,
        pricingParameters: {
          ...pricingParameters,
          adjustment,
        },
        decisionStatus: decisionStatus,
        underwriting: underwriting,
        person: this.getUniqueInsuranceObjects(insuranceObjects)[0],
        product: this.productService.product,
        reentryUrl: this.reentryUrlService.baseUrl,
      })
      .pipe(finalize(() => this.store.setLoading(false)));
  }

  sendOfferDocumentMail(parameters: OfferMailParameters): Observable<void> {
    this.store.setLoading(true);
    return this.http
      .post<void>(`${this.telephonyApiUrl}/priceIndication/mail`, parameters)
      .pipe(finalize(() => this.store.setLoading(false)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyOfferDocumentPassword(offerId: string, birthDate: string, zipCode: string): Observable<any> {
    return this.http.get(
      `${this.telephonyApiUrl}/credentialsValidation/${offerId}?birthDate=${birthDate}&zipCode=${zipCode}`
    );
  }

  downloadOfferDocument(document: InsuranceDocument) {
    this.store.setLoading(true);
    return this.http
      .get(`${this.telephonyApiUrl}/priceIndication/document`, {
        params: {
          description: document.description,
          documentIndex: document.documentIndex,
          documentId: document.documentId,
        },
        headers: {
          'Content-Type': 'application/pdf',
          [InterceptorSkipHeader]: '',
        },
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          saveAs(new Blob([res.body], { type: 'application/pdf;charset=utf-8' }), 'Absicherungsvorschlag.pdf');
        }),
        finalize(() => this.store.setLoading(false))
      );
  }

  private getUniqueInsuranceObjects(insuranceObjects: { [key in Role]?: Person }): Person[] {
    return [...Object.values(insuranceObjects)].filter(
      (person, index, array) =>
        // find the first entry with the same roles and only keep if this is it
        array.findIndex((p) => person.roles.every((role, roleIndex) => role === p.roles[roleIndex])) === index
    );
  }

  generateOfferId(): Observable<string> {
    return this.http
      .post<{ offerId: string }>(`${this.apiUrl}/offerId`, null)
      .pipe(map((response) => response.offerId));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceBeforeUpperCasePipe } from './space-before-upper-case-pipe';

@NgModule({
  declarations: [SpaceBeforeUpperCasePipe],
  imports: [CommonModule],
  exports: [SpaceBeforeUpperCasePipe],
})
export class CustomPipesModule {
}

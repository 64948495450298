import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@nbg-digital/user-management/domain';
import {
  allowedSpecialCharacters,
  isEqualPassword,
  isNewPassword,
  validatePasswordStrength,
} from '@nbg-digital/shared/util';
import { catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nv-user-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnDestroy {
  public passwordChangeForm: UntypedFormGroup;
  public loginDetailPath = '../';
  public passwordStrength;
  specialChars = allowedSpecialCharacters;
  private unsubscribe$ = new Subject<void>();
  @ViewChild('modal', { static: true }) private modal: NvModalComponent;

  constructor(
    private userService: UserService,
    private fBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.passwordChangeForm = this.fBuilder.group(
      {
        email: [{ value: '', disabled: true }, Validators.required],
        currentPassword: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(8), validatePasswordStrength]],
        passwordConfirmation: ['', [Validators.required]],
      },
      {
        validators: [isEqualPassword(), isNewPassword()],
      }
    );

    this.userService.getUser().subscribe((response) => {
      this.passwordChangeForm.patchValue({
        email: response.username,
      });
    });
  }

  updateUserData(event: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.passwordChangeForm.valid) {
      this.passwordChangeForm.markAllAsTouched();
    } else {
      const formControls = this.passwordChangeForm.controls;
      this.userService
        .changePassword(formControls.currentPassword.value, formControls.password.value)
        .pipe(
          takeUntil(this.unsubscribe$),
          catchError((err) => {
            if (err.status === 401) {
              this.passwordChangeForm.patchValue({ currentPassword: '' });
              this.passwordChangeForm.get('currentPassword').setErrors({ invalidPassword: true });
            }
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.modal.open();
          this.router.navigate([this.loginDetailPath], { relativeTo: this.route });
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onPassed($event) {
    this.passwordStrength = $event;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@nbg-digital/shared/util';
import { ActionArea } from '../actions/action-area.enum';

export interface ClickId {
  key: string;
  value: string;
}

export interface TrackingState {
  clickId: ClickId;
  actionAreas: {
    [area in ActionArea]?: string;
  };
}

const initialState: TrackingState = {
  clickId: null,
  actionAreas: {},
};

@Injectable()
export class TrackingStore extends Store<TrackingState> {
  constructor() {
    super('tracking');
  }

  setClickId({ key, value }: ClickId) {
    this.update({ clickId: { key, value } });
  }

  registerArea(area: ActionArea, selector: string) {
    const actionAreas = { ...this.state.actionAreas, [area]: selector };
    this.update({ actionAreas });
  }

  deregisterArea(area: ActionArea) {
    const actionAreas = { ...this.state.actionAreas, [area]: null };
    this.update({ actionAreas });
  }

  selectActionAreaByElement(element: HTMLElement): Observable<ActionArea> {
    return this.state$.pipe(
      map((state) => {
        return (
          (Object.keys(state.actionAreas).find((key: ActionArea) =>
            document.querySelector(state.actionAreas[key])?.contains(element)
          ) as ActionArea) || ActionArea.Content
        );
      })
    );
  }

  protected getInitialState(): TrackingState {
    return initialState;
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BankInfo } from '../models/bank-info';
import { Observable } from 'rxjs';
import { BankAccountInfo } from '../models/bankAccountInfo';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient, @Inject('userApiUrl') private apiUrl) {}

  //todo remooooove
  getInfo(iban: string): Observable<BankInfo> {
    return this.http.get<BankInfo>(`${this.apiUrl}/information/bankInfo`, { params: { iban } });
  }

  // getInfo(iban: string) {
  //   return this.http.get<BankInfo>(`${this.apiUrl}/bank/info`, { params: { iban } });
  // }

  getAccountInfo(accountNumber: string, bankCode: string) {
    return this.http.get<BankAccountInfo>(`${this.apiUrl}/information/bankAccountInfo`, {
      params: { accountNumber, bankCode },
    });
  }
}

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'nv-user-password-changed',
  templateUrl: 'password-changed.component.html',
})
export class PasswordChangedComponent {
  @Output() next = new EventEmitter();

  onSubmit(event: Event) {
    event.preventDefault();
    this.next.emit();
  }
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService, VerifyEmailData } from '@nbg-digital/user-management/domain';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';

@Component({
  selector: 'nv-user-email-verification',
  templateUrl: './verification.component.html',
})
export class VerificationComponent {
  pinCodeForm: UntypedFormGroup;
  verifyEmailData: VerifyEmailData;

  @Output() successfulVerification = new EventEmitter<void>();
  @Output() pinCodeEmailSent = new EventEmitter<void>();
  isResendPIN = false;

  @ViewChild('modalVerification', { static: true })
  private modalVerification: NvModalComponent;

  constructor(private fBuilder: UntypedFormBuilder, private userService: UserService) {
    this.pinCodeForm = this.fBuilder.group({
      pinCode: [
        '',
        {
          validators: [Validators.required, Validators.minLength(4)],
        },
      ],
    });
  }

  open(verifyEmailData: VerifyEmailData, shouldSendEmail: boolean) {
    this.verifyEmailData = verifyEmailData;

    if (shouldSendEmail) {
      this.sendVerificationCode();
    }
    this.modalVerification.open();
  }

  sendVerificationCode() {
    this.userService.sendVerificationCode(this.verifyEmailData).subscribe(() => {
      this.pinCodeEmailSent.emit();
    });
  }

  verifyVerificationCode(event: Event) {
    event.preventDefault();

    if (!this.pinCodeForm.valid) {
      this.pinCodeForm.markAllAsTouched();
    } else {
      this.userService
        .verifyCode(this.verifyEmailData.userData.email, this.pinCodeForm.get('pinCode').value)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 400 || error.status === 401) {
              this.pinCodeForm.controls.pinCode.setErrors({ invalid: true });
              return of();
            }
            throw error;
          })
        )
        .subscribe(() => {
          this.modalVerification.dismiss();
          this.successfulVerification.emit();
        });
    }
  }

  onResendPin() {
    this.isResendPIN = true;
    this.sendVerificationCode();
  }

  onBack() {
    this.modalVerification.dismiss();
  }
}

import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'nv-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class NvContainerComponent {
  @HostBinding('class.container') containerClass = true;
}

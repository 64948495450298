import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[nvWiggle]',
})
export class NvWiggleDirective {
  private toWiggle: boolean;
  private wiggleDuration = 2000;

  @HostBinding('class.shake') get wiggleClass() {
    return this.toWiggle;
  }

  public wiggle() {
    this.toWiggle = true;

    setTimeout(() => {
      this.toWiggle = false;
    }, this.wiggleDuration);
  }
}

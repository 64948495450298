import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvCollapseComponent } from './collapse.component';
import { NvCardModule } from '../card/card.module';

@NgModule({
  declarations: [NvCollapseComponent],
  imports: [CommonModule, NvCardModule],
  exports: [NvCollapseComponent],
})
export class NvCollapseModule {}

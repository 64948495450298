import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvLoginFailCardComponent } from './nv-login-fail-card.component';
import { NvCardModule } from '@nbg-digital/ui-dpl-components';

@NgModule({
  declarations: [NvLoginFailCardComponent],
  imports: [CommonModule, NvCardModule],
  exports: [NvLoginFailCardComponent],
})
export class NvLoginFailCardModule {}

import { Inject, Injectable } from '@angular/core';
import { loadScript } from '../load-script';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private pathAdviceSrc = 'https://app.pathadvice.ai/embed.js';

  constructor(
    @Inject('pathAdviceConnectId') private pathAdviceConnectId: string,
    @Inject('enableChat') private enableChat: boolean
  ) {}

  init() {
    if (this.enableChat) {
      loadScript(this.pathAdviceSrc, this.pathAdviceConnectId);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountryPipe } from './country.pipe';
import { NationalityPipe } from './nationality.pipe';

@NgModule({
  declarations: [CountryPipe, NationalityPipe],
  imports: [CommonModule],
  exports: [CountryPipe, NationalityPipe],
})
export class CountryModule {}

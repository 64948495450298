import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiError, ApiErrorCode } from '@nbg-digital/shared/util';
import { UserService } from '@nbg-digital/user-management/domain';

@Component({
  selector: 'nv-user-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent {
  @Output() loginSuccess = new EventEmitter<void>();
  @Input() kupoUrl: string;
  @Output() loginFail = new EventEmitter<void>();
  @Output() userLocked = new EventEmitter<void>();

  loginForm: UntypedFormGroup;

  constructor(private fBuilder: UntypedFormBuilder, private router: Router, private userService: UserService) {
    this.loginForm = this.fBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    const { username: email, password } = this.loginForm.value;
    this.userService
      .signIn(email, password)
      .pipe(
        catchError((error: { error: ApiError }) => {
          this.loginFail.emit();
          const errorCode = error.error[0]?.errorCode;
          if (errorCode === ApiErrorCode.UNAUTHORIZED || errorCode === ApiErrorCode.USER_LOCKED) {
            this.loginForm.setErrors({ credentials: errorCode });
          }
          if (errorCode === ApiErrorCode.USER_LOCKED) {
            this.userLocked.emit();
          }
          return EMPTY;
        })
      )
      .subscribe(() => this.loginSuccess.emit());
  }
}

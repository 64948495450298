import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Placement } from '@ng-bootstrap/ng-bootstrap/util/positioning';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[popoverContent]' })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NvPopoverContent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'nv-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvPopoverComponent {
  @Input() placement: Placement[] | string = 'auto';
  @Output() closed = new EventEmitter();

  @ViewChild(NgbPopover, { static: true }) ngbPopover: NgbPopover;
  @ContentChild(NvPopoverContent, { read: TemplateRef, static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazyContent: TemplateRef<any>;

  close() {
    this.ngbPopover.close();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvFormComponent } from './form.component';

@NgModule({
  declarations: [NvFormComponent],
  imports: [CommonModule],
  exports: [NvFormComponent],
})
export class NvFormModule {}

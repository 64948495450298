import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NvModalComponent } from '@nbg-digital/ui-dpl-components';
import { UserService, UserStore } from '@nbg-digital/user-management/domain';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { EmailComponent } from '../../../email/email.component';
import { VerificationComponent } from '../../verification/verification.component';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'nv-user-change-username',
  templateUrl: './change-username.component.html',
})
export class ChangeUsernameComponent {
  public usernameChangeForm: UntypedFormGroup;
  public loginDetailPath = '../';
  @ViewChild(EmailComponent) emailComponent: EmailComponent;
  initialEmail: string;
  @ViewChild('successModal', { static: true }) private successModal: NvModalComponent;
  @ViewChild('verificationComponent', { static: true }) private verificationComponent: VerificationComponent;

  constructor(
    private userService: UserService,
    private userStore: UserStore,
    private fBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.usernameChangeForm = this.fBuilder.group({
      email: null,
      currentPassword: ['', Validators.required],
    });
  }

  openEmailVerification(event: Event) {
    event.preventDefault();

    if (!this.usernameChangeForm.valid) {
      this.usernameChangeForm.markAllAsTouched();
    } else {
      const { firstname, lastname } = this.userStore.state;

      this.userService
        .verifyLogin(this.usernameChangeForm.get('currentPassword').value)
        .pipe(
          catchError((err) => {
            if (err.status === 401) {
              this.usernameChangeForm.patchValue({ currentPassword: '' });
              this.usernameChangeForm.get('currentPassword').setErrors({ invalidPassword: true });
            }
            return of(false);
          })
        )
        .subscribe((success) => {
          if (success !== false) {
            this.verificationComponent.open(
              {
                userData: { firstname, lastname, email: this.emailComponent.value },
              },
              true
            );
          }
        });
    }
  }

  updateUsername() {
    const formControls = this.usernameChangeForm.controls;
    this.userService
      .changeUsername(this.emailComponent.value, formControls.currentPassword.value)
      .pipe(
        catchError((err) => {
          if (err.status === 401) {
            this.usernameChangeForm.patchValue({ currentPassword: '' });
            this.usernameChangeForm.get('currentPassword').setErrors({ invalidUserData: true });
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.successModal.open();
        this.userStore.update({ username: this.emailComponent.value });
        this.router.navigate([this.loginDetailPath], { relativeTo: this.route });
      });
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.usernameChangeForm.setControl(name, form);
  }
}

export class AppConfig {
  showHeaderWithFixedNavigationBar: boolean;
  showRating: boolean;
  showBayerischeProgressBar: boolean;
  progressNavigationAssetsPath: string;
  showAcquisitionAgentCheckboxes: boolean;
  enableEwe: boolean;
  enableReentry: boolean;
  showLoginButtonInFooter: boolean;
}

import { Component, Input, ViewChild } from '@angular/core';
import { Observable, race, Subject } from 'rxjs';
import { NvModalComponent } from '../modal/modal.component';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'nv-decision-modal',
  templateUrl: './decision-modal.component.html',
})
export class NvDecisionModalComponent {
  @Input() dismissBtnText = '';
  @Input() submitBtnText = '';

  modalClosed$ = new Subject();
  modalConfirmed$ = new Subject<true>();

  @ViewChild('modalDecisionChange', { static: true })
  private modal: NvModalComponent;

  openModal(): Observable<boolean> {
    this.modal.open();
    return race(this.modalConfirmed$, this.modalClosed$).pipe(
      first(),
      map((e) => !!e)
    );
  }
  dismissSuccessModal() {
    this.modal.dismiss();
  }
}

import { Injectable } from '@angular/core';

import { Store } from '@nbg-digital/shared/util';

export interface RegistrationState {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly isInitialPinCodeSent: boolean;
  readonly isPinCodeVerified: boolean;
}

const initialState = {
  email: null,
  password: null,
  firstName: null,
  lastName: null,
  isInitialPinCodeSent: false,
  isPinCodeVerified: false,
};

@Injectable({
  providedIn: 'root',
})
export class RegistrationStore extends Store<RegistrationState> {
  registrationComplete$ = this.select((state) => {
    return !!state.email && !!state.password && state.isPinCodeVerified;
  });

  constructor() {
    super('registration');
  }

  protected getInitialState() {
    return initialState;
  }
}

import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[modalContent]' })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NvModalContent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'nv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [NgbModal, NgbModalConfig, NgbActiveModal],
})
export class NvModalComponent implements OnInit {
  @ContentChild(NvModalContent, { read: TemplateRef, static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazyContent: TemplateRef<any>;

  @ViewChild('dialogContent', { static: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private modalTemplate: TemplateRef<any>;

  @Input() size: 'sm' | 'lg' | 'xl' = 'lg';

  @Input() isClosable = true;

  @Input() isClosableByBackdrop = true;

  @Output() closed = new EventEmitter();

  private modalService: NgbModal;

  private readonly containerSelector = '.nv-evox-root';

  constructor(
    @Inject(PLATFORM_ID) private platformId: unknown,
    private injector: Injector,
    private config: NgbModalConfig,
    private activeModal: NgbActiveModal
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.modalService = this.injector.get(NgbModal);
    }
  }

  ngOnInit() {
    this.setModalConfig();
  }

  open() {
    this.activeModal = this.modalService.open(this.modalTemplate, { modalDialogClass: 'nv-evox-root' });
  }

  dismiss() {
    this.activeModal.dismiss();
    this.closed.emit();
  }

  close() {
    this.activeModal.close();
  }

  setModalConfig() {
    this.config.backdrop = this.isClosableByBackdrop ? true : 'static';
    this.config.size = this.size;
    this.config.centered = true;
    this.config.keyboard = this.isClosable;
    this.config.container = this.containerSelector;
  }
}

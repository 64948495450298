import { AbstractControl, ValidatorFn } from '@angular/forms';

export function isDivisibleBy(step: number): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value % step !== 0) {
      return { isDivisible: { value: control.value } };
    }
    return null;
  };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvChatButtonComponent } from './chat-button.component';
import { NvButtonModule } from '../button/button.module';

@NgModule({
  declarations: [NvChatButtonComponent],
  imports: [CommonModule, NvButtonModule],
  exports: [NvChatButtonComponent],
})
export class NvChatButtonModule {}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.add(req);
    // create a new observable which we return instead of the original
    return new Observable((observer) => {
      // subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.loaderService.remove(req);
            observer.next(event);
          }
        },
        (err) => {
          this.loaderService.remove(req);
          observer.error(err);
        },
        () => {
          this.loaderService.remove(req);
          observer.complete();
        }
      );
      // return teardown logic in case of cancelled requests
      return () => {
        this.loaderService.remove(req);
        subscription.unsubscribe();
      };
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule, NvCardModule, NvLinkModule } from '@nbg-digital/ui-dpl-components';
import { TranslateModule } from '@ngx-translate/core';

import { LoginTeaserComponent } from './login-teaser.component';

@NgModule({
  imports: [CommonModule, NvButtonModule, NvCardModule, NvLinkModule, TranslateModule],
  exports: [LoginTeaserComponent],
  declarations: [LoginTeaserComponent],
})
export class LoginTeaserModule {}

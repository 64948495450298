import { Injectable } from '@angular/core';

import { Store } from '@nbg-digital/shared/util';
import { RiskAssessmentResult } from '../models/risk-assessment-result';

export interface RiskAssessmentState extends RiskAssessmentResult {
  caseId: string;
  token: string;
}

const initialState: RiskAssessmentState = {
  caseId: '',
  token: null,
  decisionStatus: null,
  adjustment: null,
  maxSum: null,
  adjustedPrice: null,
  adjustedGrossPrice: null,
  underwriting: null,
  externalExistingContract: null,
};

@Injectable({
  providedIn: 'root',
})
export class RiskAssessmentStore extends Store<RiskAssessmentState> {
  constructor() {
    super('riskAssessment');
  }

  isComplete$ = this.select((state) => state.caseId && state.decisionStatus !== null);

  public selectAdjustment() {
    return this.select((state) => state.adjustment);
  }

  public selectUnderwriting() {
    return this.select((state) => state.underwriting);
  }

  public selectDecisionStatus() {
    return this.select((state) => state.decisionStatus);
  }

  protected getInitialState() {
    return initialState;
  }
}

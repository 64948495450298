import { ActivatedRoute } from '@angular/router';
import { Directive, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isObservable, Observable, Subject } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { focusFirstInvalidField } from '@nbg-digital/shared/util';
import { NvWizardPageNavigationComponent } from './wizard-page-navigation.component';

/**
 * Abstract base component for pages, i.e. routed steps of an application wizard.
 * Offers common functionality such as navigation to the previous and subsequent step.
 */

@Directive()
export abstract class WizardPageComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  @ViewChild(NvWizardPageNavigationComponent) navigationComponent: NvWizardPageNavigationComponent;

  /**
   * Link to next step, is navigated to when the user submits the form / clicks the "next" button.
   */
  public nextLink: string;
  /**
   * Link to previous step, is navigated to when the user clicks the "back" button.
   */
  public previousLink: string;
  /**
   * HTML element reference to have access to the DOM.
   */
  public element: HTMLElement;

  constructor(route: ActivatedRoute, elementRef: ElementRef) {
    route.data
      .pipe(
        first(),
        map((data) => data.navigation)
      )
      .subscribe((nav) => {
        this.nextLink = nav?.nextLink;
        this.previousLink = nav?.previousLink;
      });
    this.element = elementRef.nativeElement;
  }

  /**
   * To be called on form submit.
   * @param event The submit event, must be passed to prevent default browser submit.
   */
  onSubmit(event: Event) {
    event.preventDefault();
    if (this.isFormValid()) {
      const beforeNext = this.beforeNext();
      if (isObservable(beforeNext)) {
        beforeNext.pipe(take(1)).subscribe(() => {
          this.navigationComponent.navigateToNext();
        });
      } else {
        this.navigationComponent.navigateToNext();
      }
    } else {
      focusFirstInvalidField(this.element);
    }
  }

  ngOnInit() {
    this.fillInitialDataFromStore();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Hook function called before navigating to subsequent page. Can be an Observable that completes after necessary async logic was finished.
   * Can be implemented by the specific page to run custom logic.
   */

  protected isFormValid(): boolean {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  protected beforeNext(): void | Observable<any> {}

  /**
   * Pre-fills the page's form based on the current state in the store.
   * Called on page init.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected fillInitialDataFromStore() {}
}

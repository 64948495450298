import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionData, SessionManagementService } from '@nbg-digital/session-management/domain';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { catchError, map } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as isBetween from 'dayjs/plugin/isBetween';
import { getFirstOfNextMonth, isSameAge } from '@nbg-digital/shared/util';

dayjs.extend(utc);
dayjs.extend(isBetween);

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({
  providedIn: 'root',
})
export class ReentryResolver implements Resolve<any> {
  constructor(
    private sessionService: SessionManagementService,
    private pricingFacade: PricingFacade,
    private applicationFacade: ApplicationFacade,
    private riskAssessmentFacade: RiskAssessmentFacade,
    private userManagementFacade: UserManagementFacade
  ) {}

  calculateAdjustedStart(plannedStart: string): string {
    if (dayjs(plannedStart).isAfter(dayjs())) {
      return plannedStart;
    }
    return getFirstOfNextMonth();
  }

  isReentryValid(plannedStart: string, adjustedStart: string, birthDate: string): boolean {
    return isSameAge(plannedStart, adjustedStart, birthDate);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.sessionService.getSessionData(route.params.offerId).pipe(
      map((sessionData: SessionData) => {
        if (sessionData) {
          const adjustedStartDate = this.calculateAdjustedStart(sessionData.data.pricing.startDate);
          const reentryValid = this.isReentryValid(
            sessionData.data.pricing.startDate,
            adjustedStartDate,
            sessionData.data.pricing.birthDate
          );
          this.applicationFacade.setReEntryState(sessionData.data.application);
          this.userManagementFacade.setReEntryState(sessionData.data.userManagement);
          this.pricingFacade.setReEntryState(sessionData.data.pricing, reentryValid, adjustedStartDate);
          this.riskAssessmentFacade.setReEntryState(sessionData.data.riskAssessment, reentryValid);

          return { reentryData: { canReEnter: true, reentryValid: reentryValid } };
        }
      }),
      catchError(() => of({ reentryData: { canReEnter: false, reentryValid: true } }))
    );
  }
}

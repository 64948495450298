import { ContractOverview, Customer, CustomerServiceStore } from '@nbg-digital/customer-service/domain';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerServiceFacade {
  constructor(private store: CustomerServiceStore) {}

  public resetState() {
    this.store.reset();
  }

  public getCustomer(): Observable<Customer> {
    return this.store.selectCustomer();
  }

  public getContracts(): Observable<ContractOverview[]> {
    return this.store.selectContracts();
  }
}

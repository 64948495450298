import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';

export function maxAge(maxAge: number, format?: string): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isSmaller = false;
    if (maxAge !== null && control.value && dayjs(control.value, format || 'YYYY-MM-DD', true).isValid()) {
      const birthDate = dayjs(control.value, format || 'YYYY-MM-DD');
      const ageNow = dayjs().diff(birthDate, 'year');
      const ageInOneMonth = dayjs().add(1, 'months').startOf('months').diff(birthDate, 'year');

      isSmaller = ageNow <= maxAge && ageInOneMonth <= maxAge;
      return isSmaller ? null : { invalidMaxAge: { value: control.value } };
    }
    return null;
  };
}

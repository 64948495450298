import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';
import { NvRejectionComponent } from './rejection.component';
import { NvButtonModule, NvCardModule } from '@nbg-digital/ui-dpl-components';
import { NvWizardHeaderModule } from '../wizard-header/wizard-header.module';

@NgModule({
  declarations: [NvRejectionComponent],
  imports: [
    CommonModule,
    NvCardModule,
    RouterModule,
    SharedUtilTrackingModule,
    TranslateModule,
    NvButtonModule,
    NvWizardHeaderModule,
  ],
  exports: [NvRejectionComponent],
})
export class NvRejectionModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvPricingPreviewComponent } from './nv-pricing-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NvPricingPreviewComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  exports: [NvPricingPreviewComponent],
})
export class NvPricingPreviewModule {}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BankAccountInfo, BankInfo } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient, @Inject('apiUrl') private apiUrl: string) {}

  getInfo(iban: string) {
    return this.http.get<BankInfo>(`${this.apiUrl}/bank/info`, { params: { iban } });
  }

  getAccountInfo(accountNumber: string, bankCode: string) {
    return this.http.get<BankAccountInfo>(`${this.apiUrl}/bank/accountInfo`, {
      params: { accountNumber, bankCode },
    });
  }
}

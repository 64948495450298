import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvRejectionPageComponent } from './rejection-page.component';
import { NvRejectionModule } from '../rejection/rejection.module';
import { TranslateModule } from '@ngx-translate/core';
import { NvWizardHeaderModule } from '../wizard-header/wizard-header.module';

@NgModule({
  declarations: [NvRejectionPageComponent],
  imports: [CommonModule, NvRejectionModule, NvWizardHeaderModule, TranslateModule],
})
export class RejectionPageModule {}

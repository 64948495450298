import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'spaceBeforeUpperCase' })
export class SpaceBeforeUpperCasePipe implements PipeTransform {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: string, ...args: any[]): any {
    return value.replace(/([A-Z])/g, ' $1').trim();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvAgentModalComponent } from './agent-modal.component';
import { NvPopoverModule } from '../popover/popover.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilTrackingModule } from '@nbg-digital/shared/util-tracking';
import { NvModalModule } from '../modal/modal.module';
import { NvLinkModule } from '../link/link.module';

@NgModule({
  declarations: [NvAgentModalComponent],
  imports: [CommonModule, NvPopoverModule, NvModalModule, TranslateModule, SharedUtilTrackingModule, NvLinkModule],
  exports: [NvAgentModalComponent],
})
export class NvAgentModalModule {}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';

export function isNoFutureDate(format?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && dayjs(control.value, format || 'YYYY-MM-DD', true).isAfter(dayjs())) {
      return { isFutureDate: { value: control.value } };
    }
    return null;
  };
}

import * as dayjs from 'dayjs';

interface AgeRange {
  min: number;
  max: number;
}
const ranges: AgeRange[] = [
  { min: 0, max: 20 },
  { min: 21, max: 30 },
  { min: 31, max: 40 },
  { min: 41, max: 50 },
  { min: 51, max: 60 },
  { min: 61, max: 70 },
  { min: 71, max: 99 },
];

/**
 * Takes an ISO date and returns its age span as needed for tracking purposes.
 */
export function getAgeSpan(birthdate: string): string {
  if (!birthdate) {
    return '';
  }

  const calculatedAge = dayjs().diff(dayjs(birthdate).format(), 'years');

  const range = ranges.find((r) => calculatedAge >= r.min && calculatedAge <= r.max) || ranges[ranges.length - 1];
  return range.min + '-' + range.max;
}

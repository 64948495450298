import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvFooterComponent } from './footer.component';
import { NvContainerModule } from '../container/container.module';
import { NvLinkModule } from '../link/link.module';

@NgModule({
  declarations: [NvFooterComponent],
  imports: [CommonModule, NvContainerModule, NvLinkModule],
  exports: [NvFooterComponent],
})
export class NvFooterModule {}

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'nv-loadingspinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class NvLoadingSpinnerComponent implements OnDestroy {
  @Input() alwaysVisible = false;
  // minimum duration for the spinner to show
  @Input() minDuration = 500;
  // minimum loading duration for the spinner to show at all
  @Input() threshold = 500;
  @Input() appearance: 'embedded' | 'modal' = 'embedded';
  @Input() set loading(value: boolean) {
    this.loading$.next(value);
  }
  private loading$ = new Subject<boolean>();

  @Output() appear = new EventEmitter<void>();
  @Output() disappear = new EventEmitter<void>();

  showSpinner$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor() {
    let lastLoadingStart: number;
    this.showSpinner$ = this.loading$.pipe(
      distinctUntilChanged(),
      tap((loading) => {
        if (loading) {
          lastLoadingStart = Date.now();
        }
      }),
      switchMap((loading) => {
        if (loading) {
          return of(loading).pipe(delay(this.threshold));
        }
        if (Date.now() < lastLoadingStart + this.threshold) {
          return of(loading);
        }
        return of(loading).pipe(delay(new Date(lastLoadingStart + this.threshold + this.minDuration)));
      }),
      startWith(false)
    );

    this.showSpinner$.pipe(takeUntil(this.unsubscribe$)).subscribe((show) => {
      if (show) {
        this.appear.emit();
      } else {
        this.disappear.emit();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

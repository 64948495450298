import * as dayjs from 'dayjs';

export function getFirstOfNextMonth(): string {
  return dayjs().add(1, 'months').startOf('month').format('YYYY-MM-DD');
}

export function getFirstOfCurrentMonth(): string {
  return dayjs().startOf('month').format('YYYY-MM-DD');
}

export function getFirstOfPreviousMonth(): string {
  return dayjs().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
}

import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'nv-pincode',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NvPinCodeComponent),
      multi: true,
    },
  ],
})
export class NvPinCodeComponent implements ControlValueAccessor {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  @ViewChild('codeInput', { read: ElementRef }) codeInputElement!: ElementRef<HTMLElement>;

  initialCode = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    this.initialCode = val;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onCodeChanged(code: string) {
    this.onChange(code);
  }

  onBlur(event: FocusEvent) {
    const newFocusedElement = event.relatedTarget;
    // we use event.relatedTarget to determine if the code input as a whole has lost focus and call onTouched only in that case
    if (newFocusedElement instanceof Node && !this.codeInputElement.nativeElement.contains(newFocusedElement)) {
      this.onTouched();
    }
  }
}

import { NgModule } from '@angular/core';
import { NvContainerModule } from '../container/container.module';
import { NvPricingFooterComponent } from './pricing-footer.component';
import { NvInfoTextContainerModule } from '../info-text-container/info-text-container.module';

@NgModule({
  declarations: [NvPricingFooterComponent],
  imports: [NvContainerModule, NvInfoTextContainerModule],
  exports: [NvPricingFooterComponent],
})
export class NvPricingFooterModule {}

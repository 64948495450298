import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */
export abstract class Store<T extends { [key: string]: any }> {
  private readonly _state$ = new BehaviorSubject<T>(this.getInitialState());
  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  readonly state$ = this._state$.asObservable();

  constructor(public storageKey: string = '') {
    this.syncFromStorage();
  }

  get state(): T {
    return this._state$.getValue();
  }

  set state(val: T) {
    this._state$.next(val);
  }

  select<P>(project: (value: T) => P) {
    return this.state$.pipe(
      map<T, P>((state) => {
        try {
          return project(state);
        } catch {
          return null;
        }
      }),
      distinctUntilChanged()
    );
  }

  update(state: Partial<T>) {
    const newState = {
      ...this.state,
      ...state,
    };
    this.state = newState;
    this.syncToStorage();
  }

  reset() {
    this.state = this.getInitialState();
    this.clearStorage();
  }

  // TODO: build a more sophisticated, mockable solution with interchangeable storages and keys to read/write
  // consider https://github.com/dscheerens/ngx-webstorage-service
  syncToStorage() {
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.state));
  }

  syncFromStorage() {
    const sessionStore = JSON.parse(sessionStorage.getItem(this.storageKey));
    if (sessionStore) this.state = sessionStore;
  }

  clearStorage() {
    sessionStorage.removeItem(this.storageKey);
  }

  isLoading() {
    return this._loading$.asObservable();
  }

  setLoading(loading: boolean) {
    this._loading$.next(loading);
  }

  protected abstract getInitialState(): T;
}
